import "../../../styles.css";
import React, { useState, useContext } from "react";
import { Button, ButtonAlt, ButtonContainer } from "./Subscribe.styles";
import { Pool, confirmRegistration } from "../../../UserPool";
import ShopifyBuy from "shopify-buy";

const OneMonthProductId = "gid://shopify/Product/8252416655641";

const shopifyClient = ShopifyBuy.buildClient({
  domain: "trizon-1906.myshopify.com",
  storefrontAccessToken: "fb3a64c4e25f0c173165fa4689138b29",
});

const handleTest = async () => {
  const order_id =
    "gid://shopify/Order/5313631781145?key=598ec80672233bc6e4286390f34772c9";
  const checkout_id =
    "gid://shopify/Checkout/dc652e4dd59f78cece55a1cd956f3b8d?key=c4f2bdeda79bf408e5363fb291bf0669";
  const order = await shopifyClient.order.fetch(1000);
  console.log(order);
};

const handleBuyBasic = async (event) => {
  event.preventDefault();

  const productId = OneMonthProductId;

  const now = new Date();
  const currentTs = Math.floor(now.getTime() / 1000);
  now.setMonth(now.getMonth() + 1);
  const endSubTs = Math.floor(now.getTime() / 1000);
  const user = Pool.getCurrentUser();

  console.log(user);
  if (user) {
    user.getSession(async (err, session) => {
      if (err) {
        setIsLoading(false);
      } else {
        const attributeList = [
          { Name: "updated_at", Value: currentTs.toString() },
          { Name: "custom:role", Value: "paid" },
          { Name: "custom:tsEndRole", Value: endSubTs.toString() },
        ];

        const product = await shopifyClient.product.fetch(productId);
        const checkout = await shopifyClient.checkout.create();
        const lineItems = [
          {
            variantId: product.variants[0].id,
            quantity: 1,
          },
        ];
        console.log(checkout);
        console.log(lineItems);
        await shopifyClient.checkout.addLineItems(checkout.id, lineItems);

        // Redirect to Shopify checkout
        window.open(checkout.webUrl, "_blank");

        // Poll Shopify API to check if payment is completed
        let isPaymentCompleted = false;
        while (!isPaymentCompleted) {
          const updatedCheckout = await shopifyClient.checkout.fetch(
            checkout.id
          );
          console.log(updatedCheckout);
          if (updatedCheckout.completedAt) {
            // const orderId = updatedCheckout.order.id;
            // const order = await shopifyClient.order.fetch();
            console.log('Order done - updating')
            user.updateAttributes(attributeList, (err, result) => {
              if (err) {
                setError(err.message || "Something went wrong");
              } else {
                // window.location.reload();
              }
            });
            isPaymentCompleted = true;
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    });
  }
};

export default function Subscribe() {
  return (
    <>
      <ButtonContainer>
        <Button onClick={handleBuyBasic}>SubscribeBasic</Button>
        <ButtonAlt onClick={handleTest}>List Items</ButtonAlt>
      </ButtonContainer>
    </>
  );
}
