import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { FilterMatchMode } from "primereact/api";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";

const varPadding = 20;

const positions_url = "https://api.forkeddigital.vip/warTerminalpositionsdf";

const init = (initialState) => initialState;

const globalHeaderStyle = { fontWeight: 500, backgroundColor: "#ece4ff" };

function convertSecondsToTime(seconds) {
  var days = Math.floor(seconds / 86400);
  var hours = Math.floor((seconds % 86400) / 3600);
  var minutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var seconds = ((seconds % 86400) % 3600) % 60;
  if (days < 10) {
    days = "0" + days;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + "m " + hours + "h " + days + "d ";
}

const headerTemplate = (data) => {
  return (
    <React.Fragment>
      <span className={"light-gray-highlight"}>
        {data.rank + " " + data.user_name}
      </span>
      <span> </span>
      <span
        className={
          data.monthlyPnl >= 0 ? "positive-highlight" : "negative-highlight"
        }
      >
        {"Monthly PnL: " +
          data.monthlyPnl.toLocaleString("en", { maximumFractionDigits: 0 }) +
          " | Monthly RoI: " +
          data.monthlyRoi.toLocaleString("en", {
            style: "percent",
            maximumFractionDigits: 0,
          }) +
          " | All Time RoI: " +
          data.totalRoi.toLocaleString("en", {
            style: "percent",
            maximumFractionDigits: 0,
          })}{" "}
      </span>
    </React.Fragment>
  );
};

const entryPriceBodyTemplate = (rowData) => {
  return rowData.entryPrice.toLocaleString("en", { maximumFractionDigits: 3 });
};

const pnlBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData.pnl > 0,
    "low-percentage": rowData.pnl < 0,
  });

  return (
    <div className={stockClassName}>
      {rowData.pnl.toLocaleString("en", { maximumFractionDigits: 0 })}
    </div>
  );
};

const sideBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData.side == "LONG",
    "low-percentage": rowData.side == "SHORT",
  });

  return <div className={stockClassName}>{rowData.side}</div>;
};

const valueBodyTemplate = (rowData) => {
  return rowData.value.toLocaleString("en", { maximumFractionDigits: 0 });
};

const timeDiffBodyTemplate = (rowData) => {
  return convertSecondsToTime(rowData.time_diff);
};

const rowClass = (data) => {
  return {
    "trade-short": data.side === "SHORT",
    "trade-long": data.side === "LONG",
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataLoaded":
      return { ...state, results: action.payload, loading: false };
    default:
      throw new Error();
  }
};

export const WarTerminalPositionTable = () => {
  const initialState = {
    results: [],
    loading: true,
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(positions_url);
        if (result.status == 200) {
          setData(result);
          return result;
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData()
      // .then(res => console.log(res.data[0].data))
      .then((res) =>
        dispatch({ type: "dataLoaded", payload: res.data[0].data })
      );
    // dispatch({ type: "dataLoaded", payload: res.data[0].usd[0].stables });

    // if (loading) {
    //     dispatch({ type: "dataLoaded", payload: data.data[0].usd[0].stables });
    // }
  }, [loading]);

  const [filters2, setFilters2] = useState({
    ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue2, setGlobalFilterValue2] = useState("");
  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  };
  return (
    <Grid
      id="positionstable"
      container
      wrap="nowrap"
      style={{
        paddingBottom: varPadding,
        paddingRight: varPadding,
        paddingLeft: varPadding,
        gap: varPadding,
      }}
    >
      <Grid item xs={12} className="grid-universal">
        <Typography
          align="left"
          variant="subtitle1"
          style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
        >
          War Terminal - All Positions
        </Typography>
        <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
          Deficap
        </Typography>
        <div
          className="card"
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}
        >
          <DataTable
            rowGroupMode="subheader"
            groupRowsBy="user_name"
            rowGroupHeaderTemplate={headerTemplate}
            style={{ fontColor: "black", fontSize: 13 }}
            rowClassName={rowClass}
            value={results}
            sortField="change_1h"
            sortOrder={-1}
            size="small"
            responsiveLayout="scroll"
            scrollable
            scrollHeight="600px"
          >
            <Column
              field="rank"
              header="rank"
              headerStyle={globalHeaderStyle}
              sortable
            />
            {/* <Column field="monthlyPnl" header="monthlyPnl" headerStyle={globalHeaderStyle} body={monthlyPnlBodyTemplate} sortable /> */}
            {/* <Column field="monthlyRoi" header="monthlyRoi" headerStyle={globalHeaderStyle} body={monthlyRoiBodyTemplate} sortable /> */}
            {/* <Column field="user_name" header="user_name" headerStyle={globalHeaderStyle} sortable /> */}
            <Column
              field="symbol"
              header="symbol"
              headerStyle={globalHeaderStyle}
              sortable
            />
            <Column
              field="side"
              header="side"
              headerStyle={globalHeaderStyle}
              body={sideBodyTemplate}
              sortable
            />
            <Column
              field="value"
              header="net_amount_usd"
              headerStyle={globalHeaderStyle}
              body={valueBodyTemplate}
              sortable
            />
            {/* <Column field="amount" header="amount" headerStyle={globalHeaderStyle} body={amtBodyTemplate} sortable/> */}
            <Column
              field="entryPrice"
              header="entryPrice"
              headerStyle={globalHeaderStyle}
              body={entryPriceBodyTemplate}
              sortable
            />
            <Column
              field="pnl"
              header="pnl"
              headerStyle={globalHeaderStyle}
              body={pnlBodyTemplate}
              sortable
            />
            <Column
              field="time_diff"
              header="last_updated"
              headerStyle={globalHeaderStyle}
              body={timeDiffBodyTemplate}
              sortable
            />
          </DataTable>
        </div>
      </Grid>
    </Grid>
  );
};
