import "../../../styles.css";
import React from "react";
import { ChainTvlTable } from "./TvlComponents";
import { ProtocolTvlTable } from "./TvlComponents";

export default function TvlPage() {

  return (
    <>
      <ChainTvlTable />
      <ProtocolTvlTable />
    </>
  );
}