import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom, #fff, #e1d9ea);
`;


export const Header = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #4c4554;
  font-weight: normal; /* add this line to reduce boldness */
`;


export const Form = styled.form`
  display: flex;
  flex-direction: column;
//   align-items: center;
  width: 100%;
  max-width: 25rem;
  padding: 3rem;
  background-color: #fff;
  border: 0.5px solid purple;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 0.25rem;
  &:read-only {
    background-color: #e1d9ea;
    pointer-events: none;
  }
`;

export const Button = styled.button`
  width: calc(50% - 0.5rem);
  padding: 0.5rem;
  border: none;
  border-radius: 1rem;
  background-color: #8461a9;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #ad6baf;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const ButtonAlt = styled.button`
  width: calc(50% - 0.5rem);
  padding: 0.5rem;
  border: none;
  border-radius: 1rem;
  background-color: #ebe5f3;
  color: #4c4554;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #cfcfcf;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const Link = styled.a`
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #ebe5f3;
  color: #0066cc;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  font-size: 12px; /* set the font size to 16px */

  &:hover {
    background-color: #cfcfcf;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;

  /* add gap or space between the two buttons */
  & ${Button}:first-child {
    margin-right: 1rem;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0.5rem;
  text-align: left;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 12px;
`;