import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useLayerData } from "../hooks/useMaticLayerData";
import {
  getLineChartOptions,
  getHistogramOptions,
  getCombinedOptions,
} from "../../shared/components/LineHistogram.js";
import { getSingleLine, getDoubleLine } from "../../shared/components/DoubleLine.js";
import { getSingleBar, generateBar } from "../../shared/components/Bar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const MaticMarketCapActiveAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[0]["MarketCapActiveAddress"][0]];
      const graphTwoData = [data[0]["MarketCapActiveAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapActiveAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapActiveAddress"></div>
    </div>
  );
};

export const MaticMarketCapUniqueAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[1]["MarketCapUniqueAddress"][0]];
      const graphTwoData = [data[1]["MarketCapUniqueAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapUniqueAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapUniqueAddress"></div>
    </div>
  );
};


export const MaticMarketCapDailyTx = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[3]["MarketCapDailyTx"][0]];
      const graphTwoData = [data[3]["MarketCapDailyTx"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapDailyTx", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapDailyTx"></div>
    </div>
  );
};


export const MaticMarketCapUniqueAddressGrowth = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[2]["MarketCapUniqueAddressGrowth"][0]];
      const graphTwoData = [data[2]["MarketCapUniqueAddressGrowth"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapUniqueAddressGrowth", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="MaticMarketCapUniqueAddressGrowth"
      ></div>
    </div>
  );
};

export const MaticMarketCapDailyNetFlow = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[4]["MarketCapNetFlow"][0]];
      const graphTwoData = [data[4]["MarketCapNetFlow"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapDailyNetFlow", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="MaticMarketCapDailyNetFlow"
      ></div>
    </div>
  );
};

export const MaticMarketCapRevenue = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapRevenue", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="MaticMarketCapRevenue"
      ></div>
    </div>
  );
};

export const MaticMarketCapTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[6]["MarketCapTvl"][0]];
      const graphTwoData = [data[6]["MarketCapTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapTvl"></div>
    </div>
  );
};


export const MaticMarketCapStablecoinTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[7]["MarketCapStablecoinTvl"][0]];
      const graphTwoData = [data[7]["MarketCapStablecoinTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapStablecoinTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapStablecoinTvl"></div>
    </div>
  );
};


export const MaticMarketCapFees = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[8]["MarketCapFees"][0]];
      const graphTwoData = [data[8]["MarketCapFees"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapFees", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="MaticMarketCapFees"
      ></div>
    </div>
  );
};

export const MaticMarketCapRollingPE = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][2]];
      const graphThreeData = [data[5]["MarketCapRevenue"][3]];

      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      const graphThreeId = graphThreeData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData),getSingleLine(graphThreeId, graphThreeData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("MaticMarketCapRollingPE", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticMarketCapRollingPE"></div>
    </div>
  );
};

export const MaticDevs = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][1]];
      const graphTwoData = [data[9]["MarketCapDev"][2]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("MaticDevs", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticDevs"></div>
    </div>
  );
};


export const MaticCommits = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][3]];
      const graphTwoData = [data[9]["MarketCapDev"][4]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("MaticCommits", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="MaticCommits"></div>
    </div>
  );
};