import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { FooterContainer, FooterContent } from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Typography variant="body2" color="textSecondary" align="center">
          Trizon Analytics 2023 | Info
        </Typography>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
