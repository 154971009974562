import "../../../styles.css";
import {
  firstColor,
  secondColor,
} from "../../constants/components/Universal.styles";

const lineWidth = 2;
const fontSize = "10px";
const validColors = [
  "#E57500", // Dark Orange
  "#C11479", // Dark Pink
  "#7A60A8", // Dark Medium Purple
  "#DB00DB", // Dark Magenta
  "#CC3C00", // Dark Orange Red
  "#7523AB", // Dark Blue Violet
  "#D1478B", // Dark Hot Pink
  "#660066", // Dark Purple
  "#D47D00", // Dark Orange
  "#B20F2C", // Dark Crimson
  "#D1432D", // Dark Tomato
  "#7C2696", // Dark Dark Orchid
  "#2A764C", // Dark Sea Green
  "#FF1493", // Deep Pink
  "#FF8C00", // Orange
  secondColor,
  firstColor,
];

export const getSingleBar = (graphId, graphData) => {
  return {
    yAxis: {
      title: {
        text: graphId,
        style: {
          fontSize: fontSize,
        },
      },
    },
    series: [
      {
        name: graphId,
        data: graphData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
      },
    ],
    legend: {
      enabled: false,
    },
  };
};

export const generateBar = (
  barChartOptions,
  varMarginTop,
  varMarginRight,
  varMarginBottom,
  varMarginLeft
) => {
  const reversedOptions = [...barChartOptions].reverse(); // Reverse the order of barChartOptions
  const reversedColors = [...validColors].reverse(); // Reverse the order of validColors
  const combinedSeries = reversedOptions.map((options, index) => ({
    ...options.series[0],
    color:
      index < reversedColors.length ? reversedColors[index] : reversedColors[0],
    dataLabels: {
      enabled: true,
      formatter: function () {
        return this.y !== 0 ? this.y.toLocaleString() : "";
      },
      style: {
        fontSize: fontSize,
        color: "black",
        fontWeight: 500,
      },
      inside: false,
      verticalAlign: "bottom",
      align: "center",
      overflow: "justify",
      crop: true,
    },
  }));

  const firstDataPoint = combinedSeries[0].data[0][0]; // Get the timestamp of the first data point
  const lastDataPoint =
    combinedSeries[combinedSeries.length - 1].data[
      combinedSeries[combinedSeries.length - 1].data.length - 1
    ][0]; // Get the timestamp of the last data point

  const oneWeekBefore = new Date(lastDataPoint);
  oneWeekBefore.setDate(oneWeekBefore.getDate() - 7); // Subtract 7 days from the first data point date

  const oneWeekAfter = new Date(firstDataPoint);
  oneWeekAfter.setDate(oneWeekAfter.getDate() + 7); // Add 7 days to the last data point date

  return {
    chart: {
      type: "bar",
      spacingTop: varMarginTop,
      spacingRight: varMarginRight,
      spacingBottom: varMarginBottom,
      spacingLeft: varMarginLeft,
    },
    title: {
      text: undefined,
    },
    credits: {
      text: "Trizon",
    },
    xAxis: {
      type: "datetime",
      min: oneWeekBefore.getTime(), // Set the minimum value to one week before the first data point
      max: oneWeekAfter.getTime(), // Set the maximum value to one week after the last data point
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getUTCDate().toString().padStart(2, "0");
          const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
          return `${day}/${month}`;
        },
        style: {
          fontSize: fontSize,
        },
      },
    },

    yAxis: {
      title: {
        text: null,
        style: {
          fontSize: fontSize,
        },
      },
      labels: {
        style: {
          fontSize: fontSize,
        },
      },
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const formattedDate = new Date(this.x).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
        });
        const day = formattedDate.slice(3, 5);
        const month = formattedDate.slice(0, 2);
        const formattedTooltipDate = `${day}/${month}`;
        let tooltip = `<span style="font-size: ${fontSize}">${formattedTooltipDate}</span><br/>`;
        for (let point of this.points) {
          const formattedValue = point.y.toLocaleString(); // Format with thousand separators
          tooltip += `<span style="color:${point.color}">${point.series.name}: ${formattedValue}</span><br/>`;
        }
        return tooltip;
      },
    },

    plotOptions: {
      line: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
      series: {
        stacking: "normal", // Stack the bars on top of each other
        pointWidth: 3,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      x: 0,
      y: -20,
      floating: false,
      itemStyle: {
        fontSize: fontSize,
        textOverflow: "ellipsis",
      },
    },
    series: combinedSeries,
  };
};
