// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useBenchmarkData } from "../hooks/useBenchmarkData";
import { getLine, generateLineChart } from "../../shared/components/Line.js";

import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const OiBenchmarkUsd = () => {
  const { isLoading, isError, getData } = useBenchmarkData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data)
      const graphData = data[0].benchmark_usd; // Get the benchmark data

      // Prepare an array to store lineChartOptions for each graph
      const lineChartOptionsArray = [];

      // Loop through the graphData and create lineChartOptions for each item
      graphData.forEach((item) => {
        const lineChart = getLine(item.id, [item]);
        lineChartOptionsArray.push(lineChart);
      });

      console.log(lineChartOptionsArray)

      const combinedOptions = generateLineChart(
        lineChartOptionsArray,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft,
        true // displayPercentage
      );

      Highcharts.chart("OiBenchmarkUsd", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="OiBenchmarkUsd"></div>
    </div>
  );
};

export const OiBenchmarkCoin = () => {
  const { isLoading, isError, getData } = useBenchmarkData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data)
      const graphData = data[1].benchmark_coin; // Get the benchmark data

      // Prepare an array to store lineChartOptions for each graph
      const lineChartOptionsArray = [];

      // Loop through the graphData and create lineChartOptions for each item
      graphData.forEach((item) => {
        const lineChart = getLine(item.id, [item]);
        lineChartOptionsArray.push(lineChart);
      });

      console.log(lineChartOptionsArray)

      const combinedOptions = generateLineChart(
        lineChartOptionsArray,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft,
        true // displayPercentage
      );

      Highcharts.chart("OiBenchmarkCoin", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="OiBenchmarkCoin"></div>
    </div>
  );
};