import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useDailyData, useTwoDayData, useThreeDayData } from "../hooks/useDailyData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import {
  DataTableStyles,
  globalHeaderStyle,
  globalHeaderStyleWithDivider,
  dividerStyle,
} from "../../constants/components/Universal.styles";
import {
  getLineChartOptions,
  getSecondLineChartOptions,
  getCombinedOptions,
} from "../../shared/components/PosNegBar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

const totalDepositBodyTemplate = (rowData) => {
  return rowData.total_deposit_usd_value.toLocaleString("en", { maximumFractionDigits: 0 });
};

const totalWithdrawBodyTemplate = (rowData) => {
  return rowData.total_withdraw_usd_value.toLocaleString("en", { maximumFractionDigits: 0 });
};

const netBodyTemplate = (rowData) => {
  return rowData.net_deposits.toLocaleString("en", { maximumFractionDigits: 0 });
};


export const CexTableDaily = () => {
  const { isLoading, isError, getData } = useDailyData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];

      // Convert "net" values to numbers
      const formattedData = summary_data.map((item) => ({
        ...item,
        total_deposit: parseFloat(item.total_deposit),
        total_withdraw: parseFloat(item.total_withdraw),
        net: parseFloat(item.net),
      }));

      // Set the state with formatted data
      setResult(formattedData);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      value={results}
      sortField="net_deposits"
      sortOrder={1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="400px"
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_deposit_usd_value"
        header="Total Deposits"
        body={totalDepositBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_withdraw_usd_value"
        header="Total Withdraws"
        body={totalWithdrawBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="net_deposits"
        header="Net Deposits"
        body={netBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
    </DataTable>
  );
};

export const CexTableTwoDay = () => {
  const { isLoading, isError, getData } = useTwoDayData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];

      // Convert "net" values to numbers
      const formattedData = summary_data.map((item) => ({
        ...item,
        total_deposit: parseFloat(item.total_deposit),
        total_withdraw: parseFloat(item.total_withdraw),
        net: parseFloat(item.net),
      }));

      // Set the state with formatted data
      setResult(formattedData);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      value={results}
      sortField="net_deposits"
      sortOrder={1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="400px"
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_deposit_usd_value"
        header="Total Deposits"
        body={totalDepositBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_withdraw_usd_value"
        header="Total Withdraws"
        body={totalWithdrawBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="net_deposits"
        header="Net Deposits"
        body={netBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
    </DataTable>
  );
};

export const CexTableThreeDay = () => {
  const { isLoading, isError, getData } = useThreeDayData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];

      // Convert "net" values to numbers
      const formattedData = summary_data.map((item) => ({
        ...item,
        total_deposit: parseFloat(item.total_deposit),
        total_withdraw: parseFloat(item.total_withdraw),
        net: parseFloat(item.net),
      }));

      // Set the state with formatted data
      setResult(formattedData);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      value={results}
      sortField="net_deposits"
      sortOrder={1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="400px"
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_deposit_usd_value"
        header="Total Deposits"
        body={totalDepositBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="total_withdraw_usd_value"
        header="Total Withdraws"
        body={totalWithdrawBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="net_deposits"
        header="Net Deposits"
        body={netBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
    </DataTable>
  );
};