import "../../../styles.css";
import {
  firstColor,
  secondColor,
} from "../../constants/components/Universal.styles";

const lineWidth = 2;
const gridLineWidth = 0.5;
const fontSize = "10px";
const firstGraphColor = firstColor;
const secondGraphColor = secondColor;

function formatAxisLabel() {
    let value = Math.abs(this.value); // Take the absolute value to handle negative values
    let suffix = "";
  
    if (value >= 1e9) {
      value = (value / 1e9).toFixed(0);
      suffix = "b";
    } else if (value >= 1e6) {
      value = (value / 1e6).toFixed(0);
      suffix = "m";
    } else if (value >= 1e3) {
      value = (value / 1e3).toFixed(0);
      suffix = "k";
    }
  
    return (this.value < 0 ? "-" : "") + value + suffix; // Add "-" prefix for negative values
  }
  

export const getLineChartOptions = (graphOneId, graphOneData) => {
  return {
    yAxis: {
      title: {
        text: graphOneId,
        style: {
          fontSize: fontSize,
        },
      },
      min: 0,
      labels: {
        style: {
          fontSize: fontSize,
        },
      },
    },
    series: [
      {
        name: graphOneId,
        data: graphOneData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
  };
};

export const getHistogramOptions = (graphTwoId, graphTwoData) => {
  return {
    yAxis: [
      {
        title: {
          text: graphTwoId,
          style: {
            fontSize: fontSize,
          },
        },
        opposite: true,
        labels: {

          style: {
            fontSize: fontSize,
          },
        },
      },
    ],
    series: [
      {
        name: graphTwoId,
        data: graphTwoData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
        yAxis: 1,
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
        pointWidth: 2,
      },
    },
  };
};

export const getCombinedOptions = (
    lineChartOptions,
    histogramOptions,
    varMarginTop,
    varMarginRight,
    varMarginBottom,
    varMarginLeft
  ) => {
    const combinedSeries = [
      {
        ...lineChartOptions.series[0],
        type: "line",
        color: firstGraphColor,
      },
      {
        ...histogramOptions.series[0],
        type: "column",
        yAxis: 1,
        color: secondGraphColor,
      },
    ];
  
    const lineMin = Math.min(
      ...lineChartOptions.series[0].data.map((item) => item[1])
    );
    const histogramMin = Math.min(
      ...histogramOptions.series[0].data.map((item) => item[1])
    );
  
    return {
      chart: {
        spacingTop: varMarginTop,
        spacingRight: varMarginRight,
        spacingBottom: varMarginBottom,
        spacingLeft: varMarginLeft,
      },
      title: {
        text: undefined,
      },
      credits: {
        text: "Trizon",
      },
      series: combinedSeries,
      xAxis: {
        type: "datetime",
        labels: {
          formatter: function () {
            const date = new Date(this.value);
            const day = date.getUTCDate().toString().padStart(2, "0");
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
            return `${day}/${month}`;
          },
          style: {
            fontSize: fontSize,
          },
        },
        tickInterval: 14 * 24 * 60 * 60 * 1000,
      },
      yAxis: [
        {
          ...lineChartOptions.yAxis,
          title: {
            ...lineChartOptions.yAxis.title,
            style: {
              fontSize: fontSize,
            },
          },
          labels: {
            ...lineChartOptions.yAxis.labels,
            formatter: function () {
              return this.value !== 0 ? formatAxisLabel.call(this) : "";
            },
            style: {
              fontSize: fontSize,
            },
          },
          min: lineMin > 0 ? 0 : null, // Set min to 0 if lineMin > 0, otherwise null for auto min
          opposite: false, // Set opposite to false for the left y-axis
        },
        {
          ...histogramOptions.yAxis[0],
          title: {
            ...histogramOptions.yAxis[0].title,
            style: {
              fontSize: fontSize,
            },
          },
          labels: {
            ...histogramOptions.yAxis[0].labels,
            formatter: function () {
                if (this.isFirst) {
                  return ""; // Remove the label for the last tick on the right y-axis
                } else {
                  return formatAxisLabel.call(this); // Apply formatAxisLabel function to the rest of the labels
                }
              },
            style: {
              fontSize: fontSize,
            },
          },
          opposite: true, // Set opposite to true for the right y-axis
          min: histogramMin > 0 ? 0 : null, // Set min to 0 if histogramMin > 0, otherwise null for auto min
        },
      ],
      tooltip: {
        shared: true,
        formatter: function () {
          const formattedDate = new Date(this.x).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
          });
          const day = formattedDate.slice(3, 5);
          const month = formattedDate.slice(0, 2);
          const formattedTooltipDate = `${day}/${month}`;
          let tooltip = `<span style="font-size: ${fontSize}">${formattedTooltipDate}</span><br/>`;
          for (let point of this.points) {
            const formattedValue = point.y.toLocaleString(); // Format with thousand separators
            tooltip += `<span style="color:${point.color}">${point.series.name}: ${formattedValue}</span><br/>`;
          }
          return tooltip;
        },
      },
      plotOptions: {
        line: {
          lineWidth: lineWidth,
          marker: {
            enabled: false,
          },
        },
        column: {
          borderWidth: 0,
          pointPadding: 0,
          groupPadding: 0,
          pointWidth: 2,
        },
      },
      legend: {
        align: "center",
        verticalAlign: "top",
        x: 0,
        y: -20,
        floating: false,
        itemStyle: {
          fontSize: fontSize,
          textOverflow: "ellipsis",
        },
      },
    };
  };
  