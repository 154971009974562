import React, { createContext, useState, useEffect } from "react";
import { Pool, confirmRegistration } from "../../../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

const AccountContext = createContext();

const Account = (props) => {
  const [role, setRole] = useState("notloggedin"); // set default role to "notloggedin"
  const [userEmail, setUserEmail] = useState("");

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const getUserAttributes = async () => {
    try {
      const session = await getSession();
      const attributes = session.getIdToken().payload;
      const userRole = attributes["custom:role"];
      setUserEmail(attributes["email"])
      setRole(userRole || "notloggedin"); // set role state variable to user role, or "notloggedin" if role is undefined
      return attributes;
    } catch (err) {
    }
  };

  useEffect(() => {
    async function fetchUserAttributes() {
      try {
        const attributes = await getUserAttributes();
        setRole(attributes["custom:role"] || "notloggedin");
      } catch (err) {
      }
    }

    fetchUserAttributes();
  }, []);

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
          window.location.href = "/edit";
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const forgotPassword = async (email) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const resetPassword = async (email, verificationCode, newPassword) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool,
      });

      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        getUserAttributes,
        logout,
        forgotPassword,
        resetPassword,
        role,
        userEmail
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
