import { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = "https://api.forkeddigital.vip/layerftm";

export const useLayerData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(apiUrl);
        if (result.status === 200) {
          setData(result.data);
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getData = () => {
    return data;
  };

  return {
    isLoading,
    isError,
    getData,
  };
};
