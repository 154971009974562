import "../../../styles.css";
import React, { useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import {
  TopTraderSummaryTable,
  TopTraderPositionTable,
  TopTraderRecentTradesSmall,
  TopTraderRecentTradesMedium,
  TopTraderRecentTradesLarge,
  TopTraderBiggest,
} from "./TopTradersContent";
import { LongShortRatioPositionBenchmark } from "../../top-traders/components/TopTradersRatioPositionContent";
import { LongShortRatioAccountBenchmark } from "../../top-traders/components/TopTradersRatioAccountContent";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  ButtonContainer,
  SubButtonContainer,
  Card,
} from "../../constants/components/Universal.styles";
import Grid from "@material-ui/core/Grid";

export default function TopTraderDPage() {
  return (
    <>
      <ButtonContainer></ButtonContainer>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Top Traders - All Positions</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderPositionTable />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $1m</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesMedium />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $10m</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesLarge />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $100k</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesSmall />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Long/Short Ratio - Top ACCOUNTS</GraphTitle>
            <GraphSubTitle>Benchmark</GraphSubTitle>
            <Card>
              <LongShortRatioAccountBenchmark />
            </Card>
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>Long/Short Ratio - POSITIONS</GraphTitle>
            <GraphSubTitle>Benchmark</GraphSubTitle>
            <Card>
              <LongShortRatioPositionBenchmark />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <GridUniversalStyle>
          <GraphTitle>Top Trader Coin Summary</GraphTitle>
          <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
          <Card>
            <TopTraderSummaryTable />
          </Card>
        </GridUniversalStyle>
      </Grid>
    </>
  );
}
