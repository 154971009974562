import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";
import {
  DataTableStyles,
  globalHeaderStyle,
} from "../../constants/components/Universal.styles";

const maxSize_url = "https://api.forkeddigital.vip/maxsizeleverage";

const init = (initialState) => initialState;

const MaintenanceMarginRateBodyTemplate = (rowData) => {
  return rowData.MaintenanceMarginRate.toLocaleString("en", {
    style: "percent",
    maximumFractionDigits: 0,
  });
};

const MinSizeTemplate = (rowData) => {
  return rowData.MinSize.toLocaleString("en", { maximumFractionDigits: 0 });
};

const MaxSizeTemplate = (rowData) => {
  return rowData.MaxSize.toLocaleString("en", { maximumFractionDigits: 0 });
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataLoaded":
      return { ...state, results: action.payload, loading: false };
    default:
      throw new Error();
  }
};

export const MaxSizeLeverageTable = () => {
  const initialState = {
    results: [],
    loading: true,
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;

  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(maxSize_url);
        if (result.status == 200) {
          setData(result);
          return result;
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData()
      // .then(res => console.log(res.data[0].data))
      .then((res) =>
        dispatch({ type: "dataLoaded", payload: res.data[0].data })
      );
    // dispatch({ type: "dataLoaded", payload: res.data[0].usd[0].stables });

    // if (loading) {
    //     dispatch({ type: "dataLoaded", payload: data.data[0].usd[0].stables });
    // }
    initFilters1();
  }, [loading]);

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue1("");
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };
  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <DataTable
    className="sticky-header-table"
    style={DataTableStyles.dataTableUniversal}
    {...DataTableStyles.dataTableSettings}
      header={header1}
      filters={filters1}
      value={results}
      sortField="total_traders"
      sortOrder={-1}
    >
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="MinSize"
        header="MinSize"
        headerStyle={globalHeaderStyle}
        body={MinSizeTemplate}
        sortable
      />
      <Column
        field="MaxSize"
        header="MaxSize"
        headerStyle={globalHeaderStyle}
        body={MaxSizeTemplate}
        sortable
      />
      <Column
        field="MaintenanceMarginRate"
        header="MaintenanceMarginRate"
        headerStyle={globalHeaderStyle}
        body={MaintenanceMarginRateBodyTemplate}
        sortable
      />
      <Column
        field="maxOpenPosLeverage"
        header="MaxOpenPosLeverage"
        headerStyle={globalHeaderStyle}
        sortable
      />
      {/* <Column field="usd_value" header="net_amount_usd" headerStyle={globalHeaderStyle} body={netAmtBodyTemplate} sortable className="net-amount-column" /> */}
    </DataTable>
  );
};
