import "../../../styles.css";
import {
  firstColor,
  secondColor,
} from "../../constants/components/Universal.styles";

const lineWidth = 2;
const fontSize = "10px";
const firstGraphColor = firstColor;
const validColors = [
  secondColor,
  "#E57500", // Dark Orange
  "#C11479", // Dark Pink
  "#7A60A8", // Dark Medium Purple
  "#DB00DB", // Dark Magenta
  "#CC3C00", // Dark Orange Red
  "#7523AB", // Dark Blue Violet
  "#D1478B", // Dark Hot Pink
  "#660066", // Dark Purple
  "#D47D00", // Dark Orange
  "#B20F2C", // Dark Crimson
  "#D1432D", // Dark Tomato
  "#7C2696", // Dark Dark Orchid
  "#2A764C", // Dark Sea Green
  "#FF1493", // Deep Pink
  "#FF8C00", // Orange
  "#800000", // Maroon
  "#2F4F4F", // Dark Slate Gray
];



function formatAxisLabel() {
  let value = this.value;
  let suffix = "";

  if (value >= 1e9) {
    value = (value / 1e9).toFixed(0);
    suffix = "b";
  } else if (value >= 1e6) {
    value = (value / 1e6).toFixed(0);
    suffix = "m";
  } else if (value >= 1e3) {
    value = (value / 1e3).toFixed(0);
    suffix = "k";
  }
  return value + suffix;
}

export const getSingleLine = (graphId, graphData) => {
  return {
    yAxis: {
      title: {
        text: graphId,
        style: {
          fontSize: fontSize,
        },
      },
      min: 0,
      labels: {
        formatter: function () {
          return this.value !== 0 ? formatAxisLabel.call(this) : "";
        },
        style: {
          fontSize: fontSize,
        },
      },
    },
    series: [
      {
        name: graphId,
        data: graphData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
  };
};

export const getDoubleLine = (
  lineChartOptionsOne,
  lineChartOptionsTwo,
  varMarginTop,
  varMarginRight,
  varMarginBottom,
  varMarginLeft
) => {
  const combinedSeries = [
    {
      ...lineChartOptionsOne.series[0],
      type: "line",
      color: firstGraphColor, // Set the color for the first line graph
    },
    ...lineChartOptionsTwo.map((options, index) => ({
      ...options.series[0],
      type: "line",
      yAxis: 1,
      color: index < validColors.length ? validColors[index] : validColors[0], // Set the color for each line graph
    })),
  ];

  return {
    chart: {
      spacingTop: varMarginTop,
      spacingRight: varMarginRight,
      spacingBottom: varMarginBottom,
      spacingLeft: varMarginLeft,
    },
    title: {
      text: undefined,
    },
    credits: {
      text: "Trizon",
    },
    series: combinedSeries,
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getUTCDate().toString().padStart(2, "0");
          const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
          return `${day}/${month}`;
        },
        style: {
          fontSize: fontSize,
        },
      },
      tickInterval: 14 * 24 * 60 * 60 * 1000,
    },
    yAxis: [
      {
        ...lineChartOptionsOne.yAxis,
        title: {
          ...lineChartOptionsOne.yAxis.title,
          style: {
            fontSize: fontSize,
          },
        },
        labels: {
          ...lineChartOptionsOne.yAxis.labels,
          style: {
            fontSize: fontSize,
          },
        },
      },
      {
        ...lineChartOptionsTwo[0].yAxis,
        title: {
          ...lineChartOptionsTwo[0].yAxis.title,
          style: {
            fontSize: fontSize,
          },
        },
        labels: {
          ...lineChartOptionsTwo[0].yAxis.labels,
          style: {
            fontSize: fontSize,
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        const formattedDate = new Date(this.x).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
        });
        const day = formattedDate.slice(3, 5);
        const month = formattedDate.slice(0, 2);
        const formattedTooltipDate = `${day}/${month}`;
        let tooltip = `<span style="font-size: ${fontSize}">${formattedTooltipDate}</span><br/>`;
        for (let point of this.points) {
          const formattedValue = point.y.toLocaleString(); // Format with thousand separators
          tooltip += `<span style="color:${point.color}">${point.series.name}: ${formattedValue}</span><br/>`;
        }
        return tooltip;
      },
    },
    plotOptions: {
      line: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      x: 0,
      y: -20,
      floating: false,
      itemStyle: {
        fontSize: fontSize,
        textOverflow: "ellipsis",
      },
    },
  };
};
