import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import axios from "axios";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";
import {
  DataTableStyles,
  globalHeaderStyle,
} from "../../constants/components/Universal.styles";
import { useBtcData } from "../hooks/useDashboardBtcData";

const pricesBodyTemplate = (rowData) => {
  return rowData.current_price.toLocaleString("en", {
    maximumFractionDigits: 10,
  });
};

const oneHrBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_1h_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_1h_in_currency >= -0.2 &&
      rowData.price_change_percentage_1h_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_1h_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_1h_in_currency > 0 &&
      rowData.price_change_percentage_1h_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_1h_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_1h_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const oneWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_7d_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_7d_in_currency >= -0.2 &&
      rowData.price_change_percentage_7d_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_7d_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_7d_in_currency > 0 &&
      rowData.price_change_percentage_7d_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_7d_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_7d_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const twoWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_14d_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_14d_in_currency >= -0.2 &&
      rowData.price_change_percentage_14d_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_14d_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_14d_in_currency > 0 &&
      rowData.price_change_percentage_14d_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_14d_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_14d_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const oneMonthBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_30d_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_30d_in_currency >= -0.2 &&
      rowData.price_change_percentage_30d_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_30d_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_30d_in_currency > 0 &&
      rowData.price_change_percentage_30d_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_30d_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_30d_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const twoHundredDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_200d_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_200d_in_currency >= -0.2 &&
      rowData.price_change_percentage_200d_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_200d_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_200d_in_currency > 0 &&
      rowData.price_change_percentage_200d_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_200d_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_200d_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const athBodyTemplate = (rowData) => {
  return rowData.ath.toLocaleString("en", {
    maximumFractionDigits: 10,
  });
};

const athDropBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ath_change_percentage < -0.2,
    "mid-low-percentage":
      rowData.ath_change_percentage >= -0.2 &&
      rowData.ath_change_percentage < 0,
    "mid-percentage": rowData.ath_change_percentage === 0,
    "mid-high-percentage":
      rowData.ath_change_percentage > 0 && rowData.ath_change_percentage < 0.2,
    "high-percentage": rowData.ath_change_percentage >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ath_change_percentage.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 4,
      })}
    </div>
  );
};

const fdvBodyTemplate = (rowData) => {
  const fdv = rowData.fully_diluted_valuation.toLocaleString("en", {
    maximumFractionDigits: 10,
  });
  return fdv;
};

const mcapBodyTemplate = (rowData) => {
  return rowData.market_cap.toLocaleString("en", {
    maximumFractionDigits: 10,
  });
};

export const DashboardBenchmark = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["benchmark"]);
      const summary_data = data[0]["btc"]["benchmark"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="benchmark"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="1h"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardExchanges = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["exchanges"]);
      const summary_data = data[0]["btc"]["exchanges"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="exchanges"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardLayers = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["layers"]);
      const summary_data = data[0]["btc"]["layers"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="layers"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardDex = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["dex_amm"]);
      const summary_data = data[0]["btc"]["dex_amm"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="dex_amm"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};


export const DashboardBridges = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["bridges"]);
      const summary_data = data[0]["btc"]["bridges"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="bridges"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardLending = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["lending"]);
      const summary_data = data[0]["btc"]["lending"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="lending"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardDerivatives = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["derivatives"]);
      const summary_data = data[0]["btc"]["derivatives"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="derivatives"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardAssetMgmt = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["asset_mgmt"]);
      const summary_data = data[0]["btc"]["asset_mgmt"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="asset_mgmt"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardGamefi = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["gamefi"]);
      const summary_data = data[0]["btc"]["gamefi"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="gamefi"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardDao = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["dao"]);
      const summary_data = data[0]["btc"]["dao"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="dao"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardNft = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["nft"]);
      const summary_data = data[0]["btc"]["nft"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="nft"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardMeme = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["memes"]);
      const summary_data = data[0]["btc"]["memes"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="memes"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardPrivacy = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["privacy"]);
      const summary_data = data[0]["btc"]["privacy"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="privacy"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};

export const DashboardRandom = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["random"]);
      const summary_data = data[0]["btc"]["random"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="random"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};



export const DashboardBitcoin = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["bitcoin"]);
      const summary_data = data[0]["btc"]["bitcoin"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="bitcoin"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};


export const DashboardRwa = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["rwa"]);
      const summary_data = data[0]["btc"]["rwa"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="rwa"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};


export const DashboardNewLOne = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["newl1"]);
      const summary_data = data[0]["btc"]["newl1"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="newl1"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};


export const DashboardCosmos = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["cosmos"]);
      const summary_data = data[0]["btc"]["cosmos"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="cosmos"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};


export const DashboardGameFiLayer = () => {
  const { isLoading, isError, getData } = useBtcData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data[0]["btc"]["gamefilayer"]);
      const summary_data = data[0]["btc"]["gamefilayer"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      id="gamefilayer"
      style={DataTableStyles.dataTableUniversal}
      value={results}
      sortField="price_change_percentage_1h_in_currency"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="symbol"
        headerStyle={globalHeaderStyle}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="current_price"
        header="price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_1h_in_currency"
        header="1h"
        headerStyle={globalHeaderStyle}
        body={oneHrBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_7d_in_currency"
        header="7d"
        headerStyle={globalHeaderStyle}
        body={oneWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_14d_in_currency"
        header="14d"
        headerStyle={globalHeaderStyle}
        body={twoWeekBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_30d_in_currency"
        header="30d"
        headerStyle={globalHeaderStyle}
        body={oneMonthBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="price_change_percentage_200d_in_currency"
        header="200d"
        headerStyle={globalHeaderStyle}
        body={twoHundredDayBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath"
        header="ath"
        headerStyle={globalHeaderStyle}
        body={athBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="ath_change_percentage"
        header="ath_drop"
        headerStyle={globalHeaderStyle}
        body={athDropBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="fully_diluted_valuation"
        header="fdv (mm)"
        headerStyle={globalHeaderStyle}
        body={fdvBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
      <Column
        field="market_cap"
        header="mcap (mm)"
        headerStyle={globalHeaderStyle}
        body={mcapBodyTemplate}
        style={{ width: "8%" }}
        sortable
      />
    </DataTable>
  );
};