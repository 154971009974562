import "../../../styles.css";
import React from "react";
import { OrderBookBtcOne } from "./OrderBookGraphsContent";
import { OrderBookBtcTwo } from "./OrderBookGraphsContent";
import { OrderBookBtcFive } from "./OrderBookGraphsContent";
import { OrderBookBtcTen } from "./OrderBookGraphsContent";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  ButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
} from "../../constants/components/Universal.styles";

const varPadding = 20;

export default function OrderBookHistoryGraphs() {
  return (
    <>
      <ButtonContainer>
        <div
          style={{
            paddingTop: 0,
            height: "auto",
            paddingBottom: 10,
            paddingLeft: 20,
            position: "sticky",
            top: 50,
            backgroundColor: "white",
            zIndex: 100,
          }}
        >
          <div className="sub-menu-button">
            <Button size="small" disableElevation variant="contained">
              <Link to="/orderbook">Spot Depth</Link>
            </Button>
          </div>
          <div className="sub-menu-button">
            <Button size="small" disableElevation variant="contained">
              <Link to="/orderbookperps">Perps Depth</Link>
            </Button>
          </div>
        </div>
      </ButtonContainer>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12} style={GridUniversalStyle.gridUniversal}>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
          >
            BTC Bid/Ask 1%
          </Typography>
          <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
            Binance
          </Typography>
          <div style={{ height: "350px" }}>
            <OrderBookBtcOne />
          </div>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12} style={GridUniversalStyle.gridUniversal}>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
          >
            BTC Bid/Ask 2%
          </Typography>
          <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
            Binance
          </Typography>
          <div className="App">
            <OrderBookBtcTwo />
          </div>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12} style={GridUniversalStyle.gridUniversal}>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
          >
            BTC Bid/Ask 5%
          </Typography>
          <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
            Binance
          </Typography>
          <div className="App">
            <OrderBookBtcFive />
          </div>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12} style={GridUniversalStyle.gridUniversal}>
          <Typography
            align="left"
            variant="subtitle1"
            style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
          >
            BTC Bid/Ask 10%
          </Typography>
          <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
            Binance
          </Typography>
          <div className="App">
            <OrderBookBtcTen />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
