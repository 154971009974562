import React, { useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import { WarTerminalPositionTable } from "./WarTerminalContent";

export default function WarTerminalPage() {
  const { role } = useContext(AccountContext);

  return (
    <>
      {role === "admin" && <WarTerminalPositionTable />}
      {/* <WarTerminalPositionTable /> */}
    </>
  );
}
