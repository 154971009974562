import "../../../styles.css";
import React, { useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import {
  ArbMarketCapActiveAddress,
  ArbMarketCapUniqueAddress,
  ArbMarketCapUniqueAddressGrowth,
  ArbMarketCapDailyTx,
  ArbMarketCapDailyNetFlow,
  ArbMarketCapRevenue,
  ArbMarketCapTvl,
  ArbMarketCapStablecoinTvl,
  ArbMarketCapFees,
  ArbMarketCapRollingPE,
  ArbDevs,
  ArbCommits,
} from "./LayerComponentArb";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  ButtonContainer,
  SubButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  ButtonBox,
  TextContainer,
} from "../../constants/components/Universal.styles";

export default function LayerEvaluationArb() {
  const { role } = useContext(AccountContext);

  if (role === "notloggedin") {
    return (
      <>
        <ButtonContainer></ButtonContainer>
        <ContentNotAvailable>
          <NoticeTitle>Content Not Available</NoticeTitle>
          <NoticeSubTitle>Login or Register for Access</NoticeSubTitle>
          <NoticeButtonContainer>
            <StyledLink to="/login">
              <LoginButton>Login</LoginButton>
            </StyledLink>
            <StyledLink to="/register">
              <LoginButtonAlt>Register</LoginButtonAlt>
            </StyledLink>
          </NoticeButtonContainer>
        </ContentNotAvailable>
      </>
    );
  }

  return (
    <>
      <ButtonContainer>
        <StyledLink to="/layers/eth">
          <ButtonBox>ETHEREUM</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/matic">
          <ButtonBox>POLYGON/MATIC</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/op">
          <ButtonBox>OPTIMISM</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/arb">
          <ButtonBox>ARBITRUM</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/bsc">
          <ButtonBox>BINANCE SMART CHAIN</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/ftm">
          <ButtonBox>FANTOM</ButtonBox>
        </StyledLink>

        <StyledLink to="/layers/avax">
          <ButtonBox>AVALANCHE</ButtonBox>
        </StyledLink>
      </ButtonContainer>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Market Cap vs Active Addresses</GraphTitle>
            <GraphSubTitle>Arbitrum</GraphSubTitle>
            <ArbMarketCapActiveAddress />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>Market Cap vs Unique Addresses</GraphTitle>
            <GraphSubTitle>Arbitrum</GraphSubTitle>
            <ArbMarketCapUniqueAddress />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
        <GridUniversalStyle hasBorderRight>
          <GraphTitle>Market Cap vs Fresh Address Growth</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapUniqueAddressGrowth />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
        <GridUniversalStyle>
          <GraphTitle>Market Cap vs Daily Transactions</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapDailyTx />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
        <GridUniversalStyle hasBorderRight>
          <GraphTitle>Market Cap vs Net Flow</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapDailyNetFlow />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
        <GridUniversalStyle>
          <GraphTitle>Market Cap vs Daily Revenue (Tip Fees)</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapRevenue />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
        <GridUniversalStyle hasBorderRight>
          <GraphTitle>Market Cap vs TVL</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapTvl />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
        <GridUniversalStyle>
          <GraphTitle>Market Cap vs Stablecoin TVL</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapStablecoinTvl />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
        <GridUniversalStyle hasBorderRight>
          <GraphTitle>Market Cap vs Daily Fees (Burned)</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapFees />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
        <GridUniversalStyle>
          <GraphTitle>Market Cap vs Rolling PE</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbMarketCapRollingPE />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
        <GridUniversalStyle hasBorderRight>
          <GraphTitle>Active Developers</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbDevs />
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
        <GridUniversalStyle>
          <GraphTitle>Active Commits</GraphTitle>
          <GraphSubTitle>Arbitrum</GraphSubTitle>
          <ArbCommits />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
        <GridUniversalStyle>
          <TextContainer>
            <a
              href="https://forms.gle/4Njv5TSAN4EPGYvx7"
              target="_blank"
              rel="noArbener noreferrer"
            >
              <SmallSubHeader>
                Click here to help us with feedback or suggestions
              </SmallSubHeader>
            </a>
          </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
