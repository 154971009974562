import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useLayerData } from "../hooks/useAvaxLayerData";
import {
  getLineChartOptions,
  getHistogramOptions,
  getCombinedOptions,
} from "../../shared/components/LineHistogram.js";
import { getSingleLine, getDoubleLine } from "../../shared/components/DoubleLine.js";
import { getSingleBar, generateBar } from "../../shared/components/Bar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const AvaxMarketCapActiveAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[0]["MarketCapActiveAddress"][0]];
      const graphTwoData = [data[0]["MarketCapActiveAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapActiveAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapActiveAddress"></div>
    </div>
  );
};

export const AvaxMarketCapUniqueAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[1]["MarketCapUniqueAddress"][0]];
      const graphTwoData = [data[1]["MarketCapUniqueAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapUniqueAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapUniqueAddress"></div>
    </div>
  );
};


export const AvaxMarketCapDailyTx = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[3]["MarketCapDailyTx"][0]];
      const graphTwoData = [data[3]["MarketCapDailyTx"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapDailyTx", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapDailyTx"></div>
    </div>
  );
};


export const AvaxMarketCapUniqueAddressGrowth = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[2]["MarketCapUniqueAddressGrowth"][0]];
      const graphTwoData = [data[2]["MarketCapUniqueAddressGrowth"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapUniqueAddressGrowth", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="AvaxMarketCapUniqueAddressGrowth"
      ></div>
    </div>
  );
};

export const AvaxMarketCapDailyNetFlow = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[4]["MarketCapNetFlow"][0]];
      const graphTwoData = [data[4]["MarketCapNetFlow"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapDailyNetFlow", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="AvaxMarketCapDailyNetFlow"
      ></div>
    </div>
  );
};

export const AvaxMarketCapRevenue = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapRevenue", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="AvaxMarketCapRevenue"
      ></div>
    </div>
  );
};

export const AvaxMarketCapTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[6]["MarketCapTvl"][0]];
      const graphTwoData = [data[6]["MarketCapTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapTvl"></div>
    </div>
  );
};


export const AvaxMarketCapStablecoinTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[7]["MarketCapStablecoinTvl"][0]];
      const graphTwoData = [data[7]["MarketCapStablecoinTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapStablecoinTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapStablecoinTvl"></div>
    </div>
  );
};


export const AvaxMarketCapFees = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[8]["MarketCapFees"][0]];
      const graphTwoData = [data[8]["MarketCapFees"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapFees", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="AvaxMarketCapFees"
      ></div>
    </div>
  );
};

export const AvaxMarketCapRollingPE = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][2]];
      const graphThreeData = [data[5]["MarketCapRevenue"][3]];

      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      const graphThreeId = graphThreeData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData),getSingleLine(graphThreeId, graphThreeData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("AvaxMarketCapRollingPE", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxMarketCapRollingPE"></div>
    </div>
  );
};

export const AvaxDevs = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][1]];
      const graphTwoData = [data[9]["MarketCapDev"][2]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("AvaxDevs", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxDevs"></div>
    </div>
  );
};


export const AvaxCommits = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][3]];
      const graphTwoData = [data[9]["MarketCapDev"][4]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("AvaxCommits", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="AvaxCommits"></div>
    </div>
  );
};