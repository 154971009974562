import React, { useState, useContext, useEffect } from "react";
import { Pool, confirmRegistration } from "../../../UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { AccountContext } from "./Account";
import {
  FormContainer,
  Header,
  SmallSubHeader,
  Form,
  Input,
  Button,
  ButtonAlt,
  ErrorMessage,
  ButtonContainer,
  CenteredStyles,
} from "./Login.styles";
import jwt_decode from "jwt-decode";
import Footer from "../../footer/components/Footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [loginCode, setLoginCode] = useState("");
  const [referral, setReferral] = useState("");

  const {
    authenticate,
    forgotPassword: forgotPasswordContext,
    resetPassword: resetPasswordContext,
  } = useContext(AccountContext);

  useEffect(() => {
    const loadGoogleSignInScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const initializeGoogleSignIn = async () => {
      try {
        await loadGoogleSignInScript();
        google.accounts.id.initialize({
          client_id:
            "1085210070592-gsf7g40u1tnvthvquo2lafi8nkd9650m.apps.googleusercontent.com",
          callback: handleLoginGoogle,
          ux_mode: "popup",
        });
        google.accounts.id.renderButton(document.getElementById("signinDiv"), {
          theme: "standard", // Change the theme to 'filled'
          size: "large", // Change the size to 'medium'
          text: "Sign in with Google", // Change the button text
          width: 250, // Change the button width (in pixels)
          type: "outline", // Open as a pop-up
          shape: "square",
        });
      } catch (error) {}
    };
    initializeGoogleSignIn();
  }, []);

  const handleLoginGoogle = async (response) => {
    try {
      const googleToken = response.credential;
      const userObject = jwt_decode(googleToken);
      const email = userObject.email;
      const sub = userObject.sub;
      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);

      await authenticate(email, sub)
        .then((data) => {
          // window.location.href = "/";
        })
        .catch((err) => {
          setError(
            err.message.includes("Missing required parameter USERNAME")
              ? "Error: Missing Email"
              : err.code === "PasswordResetRequiredException"
              ? "Please reset your password"
              : err.message
          );
          if (err.code === "UserNotConfirmedException") {
            setShowVerificationCode(true);
          } else if (err.code === "PasswordResetRequiredException") {
            setResetPassword(true);
          } else if (err.code === "NotAuthorizedException") {
            handleRegisterGoogle(response);
          } else if (err.code === "UserNotFoundException") {
            setForgotPassword(true);
          }
        });
    } catch (error) {
      console.log("Error handling Google Sign-In callback:", error);
    }
  };

  const handleRegisterGoogle = async (response) => {
    try {
      const googleToken = response.credential;
      const userObject = jwt_decode(googleToken);
      const email = userObject.email;
      const sub = userObject.sub;
      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);

      const attributes = [
        { Name: "updated_at", Value: unixTimestamp.toString() },
        { Name: "custom:discord", Value: "" },
        { Name: "custom:role", Value: "basic" },
        { Name: "custom:telegram", Value: "" },
        { Name: "custom:twitter", Value: "" },
        { Name: "custom:referral", Value: "" },
      ];

      Pool.signUp(email, sub, attributes, null, (signupErr, signupResult) => {
        if (signupErr) {
          // An error occurred during the sign-up process
          console.log("Error signing up user:", signupErr);
          return;
        }
        // Sign-up successful, handle verification code if necessary
        console.log("User signed up successfully:", signupResult);
        authenticate(email, sub)
          .then((data) => {
            // window.location.href = "/";
          })
          .catch((err) => {
            setError(
              err.message.includes("Missing required parameter USERNAME")
                ? "Error: Missing Email"
                : err.code === "PasswordResetRequiredException"
                ? "Please reset your password"
                : err.message
            );
            if (err.code === "UserNotConfirmedException") {
              setShowVerificationCode(true);
            } else if (err.code === "PasswordResetRequiredException") {
              setResetPassword(true);
            } else if (err.code === "UserNotFoundException") {
              setForgotPassword(true);
            }
          });
      });
    } catch (error) {
      console.log("Error handling Google Sign-In callback:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (resetPassword) {
      if (password !== verifyPassword) {
        setError("Passwords do not match");
        return;
      }
      try {
        await resetPasswordContext(email, loginCode, password);
        setError("Password reset successful, please log in again");
        setResetPassword(false);
      } catch (err) {
        setError(err.message);
      }
    } else if (forgotPassword) {
      try {
        await forgotPasswordContext(email);
        setError("Password reset code sent to your email address");
        setForgotPassword(false);
      } catch (err) {
        setError(err.message);
      }
    } else {
      authenticate(email, password)
        .then((data) => {
          console.log(data);
          // window.location.href = "/";
        })
        .catch((err) => {
          setError(
            err.message.includes("Missing required parameter USERNAME")
              ? "Error: Missing Email"
              : err.code === "PasswordResetRequiredException"
              ? "Please reset your password"
              : err.message
          );
          if (err.code === "UserNotConfirmedException") {
            setShowVerificationCode(true);
          } else if (err.code === "PasswordResetRequiredException") {
            setResetPassword(true);
          } else if (err.code === "UserNotFoundException") {
            setForgotPassword(true);
          }
        });
    }
  };

  const handleResetPasswordClick = () => {
    setResetPassword(true);
    setShowReset(false);
    setVerifyPassword("");
    setPassword("");
    setLoginCode("");
    console.log(showReset);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    try {
      await forgotPasswordContext(email);
      setError(
        "Password reset code sent to your email if an account exists with Trizon"
      );
      setShowReset(true);
      setResetPassword(true);
    } catch (err) {
      setError(
        err.message.includes(
          "Value at 'username' failed to satisfy constraint: Member must have length greater than or equal to 1"
        )
          ? "Error: Missing Email"
          : err.code === "PasswordResetRequiredException"
          ? "Please reset your password"
          : err.message
      );
    }
  };

  const handleResendCode = async (event) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email,
      UserPool: Pool,
    });

    user.resendConfirmationCode(function (err, result) {
      if (err) {
        setError(err.message);
      } else {
        setError("Code resent successfully");
        setIsCodeResent(true);
      }
    });
  };

  const handleVerify = async (event) => {
    event.preventDefault();

    if (showVerificationCode && verificationCode.trim().length === 0) {
      setError("Invalid verification code provided, please try again.");
      return;
    }

    try {
      await confirmRegistration(email, verificationCode);

      await authenticate(email, password);
      window.location.href = "/";
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignUpClick = () => {
    window.location.href = "/register";
  };

  return (
    <>
      <FormContainer>
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        {resetPassword ? (
          <Form onSubmit={handleSubmit}>
            <Header>Reset Password</Header>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onFocus={() => setError("")}
              required
            />
            {showReset ? (
              <>
                <Input
                  type="text"
                  placeholder="Verification code"
                  value={loginCode}
                  onChange={(event) => setLoginCode(event.target.value)}
                  required
                />
                <Input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  required
                />
                <Input
                  type="password"
                  placeholder="Verify Password"
                  value={verifyPassword}
                  onChange={(event) => setVerifyPassword(event.target.value)}
                  required
                />
              </>
            ) : null}
            {showReset ? (
              <>
                <Button type="submit">Reset Password</Button>
                <CenteredStyles>
                  <ButtonAlt
                    onClick={() => {
                      setError(""); // Set the error state to an empty string
                      setResetPassword(false); // Perform any other necessary actions
                    }}
                  >
                    Cancel
                  </ButtonAlt>
                </CenteredStyles>
              </>
            ) : (
              <>
                <Button onClick={handleResetPassword}>Send Code</Button>
                <CenteredStyles>
                  <ButtonAlt
                    onClick={() => {
                      setError(""); // Set the error state to an empty string
                      setResetPassword(false); // Perform any other necessary actions
                    }}
                  >
                    Cancel
                  </ButtonAlt>
                </CenteredStyles>
              </>
            )}
            {/* <CenteredStyles>
              <div>---</div>
            </CenteredStyles>
            <CenteredStyles>
              <div id="signinDiv"></div>
            </CenteredStyles> */}
          </Form>
        ) : (
          <Form
            onSubmit={showVerificationCode ? handleVerify : handleSubmit}
            noValidate
          >
            <Header>Login</Header>
            <SmallSubHeader>
              <div>
                Trizon has removed Google Login. If you signed up via your
                Google Account, please press Reset Password to set up a password
              </div>
            </SmallSubHeader>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onFocus={() => setError("")}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onFocus={() => setError("")}
              required
            />
            {showVerificationCode ? (
              <div>
                <Input
                  type="text"
                  placeholder="Verification code"
                  value={verificationCode}
                  onChange={(event) => setVerificationCode(event.target.value)}
                  required
                />
                {isCodeResent && <p>Verification code has been resent.</p>}
                <Button type="submit">Verify</Button>
                <Button onClick={handleResendCode}>Resend Code</Button>
              </div>
            ) : (
              <>
                <Button type="submit">Login</Button>
                <ButtonContainer>
                  <ButtonAlt onClick={handleResetPasswordClick}>
                    Reset Password
                  </ButtonAlt>
                  <ButtonAlt onClick={handleSignUpClick}>Register</ButtonAlt>
                </ButtonContainer>
              </>
            )}
            {/* <CenteredStyles>
              <div>---</div>
            </CenteredStyles>
            <CenteredStyles>
              <div id="signinDiv"></div>
            </CenteredStyles> */}
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export { Login };
