import { StrictMode } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Register } from "./features/login/components/Register";
import { Login } from "./features/login/components/Login";
import App from "./features/app/components/App";
import Subscribe from "./features/subscription/components/Subscribe";
import UsdDash from "./features/dashboard/components/UsdDashboard";
import EthDash from "./features/dashboard/components/EthDashboard";
import BtcDash from "./features/dashboard/components/BtcDashboard";
import OrderBookSpotOne from "./features/order-book/components/SpotOneDashboard";
import OrderBookSpotTwo from "./features/order-book/components/SpotTwoDashboard";
import OrderBookSpotFive from "./features/order-book/components/SpotFiveDashboard";
import OrderBookSpotTen from "./features/order-book/components/SpotTenDashboard";
import OrderBookPerpsOne from "./features/order-book/components/PerpsOneDashboard";
import OrderBookPerpsTwo from "./features/order-book/components/PerpsTwoDashboard";
import OrderBookPerpsFive from "./features/order-book/components/PerpsFiveDashboard";
import OrderBookPerpsTen from "./features/order-book/components/PerpsTenDashboard";
import OrderBookHistoryGraphs from "./features/order-book/components/OrderBookDashboard";
import Navbar from "./features/navbar/components/Navbar";
import TvlPage from "./features/tvl/components/TvlDashboard";

import OpenInterestGraphsDaily from "./features/open-interest/components/OiGraphsDailyUsd";
import OpenInterestGraphsCoin from "./features/open-interest/components/OiGraphsDailyCoin";
import OiSummaryPage from  "./features/open-interest/components/OiSummaryTable";

import TopTraderPage from "./features/top-traders/components/TopTradersDashboard";
import TopTraderDPage from "./features/top-traders-d/components/TopTradersDashboard";
import WarTerminalPage from "./features/top-traders-d/components/WarTerminalDashboard";

import MaxSizePage from "./features/leverage-tracker/components/MaxSize";

import LayerEvaluationEth from "./features/layer-evaluation/components/LayerDashboardEth";
import LayerEvaluationMatic from "./features/layer-evaluation/components/LayerDashboardMatic";
import LayerEvaluationOp from "./features/layer-evaluation/components/LayerDashboardOp";
import LayerEvaluationArb from "./features/layer-evaluation/components/LayerDashboardArb";
import LayerEvaluationFtm from "./features/layer-evaluation/components/LayerDashboardFtm";
import LayerEvaluationAvax from "./features/layer-evaluation/components/LayerDashboardAvax";
import LayerEvaluationBsc from "./features/layer-evaluation/components/LayerDashboardBsc";

import CexDashboard from "./features/cex/components/CexDashboard";

import {BinanceOrderBook} from "./features/order-book-tracker/components/OrderBook";

import { TokenFetch } from "./features/token-history/components/TokenFetch";

import WalletInput from "./features/wallet-tracker/components/WalletInput";
import "./styles.css";
import "@fontsource/roboto-mono";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Account } from "./features/login/components/Account";
import { Edit } from "./features/login/components/Edit";
import { googleAnalyticsId } from "./features/app/constants/ga";
import ReactGA from "react-ga4";
ReactGA.initialize(googleAnalyticsId);

const theme = createTheme({
  typography: {
    fontSize: 12,
    fontFamily: ["Arial", "Arial", "Arial", "Arial", "Arial"].join(","),
  },
});
const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <Account>
         
          <div id="root" style={{ height: "100%" }}>
          <Navbar />
            <Routes>
              <Route exact path="/" element={<TopTraderPage />} />
              <Route path="/usd" element={<UsdDash />} />
              <Route path="/eth" element={<EthDash />} />
              <Route path="/btc" element={<BtcDash />} />
              <Route
                path="/orderbook/spot/one"
                element={<OrderBookSpotOne />}
              />
              <Route
                path="/orderbook/spot/two"
                element={<OrderBookSpotTwo />}
              />
              <Route
                path="/orderbook/spot/five"
                element={<OrderBookSpotFive />}
              />
              <Route
                path="/orderbook/spot/ten"
                element={<OrderBookSpotTen />}
              />
              <Route
                path="/orderbook/perps/one"
                element={<OrderBookPerpsOne />}
              />
              <Route
                path="/orderbook/perps/two"
                element={<OrderBookPerpsTwo />}
              />
              <Route
                path="/orderbook/perps/five"
                element={<OrderBookPerpsFive />}
              />
              <Route
                path="/orderbook/perps/ten"
                element={<OrderBookPerpsTen />}
              />
              <Route
                path="/orderbook/graphs"
                element={<OrderBookHistoryGraphs />}
              />
              <Route path="/oi/usd" element={<OpenInterestGraphsDaily />} />
              <Route path="/oi/coin" element={<OpenInterestGraphsCoin />} />
              <Route path="/oi/summary" element={<OiSummaryPage />} />

              <Route path="/binancetoptraders" element={<TopTraderDPage />} />
              <Route path="/traders" element={<TopTraderPage />} />
              <Route path="/leveragetracker" element={<MaxSizePage />} />
              <Route path="/warterminal" element={<WarTerminalPage />} />
              <Route path="/tvl" element={<TvlPage />} />

              <Route path="/layers/eth" element={<LayerEvaluationEth />} />
              <Route path="/layers/matic" element={<LayerEvaluationMatic />} />
              <Route path="/layers/op" element={<LayerEvaluationOp />} />
              <Route path="/layers/arb" element={<LayerEvaluationArb />} />
              <Route path="/layers/ftm" element={<LayerEvaluationFtm />} />
              <Route path="/layers/avax" element={<LayerEvaluationAvax />} />
              <Route path="/layers/bsc" element={<LayerEvaluationBsc />} />
              <Route path="/cex" element={<CexDashboard />} />

              <Route path="/alerts/add" element={<WalletInput />} />

              <Route path="/tokenhistory" element={<TokenFetch />} />

              <Route path="/ob" element={<BinanceOrderBook />} />

              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/edit" element={<Edit />} />
              <Route path="/subscribe" element={<Subscribe />} />
            </Routes>
          </div>
        </Account>
      </ThemeProvider>
    </Router>
  </StrictMode>,
  rootElement
);
