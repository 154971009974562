import React, { useState, useContext, useEffect } from "react";
import { Pool, confirmRegistration } from "../../../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { AccountContext } from "./Account";
import {
  FormContainer,
  Header,
  Form,
  Input,
  Button,
  ButtonAlt,
  ErrorMessage,
  ButtonContainer,
  CenteredStyles,
  SubHeader,
} from "./Login.styles";
import jwt_decode from "jwt-decode";
import Footer from "../../footer/components/Footer";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [discord, setDiscord] = useState("");
  const [telegram, setTelegram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [referral, setReferral] = useState("");
  const [role, setRole] = useState("");

  const {
    authenticate,
    forgotPassword: forgotPasswordContext,
    resetPassword: resetPasswordContext,
  } = useContext(AccountContext);

  useEffect(() => {
    const loadGoogleSignInScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    const initializeGoogleSignIn = async () => {
      try {
        await loadGoogleSignInScript();
        google.accounts.id.initialize({
          client_id:
            "1085210070592-gsf7g40u1tnvthvquo2lafi8nkd9650m.apps.googleusercontent.com",
          callback: handleRegisterGoogle,
          ux_mode: "popup",
        });
        google.accounts.id.renderButton(document.getElementById("signinDiv"), {
          theme: "STANDARD", // Change the theme to 'filled'
          size: "large", // Change the size to 'medium'
          text: "signup_with", // Change the button text
          width: 100, // Change the button width (in pixels)
          type: "standard", // Open as a pop-up
          shape: "square",
        });
      } catch (error) {}
    };

    initializeGoogleSignIn();
  }, []);

  const handleRegisterGoogle = async (response) => {
    try {
      const googleToken = response.credential;
      const userObject = jwt_decode(googleToken);
      const email = userObject.email; // Get the user's email from the Google response
      const sub = userObject.sub; // Get the user's email from the Google response
      const now = new Date();
      const unixTimestamp = Math.floor(now.getTime() / 1000);

      const attributes = [
        { Name: "updated_at", Value: unixTimestamp.toString() },
        { Name: "custom:discord", Value: "" },
        { Name: "custom:role", Value: "basic" },
        { Name: "custom:telegram", Value: "" },
        { Name: "custom:twitter", Value: "" },
        { Name: "custom:referral", Value: "" },
      ];

      Pool.signUp(email, sub, attributes, null, (signupErr, signupResult) => {
        if (signupErr) {
          // An error occurred during the sign-up process
          console.log("Error signing up user:", signupErr);
          return;
        }
        // Sign-up successful, handle verification code if necessary
        console.log("User signed up successfully:", signupResult);
        authenticate(email, sub)
          .then((data) => {
            // window.location.href = "/";
          })
          .catch((err) => {
            setError(
              err.message.includes("Missing required parameter USERNAME")
                ? "Error: Missing Email"
                : err.code === "PasswordResetRequiredException"
                ? "Please reset your password"
                : err.message
            );
            if (err.code === "UserNotConfirmedException") {
              setShowVerificationCode(true);
            } else if (err.code === "PasswordResetRequiredException") {
              setResetPassword(true);
            } else if (err.code === "UserNotFoundException") {
              setForgotPassword(true);
            }
          });
      });
    } catch (error) {
      console.log("Error handling Google Sign-In callback:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const now = new Date();
    const unixTimestamp = Math.floor(now.getTime() / 1000);

    if (password.length < 8) {
      setError("Error: Password is too short");
      return;
    }

    console.log(referral)

    const attributes = [
      { Name: "updated_at", Value: unixTimestamp.toString() },
      { Name: "custom:discord", Value: discord },
      { Name: "custom:role", Value: "basic" },
      { Name: "custom:telegram", Value: telegram },
      { Name: "custom:twitter", Value: twitter },
      { Name: "custom:referral", Value: referral },
    ];

    Pool.signUp(email, password, attributes, null, (err, data) => {
      if (err) {
        let errorMessage = "";
        if (err.code === "CodeDeliveryFailureException") {
          errorMessage = "Email address is not verified.";
        } else {
          let messageArray = err.message.split(": ");
          if (messageArray.length > 1) {
            errorMessage = messageArray[1];
          } else {
            errorMessage = messageArray[0];
          }
        }
        setError(errorMessage);
      } else {
        window.location.href = "/";
        // setShowVerificationCode(true);
      }
    });
  };

  const handleVerify = async (event) => {
    event.preventDefault();

    try {
      await confirmRegistration(email, verificationCode);

      window.location.href = "/";
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <FormContainer>
        <Form
          onSubmit={showVerificationCode ? handleVerify : handleSubmit}
          noValidate
        >
          <Header>Register</Header>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onFocus={() => setError("")}
          />
          <Input
            type="password"
            placeholder="Password"
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onFocus={() => setError("")}
          />
          <Input
            type="referral"
            placeholder="Referral Code"
            label="Referral"
            value={referral}
            onChange={(event) => setReferral(event.target.value)}
            onFocus={() => setError("")}
          />
          {showVerificationCode && (
            <Input
              type="text"
              placeholder="Verification code"
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.target.value)}
            />
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Button type="submit">
            {showVerificationCode ? "Verify" : "Register"}
          </Button>

          <CenteredStyles>
            <ButtonAlt onClick={() => (window.location.href = "/login")}>
              Already have an account?
            </ButtonAlt>
          </CenteredStyles>
          {/* <ButtonContainer> */}
          {/* <CenteredStyles>
            <div>---</div>
          </CenteredStyles>
          <CenteredStyles>
            <div id="signinDiv"></div>
          </CenteredStyles> */}
          {/* </ButtonContainer> */}
        </Form>
      </FormContainer>
      <Footer />
    </>
  );
};

export { Register };
