import React, { useState, useContext, useEffect } from "react";
import { Pool } from "../../../UserPool";
import AWS from "../../../AwsConfig";
import {
  Form,
  Input,
  InputWallet,
  Button,
  Row,
  RowContainer,
  RowContainerAlt,
  Label,
  LabelInputContainer,
  ErrorMessage,
  InputButton,
  AddressRow,
  AddressContainer,
  AddressText,
  DeleteButton,
  RowButtonTopRight,
  RowButtonBottomRight,
  RowButtonContainer,
  Header,
  InnerHeader,
  InnerHeaderContainer,
  FormContainerNonLogin,
  RowWrapper,
  RowLabel,
  CheckboxContainer,
  LabelContainer,
  AddAlertButton,
} from "../../constants/components/Form.styles";
import {
  ButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  GridUniversalStyle,
  TextContainer,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  GridContainer,
} from "../../constants/components/Universal.styles";
import { AccountContext } from "../../login/components/Account";
import { FaTimes, FaSave } from "react-icons/fa";
import { RiDeleteBin7Fill } from "react-icons/ri";
import Grid from "@material-ui/core/Grid";

function getWalletAddress(string) {
  const startIdx = string.indexOf("[");
  const endIdx = string.indexOf("]");
  if (startIdx === -1 || endIdx === -1 || endIdx <= startIdx) {
    return "";
  }
  return string.substring(startIdx + 1, endIdx);
}

const WalletInput = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState([
    {
      id: 1,
      walletName: "",
      walletLabel: [],
      addresses: [],
      discordChannel: "",
      valueFilter: "",
      valueFilterError: "",
      addressError: "",
      error: "",
      includeApproval: false,
    },
  ]);
  const [loadedAddresses, setLoadedAddresses] = useState({});
  const [loadedLabels, setLoadedLabels] = useState({});
  const [rowCounter, setRowCounter] = useState(1);
  const [addressError, setAddressError] = useState("");
  const [selectedAlertName, setSelectedAlertName] = useState(null);


  const { role } = useContext(AccountContext);

  useEffect(() => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setIsLoading(false);
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              setIsLoading(false);
            } else {
              const attributeMap = {};
              attributes.forEach((attribute) => {
                attributeMap[attribute.Name] = attribute.Value;
              });
              setEmail(attributeMap.email);
              setIsLoading(false);
            }
          });
        }
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const fetchExistingCSV = () => {
    const s3 = new AWS.S3();
    const bucketName = "trizon-bucket";
    const key = `email/${email}.csv`;

    const params = {
      Bucket: bucketName,
      Key: key,
    };

    s3.getObject(params, (err, data) => {
      if (err) {
      } else {
        const fileContent = data.Body.toString();
        const [header, ...rows] = fileContent.split("\n");
        setRowCounter(rows.length);

        const loadedData = rows.map((row, index) => {
          const csvData = row;
          const pattern = /\[.*?\]/g;
          const arrays = csvData.match(pattern);
          const firstArray = arrays[0];
          const secondArray = arrays[1];
          const thirdArray = arrays[2];
          const alertDetailsArray = getWalletAddress(firstArray).split(";");
          const alertAddressesArray = getWalletAddress(secondArray).split(";");
          const alertLabelsArray = getWalletAddress(thirdArray).split(";");
          const walletName = alertDetailsArray[0];
          const discordChannel = alertDetailsArray[1];
          const valueFilter = alertDetailsArray[2];
          const inclApproval = alertDetailsArray[3];
          const includeApproval = inclApproval === "true";
          const addresses = alertAddressesArray;
          const walletLabel = alertLabelsArray;

          return {
            id: index + 1,
            walletName,
            addresses,
            discordChannel,
            valueFilter,
            walletLabel,
            valueFilterError: "",
            addressError: "",
            error: "",
            includeApproval,
          };
        });

        setFormData(loadedData);
        setLoadedAddresses(
          loadedData.reduce((acc, curr) => {
            acc[curr.id] = curr.addresses;
            return acc;
          }, {})
        );
        setLoadedLabels(
          loadedData.reduce((acc, curr) => {
            acc[curr.id] = curr.walletLabel;
            return acc;
          }, {})
        );
      }
    });
  };

  useEffect(() => {
    if (email) {
      fetchExistingCSV();
    }
  }, [email]);

  const handleFileUpload = async (file) => {
    try {
      const s3 = new AWS.S3();
      const bucketName = "trizon-bucket";
      const key = `email/${email}.csv`;

      const params = {
        Bucket: bucketName,
        Key: key,
        Body: file,
        ACL: "authenticated-read",
      };

      await s3.upload(params).promise();
    } catch (error) {}
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const hasEmptyAddress = formData.some((row) => row.addresses.length === 0);

    if (hasEmptyAddress) {
      setAddressError("All alerts must have at least one address.");
      return;
    }

    const csvRows = formData.map((row) => {
      const loadedAddress = loadedAddresses[row.id] || [];
      const loadedLabel = loadedLabels[row.id] || [];
      const addressesString =
        loadedAddress.length > 0 ? loadedAddress.join(";") : "";
      const labelString = loadedLabel.length > 0 ? loadedLabel.join(";") : "";
      const includeApproval = row.includeApproval ? "true" : "false";
      const alertDetails = [
        row.walletName,
        row.discordChannel,
        row.valueFilter,
        includeApproval,
      ].join(";");
      return `${"[" + alertDetails + "]"},${"[" + addressesString + "]"},${
        "[" + labelString + "]"
      }`;
    });
    const csvContent = `alert_details,address,wallet_label\n${csvRows.join(
      "\n"
    )}`;
    const csvFile = new Blob([csvContent], { type: "text/csv" });
    console.log(csvRows[1]);

    try {
      await handleFileUpload(csvFile); // Wait for handleFileUpload to complete
      const webhookPromises = formData.map((row) => {
        const webhookUrl = row.discordChannel;
        const alertName = row.walletName;
        const message = `Alert "${alertName}" was updated`;
        const payload = {
          username: "Trizon Notification",
          content: message,
        };
        return fetch(webhookUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      });
      await Promise.all(webhookPromises);

      window.location.reload();
    } catch (error) {}
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    if (name === "valueFilter") {
      if (isNaN(value)) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            valueFilter: value,
            valueFilterError: "Value must be a number",
          };
          return updatedFormData;
        });
      } else {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            valueFilter: value,
            valueFilterError: "",
          };
          return updatedFormData;
        });
      }
    } else if (name === "address") {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        const addresses = [...updatedFormData[index].addresses];
        addresses[0] = { value: value.trim() };
        updatedFormData[index] = {
          ...updatedFormData[index],
          addresses,
          addressError: "",
        };
        return updatedFormData;
      });
    } else if (name === "walletLabel") {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        const labels = [...updatedFormData[index].walletLabel];
        labels[0] = { value: value };
        updatedFormData[index] = {
          ...updatedFormData[index],
          walletLabel: labels,
        };
        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [name]: value,
          error: "",
        };
        return updatedFormData;
      });
    }
  };

  const addAddress = (index) => {
    const address = formData[index].addresses[0]?.value;
    const label = formData[index].walletLabel[0]?.value;

    if (address) {
      const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/;
      if (!ethereumAddressRegex.test(address)) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            addressError: "Invalid address format",
          };
          return updatedFormData;
        });
        return;
      }
    }

    setLoadedAddresses((prevLoadedAddresses) => {
      const updatedLoadedAddresses = { ...prevLoadedAddresses };
      updatedLoadedAddresses[formData[index].id] = [
        ...(updatedLoadedAddresses[formData[index].id] || []),
        address || "",
      ];
      return updatedLoadedAddresses;
    });

    setLoadedLabels((prevLoadedLabels) => {
      const updatedLoadedLabels = { ...prevLoadedLabels };
      updatedLoadedLabels[formData[index].id] = [
        ...(updatedLoadedLabels[formData[index].id] || []),
        label || "",
      ];
      return updatedLoadedLabels;
    });

    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index].addresses = [
        { value: "" },
        ...updatedFormData[index].addresses,
      ];
      updatedFormData[index].walletLabel = [
        { value: "" },
        ...updatedFormData[index].walletLabel,
      ];
      return updatedFormData;
    });
  };

  const deleteAddress = (index, addressIndex) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      const updatedAddresses = [...updatedFormData[index].addresses];
      const updatedLabels = [...updatedFormData[index].walletLabel];

      updatedAddresses.splice(addressIndex, 1);
      updatedLabels.splice(addressIndex, 1);

      updatedFormData[index] = {
        ...updatedFormData[index],
        addresses: updatedAddresses,
        walletLabel: updatedLabels,
      };

      return updatedFormData;
    });

    setLoadedAddresses((prevLoadedAddresses) => {
      const updatedLoadedAddresses = { ...prevLoadedAddresses };
      const rowId = formData[index].id;
      updatedLoadedAddresses[rowId] = updatedLoadedAddresses[rowId].filter(
        (_, idx) => idx !== addressIndex
      );
      return updatedLoadedAddresses;
    });

    setLoadedLabels((prevLoadedLabels) => {
      const updatedLoadedLabels = { ...prevLoadedLabels };
      const rowId = formData[index].id;
      updatedLoadedLabels[rowId] = updatedLoadedLabels[rowId].filter(
        (_, idx) => idx !== addressIndex
      );
      return updatedLoadedLabels;
    });
  };

  const addRow = () => {
    const newRowId = formData.length + 1;
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        id: newRowId,
        walletName: "",
        walletLabel: [],
        addresses: [],
        discordChannel: "",
        valueFilter: "",
        valueFilterError: "",
        addressError: "",
        error: "",
      },
    ]);
    setLoadedAddresses((prevLoadedAddresses) => {
      const updatedLoadedAddresses = { ...prevLoadedAddresses };
      updatedLoadedAddresses[newRowId] = [];
      return updatedLoadedAddresses;
    });
    setLoadedLabels((prevLoadedLabels) => {
      const updatedLoadedLabels = { ...prevLoadedLabels };
      delete updatedLoadedLabels[newRowId];
      return updatedLoadedLabels;
    });
    setRowCounter((prevCounter) => prevCounter + 1);
  };

  const deleteRow = (index) => {
    const rowId = formData[index].id;

    setFormData((prevFormData) => {
      const updatedFormData = prevFormData.filter((_, i) => i !== index);
      for (let i = index; i < updatedFormData.length; i++) {
        updatedFormData[i].id = i + 1;
      }
      return updatedFormData;
    });

    setLoadedAddresses((prevLoadedAddresses) => {
      const updatedLoadedAddresses = { ...prevLoadedAddresses };
      delete updatedLoadedAddresses[rowId];
      return Object.keys(updatedLoadedAddresses).reduce((acc, key) => {
        const updatedKey = parseInt(key, 10);
        if (updatedKey > index) {
          acc[updatedKey - 1] = updatedLoadedAddresses[updatedKey];
        } else {
          acc[updatedKey] = updatedLoadedAddresses[updatedKey];
        }
        return acc;
      }, {});
    });

    setLoadedLabels((prevLoadedLabels) => {
      const updatedLoadedLabels = { ...prevLoadedLabels };
      delete updatedLoadedLabels[rowId];
      return Object.keys(updatedLoadedLabels).reduce((acc, key) => {
        const updatedKey = parseInt(key, 10);
        if (updatedKey > index) {
          acc[updatedKey - 1] = updatedLoadedLabels[updatedKey];
        } else {
          acc[updatedKey] = updatedLoadedLabels[updatedKey];
        }
        return acc;
      }, {});
    });

    setRowCounter((prevCounter) => prevCounter - 1);
  };


  return (
    <>
      <ButtonContainer></ButtonContainer>
      <FormContainerNonLogin>
        {addressError && <ErrorMessage>{addressError}</ErrorMessage>}
        <Form onSubmit={handleSubmit}>
          {formData.map((row, index) => (
            <RowWrapper key={row.id}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Row key={row.id} isGray={index % 2 !== 0}>
                  <InnerHeaderContainer>
                    <InnerHeader rowNumber={row.id}>
                      Alert #{row.id}
                    </InnerHeader>
                  </InnerHeaderContainer>
                  <RowContainer>
                    <LabelInputContainer>
                      <Label>Name of Alert</Label>
                      <Input
                        type="text"
                        placeholder="Name of Alert"
                        name="walletName"
                        value={row.walletName}
                        onChange={(event) => handleInputChange(index, event)}
                        required
                      />
                    </LabelInputContainer>
                    <LabelInputContainer>
                      <Label>
                        Discord Webhook URL -{" "}
                        <a
                          href="https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Find out how
                        </a>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Discord Webhook URL"
                        name="discordChannel"
                        value={row.discordChannel}
                        onChange={(event) => handleInputChange(index, event)}
                        required
                      />
                    </LabelInputContainer>

                    <LabelInputContainer>
                      <Label>USD Value Filter</Label>
                      <Input
                        type="text"
                        placeholder="USD Value Filter"
                        name="valueFilter"
                        value={row.valueFilter}
                        onChange={(event) => handleInputChange(index, event)}
                        required
                      />
                      {row.valueFilterError && (
                        <ErrorMessage>{row.valueFilterError}</ErrorMessage>
                      )}
                    </LabelInputContainer>
                  </RowContainer>
                  <RowContainer>
                    <RowLabel>
                      <LabelContainer>
                        <Label>Include Wallet Approvals</Label>
                      </LabelContainer>
                      <CheckboxContainer>
                        <input
                          type="checkbox"
                          checked={row.includeApproval}
                          onChange={() => handleIncludeApprovalChange(index)}
                        />
                      </CheckboxContainer>
                    </RowLabel>
                  </RowContainer>
                  <InnerHeaderContainer>
                    <InnerHeader>Add Wallets Address to alert:</InnerHeader>
                  </InnerHeaderContainer>
                  <RowContainerAlt>
                    <LabelInputContainer>
                      <InputWallet
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={row.addresses[0]?.value || ""}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                      {row.addressError && (
                        <ErrorMessage>{row.addressError}</ErrorMessage>
                      )}
                    </LabelInputContainer>
                    <LabelInputContainer>
                      <InputWallet
                        type="text"
                        placeholder="Wallet Label"
                        name="walletLabel"
                        value={row.walletLabel[0]?.value || ""}
                        onChange={(event) => handleInputChange(index, event)}
                      />
                    </LabelInputContainer>
                    <LabelInputContainer>
                      {/* <Label>Add to Alert</Label> */}
                      <InputButton
                        type="button"
                        onClick={() => addAddress(index)}
                        disabled={row.addresses.length >= 10}
                        style={
                          row.addresses.length >= 10 ? { opacity: 0.5 } : {}
                        }
                      >
                        {row.addresses.length >= 10
                          ? "Max 10 Address Per Alert"
                          : "Add Wallet to Alert"}
                      </InputButton>
                    </LabelInputContainer>
                  </RowContainerAlt>

                  <InnerHeaderContainer>
                    {/* <InnerHeader>List of Added Addresses:</InnerHeader> */}
                  </InnerHeaderContainer>

                  {loadedAddresses[row.id]?.length > 0 &&
                    loadedAddresses[row.id][0] !== "" && (
                      <AddressRow>
                        {loadedAddresses[row.id].map(
                          (address, addressIndex) =>
                            address !== "" && (
                              <AddressContainer
                                key={`${row.id}-${addressIndex}`}
                              >
                                <DeleteButton
                                  type="button"
                                  onClick={() =>
                                    deleteAddress(index, addressIndex)
                                  }
                                >
                                  <FaTimes />
                                </DeleteButton>
                                <AddressText>
                                  {address}
                                  {loadedLabels[row.id]?.length > 0 &&
                                    loadedLabels[row.id] &&
                                    loadedLabels[row.id][addressIndex] && (
                                      <span>
                                        {" "}
                                        [{loadedLabels[row.id][addressIndex]}]
                                      </span>
                                    )}
                                </AddressText>
                              </AddressContainer>
                            )
                        )}
                      </AddressRow>
                    )}

                  <InnerHeaderContainer>
                    {/* <InnerHeader>Add Wallets Address to alert:</InnerHeader> */}
                  </InnerHeaderContainer>

                  <RowButtonContainer>
                    <RowButtonBottomRight type="submit">
                      <FaSave /> Save Alert
                    </RowButtonBottomRight>

                    <RowButtonTopRight
                      type="button"
                      onClick={() => deleteRow(index)}
                    >
                      <RiDeleteBin7Fill /> Delete Alert
                    </RowButtonTopRight>
                  </RowButtonContainer>
                </Row>
              </div>
            </RowWrapper>
          ))}

          {addressError && <ErrorMessage>{addressError}</ErrorMessage>}

          <RowButtonContainer>
            {role === "basic" && rowCounter >= 5 ? null : (
              <AddAlertButton type="button" onClick={addRow}>
                Add Alert
              </AddAlertButton>
            )}
          </RowButtonContainer>
        </Form>

        <Grid container wrap="nowrap" style={GridContainer}>
          <Grid item xs={12}>
            <GridUniversalStyle>
              <TextContainer>
                <a
                  href="https://forms.gle/4Njv5TSAN4EPGYvx7"
                  target="_blank"
                  rel="noAvaxener noreferrer"
                >
                  <SmallSubHeader>
                    Click here to help us with feedback or suggestions
                  </SmallSubHeader>
                </a>
              </TextContainer>
            </GridUniversalStyle>
          </Grid>
        </Grid>
      </FormContainerNonLogin>
    </>
  );
};

export default WalletInput;
