import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

export const LightTheme = {
  background: "#fff",
  axis: {
    ticks: { text: { fontSize: 11 } },
    legend: { text: { fontSize: 11 } },
  },
  grid: { line: { stroke: "#000000", strokeWidth: 0.1 } },
  legends: { text: { fontSize: 11 } },
};

export const TextTheme = styled.div`
  axis {
    ticks {
      text {
        font-size: 10px;
      }
    }
    legend {
      text {
        font-size: 10px;
      }
    }
  }
  grid {
    line {
      stroke: #000000;
      stroke-width: 0.1;
    }
  }
  legends {
    text {
      font-size: 11px;
    }
  }
`;

export const GraphTitle = styled.div`
  align: left;
  padding-left: 1rem;
  padding-top: 1rem;
  font-weight: 500;
  font-size: 14px;
`;

export const GraphSubTitle = styled.div`
  align: left;
  padding-left: 1rem;
  font-size: 12px;
`;

export const NoticeTitle = styled.div`
  padding-top: 1rem;
  font-weight: 500;
`;

export const NoticeSubTitle = styled.div`
  padding-top: 1rem;
  font-size:14px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const PageHeader = styled.h1`
  position: relative;
  display: inline-block; /* Add this line to make the highlight span only the width of the text */
  font-size: 1rem;
  margin-top: 5%;
  color: #4c4554;
  font-weight: bold;
  background: #f3f1f5;
  margin-left: 7%;
  margin-right: 7%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: purple; /* Replace with your desired highlight color */
    z-index: 2;
    opacity: 0.1;
  }
`;

export const GridUniversalStyle = styled.div`

    background-color: #fff;
    height: auto;
    // padding-bottom: 1%;
    border-top: 1px solid black;
    ${(props) =>
      props.hasBorderRight
        ? "border-right: 1px solid black;"
        : ""}
    box-sizing: initial;

    &:first-child {
      border-right: ${(props) =>
        props.hasBorderRight ? "1px solid black" : "none"};
    }
  `;



export const GridContainer = {
  // paddingBottom: "2%",
  // paddingRight: "2%",
  // paddingLeft: "2%",
  // gap: "2%",
  height: "auto",
};

export const ButtonBox = styled.button`
  background-color: #fff;
  height: auto;
  padding: 10px;
  cursor: pointer;

  border-width: 0;
  border-top: 1px solid black;
  border-right: 1px solid black;

  &:hover {
    color: #0000ff;
  }
`;

export const ButtonBoxRight = styled.button`
  background-color: #fff;
  height: auto;
  padding: 10px;
  cursor: pointer;

  border-width: 0;
  border-top: 1px solid black;
  border-left: 1px solid black; 
  border-left: 1px solid black; 
  float: right;

  &:hover {
    color: #0000ff;
  }
`;


export const ButtonContainer = styled.div`
  padding-top: 0;
  height: auto;
  margin-top: 2%;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
`;

export const TextContainer = styled.div`
  padding-top: 0;
  height: auto;
  margin: 1%;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
  font-size: 12px;
`;

export const SubButtonContainer = styled.div`
  margin: 0 0.5% 0.5% 0;
  display: inline-block;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
`;

export const Card = styled.div`
  padding-bottom: 2rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.7rem;
  background: #fff;
`;

export const DataTableStyles = {
  dataTableUniversal: {
    fontColor: "black",
    fontSize: 12,
    paddingLeft: "0.6rem",
    paddingRight: "0.6rem",
    // backgroundColor: "000",
  },
  dataTableSettings: {
    size: "small",
    responsiveLayout: "scroll",
    scrollHeight: "600px",
  },
};
export const rowStyle = (data) => {
  return { "row-primary": true };
};

export const globalHeaderStyle = {
  fontWeight: 500,
  backgroundColor: "#fff",
  fontSize: 10,
};

export const globalHeaderGroupStyle = {
  fontWeight: 500,
  backgroundColor: "#fff",
  color: "#9071b0",
  fontSize: 10,
};

export const globalHeaderGroupStyleWithDivider = {
  fontWeight: 500,
  backgroundColor: "#fff",
  color: "#9071b0",
  fontSize: 10,
  borderRight: "1px solid #000",
};

export const globalHeaderStyleWithDivider = {
  fontWeight: 500,
  backgroundColor: "#fff",
  fontSize: 10,
  borderRight: "1px solid #000",
};

export const dividerStyle = {
  borderRight: "1px solid #000",
};

export const firstColor = "#0000ff";
export const secondColor = "black";

export const ContentNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: #fff; /* Use a semicolon here instead of a comma */
  // height: 100%; /* Ensure the container takes up the full height */
  text-align: center; /* This centers the text horizontally */
`;


export const LoginButton = styled.button`
  padding: 0.5rem;
  border: none;
  background-color: #0000ff;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const LoginButtonAlt = styled.button`
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  color: #0000ff;
  cursor: pointer;
  border: 1px solid #0000ff;
  margin-left: 0.5rem;

  &:hover {
    background-color: #0000ff;
    color: #fff;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const NoticeButtonContainer = styled.div`
  padding-top: 0;
  height: auto;
  margin-top: 1rem;
  padding-bottom: 1%;
  z-index: 2;
  position: sticky;
  position: -webkit-sticky;
`;

export const PageButtonBlack = styled.button`
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.color ||
    "#cfcfff"}; /* Use the passed color or fallback to a default color */
  padding: 0.2rem;
  color: #fff;

  &:hover {
    background-color: #cfcfcf;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  color: #000;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  float: right;
  background-color: transparent;
`;

export const SmallSubHeader = styled.h1`
  font-size: 0.8rem;
  margin-bottom: 2rem;
  // text-align: center;
  color: #4c4554;
  font-weight: normal; /* add this line to reduce boldness */
`;

export const PlaceHolder = styled.div`
  padding-left: 2%;
`;