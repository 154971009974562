import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import {
  DataTableStyles,
  globalHeaderStyle,
  globalHeaderStyleWithDivider,
  dividerStyle,
} from "../../constants/components/Universal.styles";

import { useTopTraderData } from "../hooks/useTopTraderData";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";

const handleUserNameClick = (rowData) => {
  const userUrl =
    "https://www.binance.com/en/futures-activity/leaderboard/user?encryptedUid=" +
    rowData.user_id;
  window.open(userUrl, "_blank");
};

function convertSecondsToTime(seconds) {
  var days = Math.floor(seconds / 86400);
  var hours = Math.floor((seconds % 86400) / 3600);
  var minutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var seconds = ((seconds % 86400) % 3600) % 60;
  if (days < 10) {
    days = "0" + days;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + "m " + hours + "h " + days + "d ";
  
}

const headerTemplate = (data) => {
  const userUrl =
    "https://www.binance.com/en/futures-activity/leaderboard/user?encryptedUid=" +
    data.user_id;

  const handleUserClick = () => {
    window.open(userUrl, "_blank");
  };

  return (
    <React.Fragment>
      <span className={"light-gray-highlight"}>
        <a
          href={userUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="link-no-style"
          onClick={(e) => e.stopPropagation()}
        >
          {data.rank + " " + data.user_name}
        </a>
      </span>
      <span> </span>
      <span
        className={
          data.monthlyPnl >= 0 ? "positive-highlight" : "negative-highlight"
        }
      >
        {"Month PnL: " +
          data.monthlyPnl.toLocaleString("en", {
            maximumFractionDigits: 0,
          })}
        {""}
      </span>
      <span
        className={
          data.monthlyRoi >= 0 ? "positive-highlight" : "negative-highlight"
        }
      >
        {"Month RoI: " +
          data.monthlyRoi.toLocaleString("en", {
            style: "percent",
            maximumFractionDigits: 0,
          })}
        {""}
      </span>
      <span
        className={
          data.totalRoi >= 0 ? "positive-highlight" : "negative-highlight"
        }
      >
        {"Total RoI: " +
          data.totalRoi.toLocaleString("en", {
            style: "percent",
            maximumFractionDigits: 0,
          })}
      </span>
    </React.Fragment>
  );
};

const longRatioBodyTemplate = (rowData) => {
  return rowData.long_ratio.toLocaleString("en", {
    style: "percent",
    maximumFractionDigits: 0,
  });
};

const shortRatioBodyTemplate = (rowData) => {
  return rowData.short_ratio.toLocaleString("en", {
    style: "percent",
    maximumFractionDigits: 0,
  });
};

const entryPriceBodyTemplate = (rowData) => {
  return rowData.entryPrice.toLocaleString("en", { maximumFractionDigits: 3 });
};

const pnlBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData.pnl > 0,
    "low-percentage": rowData.pnl < 0,
  });

  return (
    <div className={stockClassName}>
      {rowData.pnl.toLocaleString("en", { maximumFractionDigits: 0 })}
    </div>
  );
};

const netAmtBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-amt": rowData.usd_value > 0,
    "low-amt": rowData.usd_value < 0,
  });

  return (
    <div className={stockClassName}>
      {rowData.usd_value.toLocaleString("en", { maximumFractionDigits: 0 })}
    </div>
  );
};

const monthlyPnlBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-amt": rowData.monthlyPnl > 0,
    "low-amt": rowData.monthlyPnl < 0,
  });

  return (
    <div className={stockClassName}>
      {rowData.monthlyPnl.toLocaleString("en", { maximumFractionDigits: 0 })}
    </div>
  );
};

const monthlyRoiBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-amt": rowData.monthlyRoi > 0,
    "low-amt": rowData.monthlyRoi < 0,
  });
  return (
    <div className={stockClassName}>
      {rowData.monthlyRoi.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const amtChangePctBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-amt": rowData.amt_chg_pct > 0,
    "low-amt": rowData.amt_chg_pct < 0,
  });

  return (
    <div className={stockClassName}>
      {rowData.amt_chg_pct.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const sideBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "sign-long": rowData.side == "LONG",
    "sign-short": rowData.side == "SHORT",
  });

  return <span className={stockClassName}>{rowData.side}</span>;
};

const TradeSideBodyTemplate = (rowData) => {
  const stockClassNameFirstWord = classNames({
    "positive-highlight-open": rowData.status == "LONG / OPEN",
    "positive-highlight-increase": rowData.status == "LONG / INCREASE",
    "positive-highlight-reduce": rowData.status == "LONG / REDUCE",
    "positive-highlight-close": rowData.status == "LONG / CLOSE",
    "negative-highlight-reduce": rowData.status == "SHORT / REDUCE",
    "negative-highlight-increase": rowData.status == "SHORT / INCREASE",
    "negative-highlight-open": rowData.status == "SHORT / OPEN",
    "negative-highlight-close": rowData.status == "SHORT / CLOSE",
  });
  const statusWords = rowData.status.split(" / ");
  const firstWord = statusWords[0];
  const secondWord = statusWords[1];

  const stockClassNameSecondWord = classNames({
    "direction-positive-highlight-increase": secondWord == "INCREASE",
    "direction-negative-highlight-reduce": secondWord == "REDUCE",
    "direction-negative-highlight-close": secondWord == "CLOSE",
    "direction-positive-highlight-open": secondWord == "OPEN",
  });

  return (
    <div>
      <span className={stockClassNameFirstWord}>{firstWord}</span>
      <span className={stockClassNameSecondWord}>{secondWord}</span>
    </div>
  );
};

const valueBodyTemplate = (rowData) => {
  return rowData.value.toLocaleString("en", { maximumFractionDigits: 0 });
};

const tradeValueBodyTemplate = (rowData) => {
  return rowData.trade_value.toLocaleString("en", { maximumFractionDigits: 0 });
};

const timeDiffBodyTemplate = (rowData) => {
  return convertSecondsToTime(rowData.time_diff);
};

const rowClass = (data) => {
  return {
    "trade-short": data.side === "SHORT",
    "trade-long": data.side === "LONG",
  };
};

export const TopTraderSummaryTable = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[1]["toptradersummary"][0]["data"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      value={results} // Updated variable name
      sortField="total_traders"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      stripedRows
    >
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="usd_value"
        header="Value"
        headerStyle={globalHeaderStyle}
        body={netAmtBodyTemplate}
        sortable
        className="net-amount-column"
      />
      <Column
        field="traders_long"
        header="# Long"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="traders_short"
        header="# Short"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="long_ratio"
        header="% Long"
        headerStyle={globalHeaderStyle}
        body={longRatioBodyTemplate}
        sortable
      />
      <Column
        field="short_ratio"
        header="% Short"
        headerStyle={globalHeaderStyle}
        body={shortRatioBodyTemplate}
        sortable
      />
      <Column
        field="total_traders"
        header="Total Traders"
        headerStyle={globalHeaderStyle}
        sortable
      />
    </DataTable>
  );
};

export const TopTraderPositionTable = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const top_trader_positions_data =
        data[0]["toptraderpositions"][0]["data"];
      setResult(top_trader_positions_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      rowGroupMode="subheader"
      groupRowsBy="user_name"
      rowGroupHeaderTemplate={headerTemplate}
      style={DataTableStyles.dataTableUniversal}
      rowClassName={rowClass}
      value={results}
      sortField="change_1h"
      sortOrder={-1}
      {...DataTableStyles.dataTableSettings}
      resizableColumns
      stripedRows
    >
      <Column
        field="rank"
        header="Rank"
        headerStyle={globalHeaderStyle}
        style={{ maxWidth: "4%" }}
        sortable
      />
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="side"
        header="Side"
        headerStyle={globalHeaderStyle}
        body={sideBodyTemplate}
        sortable
      />
      <Column
        field="value"
        header="Value"
        headerStyle={globalHeaderStyle}
        body={valueBodyTemplate}
        sortable
      />
      {/* <Column field="amount" header="amount" headerStyle={globalHeaderStyle} body={amtBodyTemplate} sortable/> */}
      <Column
        field="entryPrice"
        header="Entry"
        headerStyle={globalHeaderStyle}
        body={entryPriceBodyTemplate}
        sortable
      />
      <Column
        field="pnl"
        header="PnL"
        headerStyle={globalHeaderStyle}
        body={pnlBodyTemplate}
        sortable
      />
      <Column
        field="time_diff"
        header="Last Updated"
        headerStyle={globalHeaderStyle}
        body={timeDiffBodyTemplate}
        sortable
      />
    </DataTable>
  );
};

export const TopTraderRecentTradesSmall = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const top_trader_small_trades_data =
        data[2]["toptraderrecentsmall"][0]["data"];
      setResult(top_trader_small_trades_data);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      rowClassName={rowClass}
      value={results}
      sortField="change_1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="300px"
    >
      <Column
        field="rank"
        header="Rank"
        style={{ maxWidth: "4%" }}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="user_name"
        header="Name"
        style={{ minWidth: "12%" }}
        headerStyle={globalHeaderStyle}
        sortable
        body={(rowData) => (
          <a href="#" onClick={() => handleUserNameClick(rowData)}>
            {rowData.user_name}
          </a>
        )}
      />
      <Column
        field="monthlyPnl"
        header="Month PnL"
        body={monthlyPnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="monthlyRoi"
        header="Month RoI"
        body={monthlyRoiBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
      <Column
        field="status"
        header="Action"
        style={{ minWidth: "10%" }}
        body={TradeSideBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="trade_value"
        header="Trade Value"
        body={tradeValueBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="amt_chg_pct"
        header="Position Change"
        body={amtChangePctBodyTemplate}
        bodyStyle={dividerStyle}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
      />
      <Column
        field="value"
        header="Position Value"
        body={valueBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="side"
        header="Position Side"
        body={sideBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="new_position_entryPrice"
        header="Entry"
        body={entryPriceBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="new_position_pnl"
        header="PnL"
        body={pnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="time_diff"
        header="Updated"
        body={timeDiffBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
    </DataTable>
  );
};

export const TopTraderRecentTradesMedium = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const top_trader_medium_trades_data =
        data[3]["toptraderrecentmedium"][0]["data"];
      setResult(top_trader_medium_trades_data);
    }
  }, [isLoading, isError, getData]);

  // const [filters2, setFilters2] = useState({
  //   ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // });

  // const [globalFilterValue2, setGlobalFilterValue2] = useState("");

  // const onGlobalFilterChange2 = (e) => {
  //   const value = e.target.value;
  //   let _filters2 = { ...filters2 };
  //   _filters2["global"].value = value;

  //   setFilters2(_filters2);
  //   setGlobalFilterValue2(value);
  // };

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      rowClassName={rowClass}
      value={results}
      sortField="change_1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="300px"
    >
      <Column
        field="rank"
        header="Rank"
        style={{ maxWidth: "4%" }}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="user_name"
        header="Name"
        style={{ minWidth: "12%" }}
        headerStyle={globalHeaderStyle}
        sortable
        body={(rowData) => (
          <a href="#" onClick={() => handleUserNameClick(rowData)}>
            {rowData.user_name}
          </a>
        )}
      />
      <Column
        field="monthlyPnl"
        header="Month PnL"
        body={monthlyPnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="monthlyRoi"
        header="Month RoI"
        body={monthlyRoiBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
      <Column
        field="status"
        header="Action"
        style={{ minWidth: "10%" }}
        body={TradeSideBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="trade_value"
        header="Trade Value"
        body={tradeValueBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="amt_chg_pct"
        header="Position Change"
        body={amtChangePctBodyTemplate}
        bodyStyle={dividerStyle}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
      />
      <Column
        field="value"
        header="Position Value"
        body={valueBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="side"
        header="Position Side"
        body={sideBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="new_position_entryPrice"
        header="Entry"
        body={entryPriceBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="new_position_pnl"
        header="PnL"
        body={pnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="time_diff"
        header="Updated"
        body={timeDiffBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
    </DataTable>
  );
};

export const TopTraderRecentTradesLarge = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const top_trader_large_trades_data =
        data[4]["toptraderrecentlarge"][0]["data"];
      setResult(top_trader_large_trades_data);
    }
  }, [isLoading, isError, getData]);

  // const [filters2, setFilters2] = useState({
  //   ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // });

  // const [globalFilterValue2, setGlobalFilterValue2] = useState("");

  // const onGlobalFilterChange2 = (e) => {
  //   const value = e.target.value;
  //   let _filters2 = { ...filters2 };
  //   _filters2["global"].value = value;

  //   setFilters2(_filters2);
  //   setGlobalFilterValue2(value);
  // };

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      rowClassName={rowClass}
      value={results}
      sortField="change_1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="300px"
    >
      <Column
        field="rank"
        header="Rank"
        style={{ maxWidth: "4%" }}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="user_name"
        header="Name"
        style={{ minWidth: "12%" }}
        headerStyle={globalHeaderStyle}
        sortable
        body={(rowData) => (
          <a href="#" onClick={() => handleUserNameClick(rowData)}>
            {rowData.user_name}
          </a>
        )}
      />
      <Column
        field="monthlyPnl"
        header="Month PnL"
        body={monthlyPnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="monthlyRoi"
        header="Month RoI"
        body={monthlyRoiBodyTemplate}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
        bodyStyle={dividerStyle}
      />
      <Column
        field="status"
        header="Action"
        style={{ minWidth: "10%" }}
        body={TradeSideBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="trade_value"
        header="Trade Value"
        body={tradeValueBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="amt_chg_pct"
        header="Position Change"
        body={amtChangePctBodyTemplate}
        bodyStyle={dividerStyle}
        headerStyle={globalHeaderStyleWithDivider}
        sortable
      />
      <Column
        field="value"
        header="Position Value"
        body={valueBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="side"
        header="Position Side"
        body={sideBodyTemplate}
        headerStyle={globalHeaderStyle}
      />
      <Column
        field="new_position_entryPrice"
        header="Entry"
        body={entryPriceBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="new_position_pnl"
        header="PnL"
        body={pnlBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="time_diff"
        header="Updated"
        body={timeDiffBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
    </DataTable>
  );
};

export const TopTraderBiggest = () => {
  const { isLoading, isError, getData } = useTopTraderData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const biggest_positions_data = data[5]["toptraderbiggest"][0]["data"];
      setResult(biggest_positions_data);
    }
  }, [isLoading, isError, getData]);

  // const [filters2, setFilters2] = useState({
  //   ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  // });

  // const [globalFilterValue2, setGlobalFilterValue2] = useState("");

  // const onGlobalFilterChange2 = (e) => {
  //   const value = e.target.value;
  //   let _filters2 = { ...filters2 };
  //   _filters2["global"].value = value;

  //   setFilters2(_filters2);
  //   setGlobalFilterValue2(value);
  // };

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      rowClassName={rowClass}
      value={results}
      sortField="change_1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="300px"
    >
      <Column
        field="rank"
        header="Rank"
        headerStyle={globalHeaderStyle}
        style={{ maxWidth: "4%" }}
        sortable
      />
      <Column
        field="user_name"
        header="Name"
        style={{ minWidth: "12%" }}
        headerStyle={globalHeaderStyle}
        sortable
        body={(rowData) => (
          <a href="#" onClick={() => handleUserNameClick(rowData)}>
            {rowData.user_name}
          </a>
        )}
      />
      <Column
        field="monthlyPnl"
        header="Month PnL"
        headerStyle={globalHeaderStyle}
        body={monthlyPnlBodyTemplate}
        sortable
      />
      <Column
        field="monthlyRoi"
        header="Month RoI"
        headerStyle={globalHeaderStyle}
        body={monthlyRoiBodyTemplate}
        bodyStyle={dividerStyle}
        sortable
      />
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="side"
        header="Side"
        headerStyle={globalHeaderStyle}
        body={sideBodyTemplate}
        sortable
      />
      <Column
        field="value"
        header="Value"
        headerStyle={globalHeaderStyle}
        body={valueBodyTemplate}
        sortable
      />
      {/* <Column field="amount" header="amount" headerStyle={globalHeaderStyle} body={amtBodyTemplate} sortable/> */}
      <Column
        field="entryPrice"
        header="Entry"
        headerStyle={globalHeaderStyle}
        body={entryPriceBodyTemplate}
        sortable
      />
      <Column
        field="pnl"
        header="PnL"
        headerStyle={globalHeaderStyle}
        body={pnlBodyTemplate}
        sortable
      />
      {/* <Column field="value" header="Value" headerStyle={globalHeaderStyle} body={valueBodyTemplate} sortable /> */}
      <Column
        field="time_diff"
        header="Updated"
        headerStyle={globalHeaderStyle}
        body={timeDiffBodyTemplate}
        sortable
      />
    </DataTable>
  );
};
