import "../../../styles.css";
import React from "react";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";
import { DashboardBenchmark } from "./BtcDashboardContent";
import { DashboardLayers } from "./BtcDashboardContent";
import { DashboardDex } from "./BtcDashboardContent";
import { DashboardExchanges } from "./BtcDashboardContent";
import { DashboardLending } from "./BtcDashboardContent";
import { DashboardDerivatives } from "./BtcDashboardContent";
import { DashboardAssetMgmt } from "./BtcDashboardContent";
import { DashboardBridges } from "./BtcDashboardContent";
import { DashboardGamefi } from "./BtcDashboardContent";
import { DashboardNft } from "./BtcDashboardContent";
import { DashboardDao } from "./BtcDashboardContent";
import { DashboardMoveToEarn } from "./BtcDashboardContent";
import { DashboardMeme } from "./BtcDashboardContent";
import { DashboardRandom } from "./BtcDashboardContent";
import { DashboardPrivacy } from "./BtcDashboardContent";

import { DashboardBitcoin } from "./BtcDashboardContent";
import { DashboardRwa } from "./BtcDashboardContent";
import { DashboardNewLOne } from "./BtcDashboardContent";
import { DashboardCosmos } from "./BtcDashboardContent";
import { DashboardGameFiLayer } from "./BtcDashboardContent";

import Grid from "@material-ui/core/Grid";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  ButtonContainer,
  SubButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  ButtonBox,
  TextContainer,
} from "../../constants/components/Universal.styles";

export default function BtcDash() {
  return (
    <>
      <ButtonContainer>
        <StyledLink to="/usd">
          <ButtonBox>USD DENOMINATED</ButtonBox>
        </StyledLink>
        <StyledLink to="/eth">
          <ButtonBox>ETH DENOMINATED</ButtonBox>
        </StyledLink>

        {/* <StyledLink
          to="#benchmark"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>BENCHMARK</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#exchanges"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>EXCHANGES</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#layers"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>LAYERS</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#dex_amm"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>DEX/AMM/AGGREGATORS</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#bridges"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>BRIDGES</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#lending"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>LEND/BORROW</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#derivatives"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>DERIVATIVES</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#asset_mgmt"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>ASSET MGMT</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#gamefi"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>GAMEFI</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#dao"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>DAO</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#nft"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>NFT</ButtonBox>
        </StyledLink>
        <StyledLink
          to="#meme"
          smooth
          scroll={(el) => {
            el.scrollIntoView(true);
            window.scrollBy(0, -150);
          }}
        >
          <ButtonBox>MEME</ButtonBox>
        </StyledLink> */}
      </ButtonContainer>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Benchmark</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardBenchmark />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Exchanges</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardExchanges />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Layers</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardLayers />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>New L1</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardNewLOne />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Dex/Amm/Aggregators</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardDex />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Bitcoin</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardBitcoin />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Cosmos</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardCosmos />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Bridges</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardBridges />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Lend/Borrow</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardLending />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Derivatives</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardDerivatives />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Asset Management</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardAssetMgmt />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>RWA</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardRwa />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>GameFiLayer</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardGameFiLayer />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>GameFi</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardGamefi />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Dao</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardDao />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>NFT</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardNft />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Memes</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardMeme />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Privacy</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardPrivacy />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Random</GraphTitle>
            <GraphSubTitle>Btc</GraphSubTitle>
            <Card>
              <DashboardRandom />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
