import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useCoinData, useUsdData } from "../hooks/useOiSummaryData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import {
  DataTableStyles,
  globalHeaderStyle,
  globalHeaderStyleWithDivider,
  dividerStyle,
} from "../../constants/components/Universal.styles";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

const oneHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['1h'] > 0,
    "low-percentage": rowData['1h'] < 0,
  });

  const formattedValue = rowData['1h'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};

const twelveHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['12h'] > 0,
    "low-percentage": rowData['12h'] < 0,
  });

  const formattedValue = rowData['12h'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};

const oneDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['1d'] > 0,
    "low-percentage": rowData['1d'] < 0,
  });

  const formattedValue = rowData['1d'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};

const twoDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['2d'] > 0,
    "low-percentage": rowData['2d'] < 0,
  });

  const formattedValue = rowData['2d'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};

const threeDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['3d'] > 0,
    "low-percentage": rowData['3d'] < 0,
  });

  const formattedValue = rowData['3d'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};


const sevenDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['7d'] > 0,
    "low-percentage": rowData['7d'] < 0,
  });

  const formattedValue = rowData['7d'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};


const fourteenDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "high-percentage": rowData['14d'] > 0,
    "low-percentage": rowData['14d'] < 0,
  });

  const formattedValue = rowData['14d'].toLocaleString("en", { maximumFractionDigits: 1 });
  const displayValue = `${formattedValue}%`;

  return (
    <div className={stockClassName}>
      {displayValue}
    </div>
  );
};


export const OiSummaryCoinTable = () => {
  const { isLoading, isError, getData } = useCoinData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];

      // Convert "net" values to numbers
      const formattedData = summary_data.map((item) => ({
        ...item,
        total_deposit: parseFloat(item.total_deposit),
        total_withdraw: parseFloat(item.total_withdraw),
        net: parseFloat(item.net),
      }));

      // Set the state with formatted data
      setResult(formattedData);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      value={results}
      sortField="1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="600px"
    >
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="1h"
        header="1h"
        body={oneHourBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="12h"
        header="12h"
        body={twelveHourBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="1d"
        header="1d"
        body={oneDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="2d"
        header="2d"
        body={twoDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="3d"
        header="3d"
        body={threeDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="7d"
        header="7d"
        body={sevenDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="14d"
        header="14d"
        body={fourteenDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />

    </DataTable>
  );
};

export const OiSummaryUsdTable = () => {
  const { isLoading, isError, getData } = useUsdData();
  const [results, setResult] = useState([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];

      // Convert "net" values to numbers
      const formattedData = summary_data.map((item) => ({
        ...item,
        total_deposit: parseFloat(item.total_deposit),
        total_withdraw: parseFloat(item.total_withdraw),
        net: parseFloat(item.net),
      }));

      // Set the state with formatted data
      setResult(formattedData);
    }
  }, [isLoading, isError, getData]);

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      resizableColumns
      value={results}
      sortField="1h"
      sortOrder={-1}
      size="small"
      responsiveLayout="scroll"
      scrollable
      scrollHeight="600px"
    >
      <Column
        field="symbol"
        header="Symbol"
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="1h"
        header="1h"
        body={oneHourBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
      <Column
        field="12h"
        header="12h"
        body={twelveHourBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="1d"
        header="1d"
        body={oneDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="2d"
        header="2d"
        body={twoDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="3d"
        header="3d"
        body={threeDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="7d"
        header="7d"
        body={sevenDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />
            <Column
        field="14d"
        header="14d"
        body={fourteenDayBodyTemplate}
        headerStyle={globalHeaderStyle}
        sortable
      />

    </DataTable>
  );
};
