import "../../../styles.css";
import React from "react";
import { MaxSizeLeverageTable } from "./MaxSizeComp";
import Grid from "@material-ui/core/Grid";
import {
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  ButtonContainer,
  Card,
  SmallSubHeader,
  TextContainer,
} from "../../constants/components/Universal.styles";

export default function MaxSizePage() {
  return (
    <>
    <ButtonContainer></ButtonContainer>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
        <GridUniversalStyle>
        <GraphTitle>Binance Leverage Tracker</GraphTitle>
          <GraphSubTitle>Max Size at 20x Leverage</GraphSubTitle>
          <Card>
          <MaxSizeLeverageTable />
          </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
        <GridUniversalStyle>
          <TextContainer>
            <a
              href="https://forms.gle/4Njv5TSAN4EPGYvx7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SmallSubHeader>
                Click here to help us with feedback or suggestions
              </SmallSubHeader>
            </a>
          </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
