import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useLayerData } from "../hooks/useEthLayerData";
import {
  getLineChartOptions,
  getHistogramOptions,
  getCombinedOptions,
} from "../../shared/components/LineHistogram.js";
import { getSingleLine, getDoubleLine } from "../../shared/components/DoubleLine.js";
import { getSingleBar, generateBar } from "../../shared/components/Bar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const EthMarketCapActiveAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[0]["MarketCapActiveAddress"][0]];
      const graphTwoData = [data[0]["MarketCapActiveAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapActiveAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapActiveAddress"></div>
    </div>
  );
};

export const EthMarketCapUniqueAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[1]["MarketCapUniqueAddress"][0]];
      const graphTwoData = [data[1]["MarketCapUniqueAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapUniqueAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapUniqueAddress"></div>
    </div>
  );
};


export const EthMarketCapDailyTx = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[3]["MarketCapDailyTx"][0]];
      const graphTwoData = [data[3]["MarketCapDailyTx"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapDailyTx", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapDailyTx"></div>
    </div>
  );
};


export const EthMarketCapUniqueAddressGrowth = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[2]["MarketCapUniqueAddressGrowth"][0]];
      const graphTwoData = [data[2]["MarketCapUniqueAddressGrowth"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapUniqueAddressGrowth", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="EthMarketCapUniqueAddressGrowth"
      ></div>
    </div>
  );
};

export const EthMarketCapDailyNetFlow = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[4]["MarketCapNetFlow"][0]];
      const graphTwoData = [data[4]["MarketCapNetFlow"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapDailyNetFlow", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="EthMarketCapDailyNetFlow"
      ></div>
    </div>
  );
};

export const EthMarketCapRevenue = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapRevenue", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="EthMarketCapRevenue"
      ></div>
    </div>
  );
};

export const EthMarketCapTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[6]["MarketCapTvl"][0]];
      const graphTwoData = [data[6]["MarketCapTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapTvl"></div>
    </div>
  );
};


export const EthMarketCapStablecoinTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[7]["MarketCapStablecoinTvl"][0]];
      const graphTwoData = [data[7]["MarketCapStablecoinTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapStablecoinTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapStablecoinTvl"></div>
    </div>
  );
};


export const EthMarketCapFees = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[8]["MarketCapFees"][0]];
      const graphTwoData = [data[8]["MarketCapFees"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapFees", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="EthMarketCapFees"
      ></div>
    </div>
  );
};

export const EthMarketCapRollingPE = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][2]];
      const graphThreeData = [data[5]["MarketCapRevenue"][3]];

      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      const graphThreeId = graphThreeData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData),getSingleLine(graphThreeId, graphThreeData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("EthMarketCapRollingPE", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthMarketCapRollingPE"></div>
    </div>
  );
};

export const EthDevs = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][1]];
      const graphTwoData = [data[9]["MarketCapDev"][2]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("EthDevs", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthDevs"></div>
    </div>
  );
};


export const EthCommits = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][3]];
      const graphTwoData = [data[9]["MarketCapDev"][4]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("EthCommits", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="EthCommits"></div>
    </div>
  );
};