import React, { useState } from "react";
import { OiBenchmarkCoin } from "./OiBenchmarkComponent";
import { OiOtherCoin } from "./OiOtherComponent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";
import {
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  ButtonContainer,
  SubButtonContainer,
  SmallSubHeader,
  TextContainer,
  ButtonBox,
  StyledLink,
  ButtonBoxRight,
} from "../../constants/components/Universal.styles";

export default function OIGraphsCoin() {
  const [isTextVisible, setIsTextVisible] = useState(false); // State to control the visibility of the text

  const toggleTextVisibility = () => {
    setIsTextVisible((prevState) => !prevState);
  };

  return (
    <>
      <ButtonContainer>
        <StyledLink to="/oi/usd">
          <ButtonBox>USD DENOMINATED</ButtonBox>
        </StyledLink>
        <ButtonBoxRight onClick={toggleTextVisibility}>What is this page?</ButtonBoxRight>
      </ButtonContainer>

      {isTextVisible && (
        <Grid container wrap="nowrap" style={GridContainer}>
          <Grid item xs={12}>
          <GridUniversalStyle>
            <TextContainer>
              The graphs presented are a depiction of cumulative open interest (OI)
              changes for Binance perpetual futures contracts over a given time
              period. These charts are not denominated in USD, as is common with many
              financial graphs, but rather in the underlying cryptocurrency, or
              'coin'. Open interest represents the total number of outstanding futures
              contracts that have been traded but not yet liquidated by an offsetting
              trade or an exercise or assignment. In essence, it gives us a broad
              sense of the overall market activity and liquidity. By plotting
              cumulative OI, we're able to track the ebb and flow of positions over
              time, providing a useful indicator of the market's sentiment towards a
              particular coin.
              <br />
              <br />
              These charts are split into two main categories: 'majors' and 'alts'.
              <br />
              <br />
              The main purpose of these graphs is to identify significant changes in
              open interest, which could indicate shifts in trader positions. Large
              increases in OI may suggest new money coming into the market, signaling
              bullish sentiment if coupled with rising prices. Conversely, a
              significant drop in open interest could indicate that traders are
              closing their positions, which might be bearish if it happens while
              prices are falling. Tracking these changes in OI allows us to infer
              potential market trends and to anticipate significant movements.
              <br />
              <br />
              Overall, these graphs of cumulative open interest changes for Binance
              perpetuals provide valuable insight into market dynamics and can aid in
              making informed trading decisions. They offer a comprehensive view of
              how market sentiment and positions are evolving over time.
            </TextContainer>
            </GridUniversalStyle>
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
        <GridUniversalStyle>
          <GraphTitle>BENCHMARK</GraphTitle>
          <GraphSubTitle>Cumulative OI - Coin Denominated</GraphSubTitle>
          <OiBenchmarkCoin />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
        <GridUniversalStyle>
          <GraphTitle>OTHER</GraphTitle>
          <GraphSubTitle>Cumulative OI - Coin Denominated</GraphSubTitle>
          <OiOtherCoin />
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <TextContainer>
              <a
                href="https://forms.gle/4Njv5TSAN4EPGYvx7"
                target="_blank"
                rel="noAvaxener noreferrer"
              >
                <SmallSubHeader>
                  Click here to help us with feedback or suggestions
                </SmallSubHeader>
              </a>
            </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
