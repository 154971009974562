import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useLayerData } from "../hooks/useArbLayerData";
import {
  getLineChartOptions,
  getHistogramOptions,
  getCombinedOptions,
} from "../../shared/components/LineHistogram.js";
import { getSingleLine, getDoubleLine } from "../../shared/components/DoubleLine.js";
import { getSingleBar, generateBar } from "../../shared/components/Bar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const ArbMarketCapActiveAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[0]["MarketCapActiveAddress"][0]];
      const graphTwoData = [data[0]["MarketCapActiveAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapActiveAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapActiveAddress"></div>
    </div>
  );
};

export const ArbMarketCapUniqueAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[1]["MarketCapUniqueAddress"][0]];
      const graphTwoData = [data[1]["MarketCapUniqueAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapUniqueAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapUniqueAddress"></div>
    </div>
  );
};


export const ArbMarketCapDailyTx = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[3]["MarketCapDailyTx"][0]];
      const graphTwoData = [data[3]["MarketCapDailyTx"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapDailyTx", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapDailyTx"></div>
    </div>
  );
};


export const ArbMarketCapUniqueAddressGrowth = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[2]["MarketCapUniqueAddressGrowth"][0]];
      const graphTwoData = [data[2]["MarketCapUniqueAddressGrowth"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapUniqueAddressGrowth", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="ArbMarketCapUniqueAddressGrowth"
      ></div>
    </div>
  );
};

export const ArbMarketCapDailyNetFlow = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[4]["MarketCapNetFlow"][0]];
      const graphTwoData = [data[4]["MarketCapNetFlow"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapDailyNetFlow", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="ArbMarketCapDailyNetFlow"
      ></div>
    </div>
  );
};

export const ArbMarketCapRevenue = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapRevenue", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="ArbMarketCapRevenue"
      ></div>
    </div>
  );
};

export const ArbMarketCapTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[6]["MarketCapTvl"][0]];
      const graphTwoData = [data[6]["MarketCapTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapTvl"></div>
    </div>
  );
};


export const ArbMarketCapStablecoinTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[7]["MarketCapStablecoinTvl"][0]];
      const graphTwoData = [data[7]["MarketCapStablecoinTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapStablecoinTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapStablecoinTvl"></div>
    </div>
  );
};


export const ArbMarketCapFees = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[8]["MarketCapFees"][0]];
      const graphTwoData = [data[8]["MarketCapFees"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapFees", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="ArbMarketCapFees"
      ></div>
    </div>
  );
};

export const ArbMarketCapRollingPE = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][2]];
      const graphThreeData = [data[5]["MarketCapRevenue"][3]];

      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      const graphThreeId = graphThreeData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData),getSingleLine(graphThreeId, graphThreeData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("ArbMarketCapRollingPE", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbMarketCapRollingPE"></div>
    </div>
  );
};

export const ArbDevs = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][1]];
      const graphTwoData = [data[9]["MarketCapDev"][2]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("ArbDevs", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbDevs"></div>
    </div>
  );
};


export const ArbCommits = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][3]];
      const graphTwoData = [data[9]["MarketCapDev"][4]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("ArbCommits", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="ArbCommits"></div>
    </div>
  );
};