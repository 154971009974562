import { useState, useEffect } from "react";
import axios from "axios";

const api24Url = "https://api.forkeddigital.vip/cex24";
const api48Url = "https://api.forkeddigital.vip/cex48";
const api72Url = "https://api.forkeddigital.vip/cex72";

export const useDailyData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(api24Url);
        if (result.status === 200) {
          setData(result.data);
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getData = () => {
    return data;
  };


  return {
    isLoading,
    isError,
    getData,
  };
};

export const useTwoDayData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(api48Url);
        if (result.status === 200) {
          setData(result.data);
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getData = () => {
    return data;
  };


  return {
    isLoading,
    isError,
    getData,
  };
};

export const useThreeDayData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(api72Url);
        if (result.status === 200) {
          setData(result.data);
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getData = () => {
    return data;
  };


  return {
    isLoading,
    isError,
    getData,
  };
};
