import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";

const varPadding = 20;

const chain_tvl_url = "https://api.forkeddigital.vip/chaintvl";
const protocol_tvl_url = "https://api.forkeddigital.vip/protocoltvl";

const init = (initialState) => initialState;

const globalHeaderStyle = { fontWeight: 500, backgroundColor: "#ece4ff" };

const tvlBodyTemplate = (rowData) => {
  return rowData.tvl.toLocaleString("en", { maximumFractionDigits: 0 });
};

const changeOneDayChainBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_1d < -0.2,
    "mid-low-percentage": rowData.change_1d >= -0.2 && rowData.change_1d < 0,
    "mid-percentage": rowData.change_1d === 0,
    "mid-high-percentage": rowData.change_1d > 0 && rowData.change_1d < 0.2,
    "high-percentage": rowData.change_1d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_1d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const changeOneWeekChainBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_7d < -0.2,
    "mid-low-percentage": rowData.change_7d >= -0.2 && rowData.change_7d < 0,
    "mid-percentage": rowData.change_7d === 0,
    "mid-high-percentage": rowData.change_7d > 0 && rowData.change_7d < 0.2,
    "high-percentage": rowData.change_7d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_7d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const changeOneMonthChainBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_30d < -0.2,
    "mid-low-percentage": rowData.change_30d >= -0.2 && rowData.change_30d < 0,
    "mid-percentage": rowData.change_30d === 0,
    "mid-high-percentage": rowData.change_30d > 0 && rowData.change_30d < 0.2,
    "high-percentage": rowData.change_30d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_30d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const changeThreeMonthChainBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_90d < -0.2,
    "mid-low-percentage": rowData.change_90d >= -0.2 && rowData.change_90d < 0,
    "mid-percentage": rowData.change_90d === 0,
    "mid-high-percentage": rowData.change_90d > 0 && rowData.change_90d < 0.2,
    "high-percentage": rowData.change_90d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_90d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const priceBodyTemplate = (rowData) => {
  return rowData.current_price.toLocaleString("en", {
    maximumFractionDigits: 3,
  });
};

const fdvTvlBodyTemplate = (rowData) => {
  return rowData.fdv_tvl.toLocaleString("en", { maximumFractionDigits: 3 });
};

const mktcapTvlBodyTemplate = (rowData) => {
  return rowData.mcap_tvl.toLocaleString("en", { maximumFractionDigits: 3 });
};

const mcapBodyTemplate = (rowData) => {
  return (rowData.market_cap / 1000000).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

const fdvBodyTemplate = (rowData) => {
  return (rowData.fully_diluted_valuation / 1000000).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

const currentTvlProtocolBodyTemplate = (rowData) => {
  return rowData.current_tvl.toLocaleString("en", { maximumFractionDigits: 0 });
};

const tvlChgOneHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_1h < -0.2,
    "mid-low-percentage": rowData.change_1h >= -0.2 && rowData.change_1h < 0,
    "mid-percentage": rowData.change_1h === 0,
    "mid-high-percentage": rowData.change_1h > 0 && rowData.change_1h < 0.2,
    "high-percentage": rowData.change_1h >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_1h.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const tvlChgOneDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_1d < -0.2,
    "mid-low-percentage": rowData.change_1d >= -0.2 && rowData.change_1d < 0,
    "mid-percentage": rowData.change_1d === 0,
    "mid-high-percentage": rowData.change_1d > 0 && rowData.change_1d < 0.2,
    "high-percentage": rowData.change_1d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_1d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const tvlChgOneWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.change_7d < -0.2,
    "mid-low-percentage": rowData.change_7d >= -0.2 && rowData.change_7d < 0,
    "mid-percentage": rowData.change_7d === 0,
    "mid-high-percentage": rowData.change_7d > 0 && rowData.change_7d < 0.2,
    "high-percentage": rowData.change_7d >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.change_7d.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const priceChgOneHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_1h_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_1h_in_currency >= -0.2 &&
      rowData.price_change_percentage_1h_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_1h_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_1h_in_currency > 0 &&
      rowData.price_change_percentage_1h_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_1h_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_1h_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const priceChgOneDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_24h_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_24h_in_currency >= -0.2 &&
      rowData.price_change_percentage_24h_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_24h_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_24h_in_currency > 0 &&
      rowData.price_change_percentage_24h_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_24h_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_24h_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const priceChgOneWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.price_change_percentage_7d_in_currency < -0.2,
    "mid-low-percentage":
      rowData.price_change_percentage_7d_in_currency >= -0.2 &&
      rowData.price_change_percentage_7d_in_currency < 0,
    "mid-percentage": rowData.price_change_percentage_7d_in_currency === 0,
    "mid-high-percentage":
      rowData.price_change_percentage_7d_in_currency > 0 &&
      rowData.price_change_percentage_7d_in_currency < 0.2,
    "high-percentage": rowData.price_change_percentage_7d_in_currency >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.price_change_percentage_7d_in_currency.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 2,
      })}
    </div>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataLoaded":
      return { ...state, results: action.payload, loading: false };
    default:
      throw new Error();
  }
};

export const ChainTvlTable = () => {
  const initialState = {
    results: [],
    loading: true,
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(chain_tvl_url);
        if (result.status == 200) {
          setData(result);
          return result;
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData()
      // .then(res => console.log(res.data[0].data))
      .then((res) =>
        dispatch({ type: "dataLoaded", payload: res.data[0].data })
      );
    // dispatch({ type: "dataLoaded", payload: res.data[0].usd[0].stables });

    // if (loading) {
    //     dispatch({ type: "dataLoaded", payload: data.data[0].usd[0].stables });
    // }
  }, [loading]);

  const [filters2, setFilters2] = useState({
    ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue2, setGlobalFilterValue2] = useState("");
  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  };
  return (
    <Grid
      id="tvlChain"
      container
      wrap="nowrap"
      style={{
        paddingBottom: varPadding,
        paddingRight: varPadding,
        paddingLeft: varPadding,
        gap: varPadding,
      }}
    >
      <Grid item xs={12} style={{ height: "auto", border: "1px solid purple" }}>
        <Typography
          align="left"
          variant="subtitle1"
          style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
        >
          Chain TVL
        </Typography>
        <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
          Total Value Locked
        </Typography>
        <div
          className="card"
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}
        >
          <DataTable
            style={{ fontColor: "black", fontSize: 13 }}
            value={results}
            sortField="change_1d"
            sortOrder={-1}
            size="small"
            responsiveLayout="scroll"
            scrollable
            scrollHeight="600px"
          >
            <Column
              field="chain"
              header="chain"
              headerStyle={globalHeaderStyle}
              style={{ width: "18%" }}
              sortable
            />
            <Column
              field="tvl"
              header="current_tvl"
              headerStyle={globalHeaderStyle}
              body={tvlBodyTemplate}
              sortable
            />
            <Column
              field="change_1d"
              header="tvl_1d"
              headerStyle={globalHeaderStyle}
              body={changeOneDayChainBodyTemplate}
              sortable
            />
            <Column
              field="change_7d"
              header="tvl_7d"
              headerStyle={globalHeaderStyle}
              body={changeOneWeekChainBodyTemplate}
              sortable
            />
            <Column
              field="change_30d"
              header="tvl_30d"
              headerStyle={globalHeaderStyle}
              body={changeOneMonthChainBodyTemplate}
              sortable
            />
            <Column
              field="change_90d"
              header="tvl_90d"
              headerStyle={globalHeaderStyle}
              body={changeThreeMonthChainBodyTemplate}
              sortable
            />
            <Column
              field="market_cap"
              header="mcap (mm)"
              headerStyle={globalHeaderStyle}
              body={mcapBodyTemplate}
              sortable
            />
            <Column
              field="fully_diluted_valuation"
              header="fdv (mm)"
              headerStyle={globalHeaderStyle}
              body={fdvBodyTemplate}
              sortable
            />
            <Column
              field="fdv_tvl"
              header="fdv/tvl"
              headerStyle={globalHeaderStyle}
              body={fdvTvlBodyTemplate}
              sortable
            />
            <Column
              field="mcap_tvl"
              header="mcap/tvl"
              headerStyle={globalHeaderStyle}
              body={mktcapTvlBodyTemplate}
              sortable
            />
            <Column
              field="current_price"
              header="current_price"
              headerStyle={globalHeaderStyle}
              body={priceBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_1h_in_currency"
              header="price_1h"
              headerStyle={globalHeaderStyle}
              body={priceChgOneHourBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_24h_in_currency"
              header="price_1d"
              headerStyle={globalHeaderStyle}
              body={priceChgOneDayBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_7d_in_currency"
              header="price_7d"
              headerStyle={globalHeaderStyle}
              body={priceChgOneWeekBodyTemplate}
              sortable
            />
          </DataTable>
        </div>
      </Grid>
    </Grid>
  );
};

export const ProtocolTvlTable = () => {
  const initialState = {
    results: [],
    loading: true,
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios(protocol_tvl_url);
        if (result.status == 200) {
          setData(result);
          return result;
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData()
      // .then(res => console.log(res.data[0].data))
      .then((res) =>
        dispatch({ type: "dataLoaded", payload: res.data[0].data })
      );
    // dispatch({ type: "dataLoaded", payload: res.data[0].usd[0].stables });

    // if (loading) {
    //     dispatch({ type: "dataLoaded", payload: data.data[0].usd[0].stables });
    // }
  }, [loading]);

  const [filters2, setFilters2] = useState({
    ticker: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue2, setGlobalFilterValue2] = useState("");
  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  };
  return (
    <Grid
      id="protocolTvl"
      container
      wrap="nowrap"
      style={{
        paddingBottom: varPadding,
        paddingRight: varPadding,
        paddingLeft: varPadding,
        gap: varPadding,
      }}
    >
      <Grid item xs={12} style={{ height: "auto", border: "1px solid purple" }}>
        <Typography
          align="left"
          variant="subtitle1"
          style={{ paddingLeft: 10, paddingTop: 5, fontWeight: 500 }}
        >
          Protocol TVL
        </Typography>
        <Typography align="left" variant="body2" style={{ paddingLeft: 10 }}>
          Total Value Locked
        </Typography>
        <div
          className="card"
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}
        >
          <DataTable
            style={{ fontColor: "black", fontSize: 13 }}
            value={results}
            sortField="change_1h"
            sortOrder={-1}
            size="small"
            responsiveLayout="scroll"
            scrollable
            scrollHeight="600px"
          >
            <Column
              field="protocol_name"
              header="protocol_name"
              headerStyle={globalHeaderStyle}
              sortable
            />
            <Column
              field="chain"
              header="chain"
              headerStyle={globalHeaderStyle}
              sortable
            />
            <Column
              field="current_tvl"
              header="tvl"
              headerStyle={globalHeaderStyle}
              body={currentTvlProtocolBodyTemplate}
              sortable
            />
            <Column
              field="change_1h"
              header="tvl_1h"
              headerStyle={globalHeaderStyle}
              body={tvlChgOneHourBodyTemplate}
              sortable
            />
            <Column
              field="change_1d"
              header="tvl_1d"
              headerStyle={globalHeaderStyle}
              body={tvlChgOneDayBodyTemplate}
              sortable
            />
            <Column
              field="change_7d"
              header="tvl_7d"
              headerStyle={globalHeaderStyle}
              body={tvlChgOneWeekBodyTemplate}
              sortable
            />
            <Column
              field="market_cap"
              header="mcap (mm)"
              headerStyle={globalHeaderStyle}
              body={mcapBodyTemplate}
              sortable
            />
            <Column
              field="fully_diluted_valuation"
              header="fdv (mm)"
              headerStyle={globalHeaderStyle}
              body={fdvBodyTemplate}
              sortable
            />
            <Column
              field="fdv_tvl"
              header="fdv/tvl"
              headerStyle={globalHeaderStyle}
              body={fdvTvlBodyTemplate}
              sortable
            />
            <Column
              field="mcap_tvl"
              header="mcap/tvl"
              headerStyle={globalHeaderStyle}
              body={mktcapTvlBodyTemplate}
              sortable
            />
            <Column
              field="current_price"
              header="current_price"
              headerStyle={globalHeaderStyle}
              body={priceBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_1h_in_currency"
              header="price_1h"
              headerStyle={globalHeaderStyle}
              body={priceChgOneHourBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_24h_in_currency"
              header="price_1d"
              headerStyle={globalHeaderStyle}
              body={priceChgOneDayBodyTemplate}
              sortable
            />
            <Column
              field="price_change_percentage_7d_in_currency"
              header="price_7d"
              headerStyle={globalHeaderStyle}
              body={priceChgOneWeekBodyTemplate}
              sortable
            />
          </DataTable>
        </div>
      </Grid>
    </Grid>
  );
};
