import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  // max-width: 25rem;
  padding: 3%;
  padding-left: 40%;
  padding-right: 40%;
  background-color: #fff;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: 2%;
  // margin-right: 2%;
`;

export const Header = styled.h1`
  font-size: 1.1rem;
  margin-bottom: 2rem;
  // text-align: center;
  color: #4c4554;
  font-weight: 500; /* add this line to reduce boldness */
`;

export const SubHeader = styled.h1`
  font-size: 1rem;
  margin-bottom: 2rem;
  // text-align: center;
  color: #4c4554;
  font-weight: normal; /* add this line to reduce boldness */
`;

export const SmallSubHeader = styled.h1`
  font-size: 0.8rem;
  margin-bottom: 2rem;
  // text-align: center;
  color: #4c4554;
  font-weight: normal; /* add this line to reduce boldness */
`;


export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  &:read-only {
    background-color: #e1d9ea;
    pointer-events: none;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #0000ff;
  // border-radius: 1rem;
  background-color: #0000ff;
  color: #fff;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    background-color: #fff;
    border: 1px solid #0000ff;
    color: #0000ff;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const ButtonAlt = styled.button`
  width: 100%;
  padding: 0.5rem;
  border: none;
  // border-radius: 1rem;
  background-color: transparent;
  color: #0000ff;
  cursor: pointer;
  border: 0.5px solid #0000ff;
  

  &:hover {
    background-color: #0000ff;
    color: #fff;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const Link = styled.a`
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #ebe5f3;
  color: #0066cc;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  font-size: 12px; /* set the font size to 16px */

  &:hover {
    background-color: #cfcfcf;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;

  /* add gap or space between the two buttons */
  & ${Button}:first-child {
    margin-right: 1rem;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0.5rem;
  text-align: left;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 12px;
`;

export const CenteredStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 1rem;
`;
