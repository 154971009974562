import "../../../styles.css";
import React, { useState, useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import { HashLink as Link } from "react-router-hash-link";
import { SpotOrderBookOne } from "./SpotContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  SubButtonContainer,
  ButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  TextContainer,
  ButtonBox,
  ButtonBoxRight,
} from "../../constants/components/Universal.styles";

export default function OrderBookSpotOne() {
  const { role } = useContext(AccountContext);
  const [isTextVisible, setIsTextVisible] = useState(false); // State to control the visibility of the text

  const toggleTextVisibility = () => {
    setIsTextVisible((prevState) => !prevState);
  };

  if (role === "notloggedin") {
    return (
      <>
        <ButtonContainer></ButtonContainer>
        <ContentNotAvailable>
          <NoticeTitle>Content Not Available</NoticeTitle>
          <NoticeSubTitle>Login or Register for Access</NoticeSubTitle>
          <NoticeButtonContainer>
            <StyledLink to="/login">
              <LoginButton>Login</LoginButton>
            </StyledLink>
            <StyledLink to="/register">
              <LoginButtonAlt>Register</LoginButtonAlt>
            </StyledLink>
          </NoticeButtonContainer>
        </ContentNotAvailable>
      </>
    );
  }

  return (
    <>
      <ButtonContainer>
      <StyledLink to="/orderbook/spot/one">
          <ButtonBox>1% Depth</ButtonBox>
        </StyledLink>
        <StyledLink to="/orderbook/spot/two">
          <ButtonBox>2% Depth</ButtonBox>
        </StyledLink>
        <StyledLink to="/orderbook/spot/five">
          <ButtonBox>5% Depth</ButtonBox>
        </StyledLink>
        <StyledLink to="/orderbook/spot/ten">
          <ButtonBox>10% Depth</ButtonBox>
        </StyledLink>
        <ButtonBoxRight onClick={toggleTextVisibility}>
          What is this page?
        </ButtonBoxRight>
      </ButtonContainer>

      {isTextVisible && (
        <Grid container wrap="nowrap" style={GridContainer}>
          <Grid item xs={12}>
            <GridUniversalStyle>
              <TextContainer>
                The page you're viewing provides information on market
                liquidity, which is a measure of the ability to buy or sell a
                particular asset without causing a significant price movement.
                In this specific instance, the liquidity is represented in terms
                of Tether (USDT), and is calculated as 1% from the center of the
                bid/ask spread. The bid price is the highest price that a buyer
                is willing to pay for an asset, while the ask price is the
                lowest price a seller is willing to accept. The spread is the
                difference between these two prices. In this context, the 1%
                range around the midpoint of this spread is used as an indicator
                of immediate market liquidity.
                <br />
                <br />
                You can see the changes in market liquidity over different
                timeframes: 1 hour (1h), 1 day (1d), and 7 days (7d). This
                allows you to track short-term, medium-term, and longer-term
                liquidity trends. Fluctuations in these trends can give you
                insight into market conditions and the behavior of market
                participants during these periods.
                <br />
                <br />
                The data on this page is especially useful for observing how the
                order book is shifting and determining whether large bids or
                offers are being spoofed or gradually worked into the order
                books. 'Spoofing' is a manipulative trading activity where a
                trader places large orders with no intention of executing them
                to create an illusion of high demand or supply, thereby
                influencing other traders. On the other hand, 'worked' orders
                refer to large orders that are split into smaller parts and fed
                into the market over time to avoid causing drastic price
                movements. This page's data can therefore be invaluable in
                discerning the genuine liquidity situation from potential market
                manipulation attempts.
              </TextContainer>
            </GridUniversalStyle>
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Binance - 1% Spot</GraphTitle>
            <GraphSubTitle>Order Book</GraphSubTitle>
            <Card>
              <SpotOrderBookOne />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <TextContainer>
              <a
                href="https://forms.gle/4Njv5TSAN4EPGYvx7"
                target="_blank"
                rel="noAvaxener noreferrer"
              >
                <SmallSubHeader>
                  Click here to help us with feedback or suggestions
                </SmallSubHeader>
              </a>
            </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
