// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useOtherData } from "../hooks/useOtherData";
import { getLine, generateLineChart } from "../../shared/components/Line.js";

import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const OiOtherUsd = () => {
  const { isLoading, isError, getData } = useOtherData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data)
      const graphData = data[0].other_usd; // Get the benchmark data
      console.log(graphData)

      // Prepare an array to store lineChartOptions for each graph
      const lineChartOptionsArray = [];

      // Loop through the graphData and create lineChartOptions for each item
      graphData.forEach((item) => {
        const lineChart = getLine(item.id, [item]);
        lineChartOptionsArray.push(lineChart);
      });

      console.log(lineChartOptionsArray)

      const combinedOptions = generateLineChart(
        lineChartOptionsArray,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft,
        true // displayPercentage
      );

      Highcharts.chart("OiOtherUsd", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapperLarge">
      <div className="graphContainerLarge" id="OiOtherUsd"></div>
    </div>
  );
};


export const OiOtherCoin = () => {
  const { isLoading, isError, getData } = useOtherData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      console.log(data)
      const graphData = data[1].other_coin; // Get the benchmark data

      // Prepare an array to store lineChartOptions for each graph
      const lineChartOptionsArray = [];

      // Loop through the graphData and create lineChartOptions for each item
      graphData.forEach((item) => {
        const lineChart = getLine(item.id, [item]);
        lineChartOptionsArray.push(lineChart);
      });

      console.log(lineChartOptionsArray)

      const combinedOptions = generateLineChart(
        lineChartOptionsArray,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft,
        true // displayPercentage
      );

      Highcharts.chart("OiOtherCoin", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapperLarge">
      <div className="graphContainerLarge" id="OiOtherCoin"></div>
    </div>
  );
};
