import React, { useReducer, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import {
  DataTableStyles,
  globalHeaderStyle,
} from "../../constants/components/Universal.styles";

import { usePerpOrderBookData } from "../hooks/usePerpOrderBookData";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "../../../styles.css";

const varPadding = 20;

const perp_ob_url = "https://api.forkeddigital.vip/perporderbook";

const init = (initialState) => initialState;

const pricesBodyTemplate = (rowData) => {
  return rowData.price.toLocaleString("en", { maximumFractionDigits: 10 });
};

const bidOneBodyTemplate = (rowData) => {
  return rowData.bid_1.toLocaleString("en", { maximumFractionDigits: 0 });
};

const askOneBodyTemplate = (rowData) => {
  return rowData.ask_1.toLocaleString("en", { maximumFractionDigits: 0 });
};

const bidOneWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_1_week_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_1_week_diff >= -0.2 && rowData.bid_1_week_diff < 0,
    "mid-percentage": rowData.bid_1_week_diff === 0,
    "mid-high-percentage":
      rowData.bid_1_week_diff > 0 && rowData.bid_1_week_diff < 0.2,
    "high-percentage": rowData.bid_1_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_1_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidOneDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_1_day_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_1_day_diff >= -0.2 && rowData.bid_1_day_diff < 0,
    "mid-percentage": rowData.bid_1_day_diff === 0,
    "mid-high-percentage":
      rowData.bid_1_day_diff > 0 && rowData.bid_1_day_diff < 0.2,
    "high-percentage": rowData.bid_1_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_1_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidOneHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_1_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_1_hour_diff >= -0.2 && rowData.bid_1_hour_diff < 0,
    "mid-percentage": rowData.bid_1_hour_diff === 0,
    "mid-high-percentage":
      rowData.bid_1_hour_diff > 0 && rowData.bid_1_hour_diff < 0.2,
    "high-percentage": rowData.bid_1_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_1_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askOneWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_1_week_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_1_week_diff >= -0.2 && rowData.ask_1_week_diff < 0,
    "mid-percentage": rowData.ask_1_week_diff === 0,
    "mid-high-percentage":
      rowData.ask_1_week_diff > 0 && rowData.ask_1_week_diff < 0.2,
    "high-percentage": rowData.ask_1_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_1_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askOneDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_1_day_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_1_day_diff >= -0.2 && rowData.ask_1_day_diff < 0,
    "mid-percentage": rowData.ask_1_day_diff === 0,
    "mid-high-percentage":
      rowData.ask_1_day_diff > 0 && rowData.ask_1_day_diff < 0.2,
    "high-percentage": rowData.ask_1_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_1_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askOneHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_1_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_1_hour_diff >= -0.2 && rowData.ask_1_hour_diff < 0,
    "mid-percentage": rowData.ask_1_hour_diff === 0,
    "mid-high-percentage":
      rowData.ask_1_hour_diff > 0 && rowData.ask_1_hour_diff < 0.2,
    "high-percentage": rowData.ask_1_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_1_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTwoBodyTemplate = (rowData) => {
  return rowData.bid_2.toLocaleString("en", { maximumFractionDigits: 0 });
};

const askTwoBodyTemplate = (rowData) => {
  return rowData.ask_2.toLocaleString("en", { maximumFractionDigits: 0 });
};

const bidTwoWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_2_week_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_2_week_diff >= -0.2 && rowData.bid_2_week_diff < 0,
    "mid-percentage": rowData.bid_2_week_diff === 0,
    "mid-high-percentage":
      rowData.bid_2_week_diff > 0 && rowData.bid_2_week_diff < 0.2,
    "high-percentage": rowData.bid_2_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_2_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTwoDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_2_day_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_2_day_diff >= -0.2 && rowData.bid_2_day_diff < 0,
    "mid-percentage": rowData.bid_2_day_diff === 0,
    "mid-high-percentage":
      rowData.bid_2_day_diff > 0 && rowData.bid_2_day_diff < 0.2,
    "high-percentage": rowData.bid_2_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_2_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTwoHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_2_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_2_hour_diff >= -0.2 && rowData.bid_2_hour_diff < 0,
    "mid-percentage": rowData.bid_2_hour_diff === 0,
    "mid-high-percentage":
      rowData.bid_2_hour_diff > 0 && rowData.bid_2_hour_diff < 0.2,
    "high-percentage": rowData.bid_2_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_2_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTwoWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_2_week_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_2_week_diff >= -0.2 && rowData.ask_2_week_diff < 0,
    "mid-percentage": rowData.ask_2_week_diff === 0,
    "mid-high-percentage":
      rowData.ask_2_week_diff > 0 && rowData.ask_2_week_diff < 0.2,
    "high-percentage": rowData.ask_2_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_2_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTwoDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_2_day_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_2_day_diff >= -0.2 && rowData.ask_2_day_diff < 0,
    "mid-percentage": rowData.ask_2_day_diff === 0,
    "mid-high-percentage":
      rowData.ask_2_day_diff > 0 && rowData.ask_2_day_diff < 0.2,
    "high-percentage": rowData.ask_2_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_2_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTwoHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_2_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_2_hour_diff >= -0.2 && rowData.ask_2_hour_diff < 0,
    "mid-percentage": rowData.ask_2_hour_diff === 0,
    "mid-high-percentage":
      rowData.ask_2_hour_diff > 0 && rowData.ask_2_hour_diff < 0.2,
    "high-percentage": rowData.ask_2_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_2_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidFiveBodyTemplate = (rowData) => {
  return rowData.bid_5.toLocaleString("en", { maximumFractionDigits: 0 });
};

const askFiveBodyTemplate = (rowData) => {
  return rowData.ask_5.toLocaleString("en", { maximumFractionDigits: 0 });
};

const bidFiveWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_5_week_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_5_week_diff >= -0.2 && rowData.bid_5_week_diff < 0,
    "mid-percentage": rowData.bid_5_week_diff === 0,
    "mid-high-percentage":
      rowData.bid_5_week_diff > 0 && rowData.bid_5_week_diff < 0.2,
    "high-percentage": rowData.bid_5_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_5_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidFiveDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_5_day_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_5_day_diff >= -0.2 && rowData.bid_5_day_diff < 0,
    "mid-percentage": rowData.bid_5_day_diff === 0,
    "mid-high-percentage":
      rowData.bid_5_day_diff > 0 && rowData.bid_5_day_diff < 0.2,
    "high-percentage": rowData.bid_5_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_5_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidFiveHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_5_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_5_hour_diff >= -0.2 && rowData.bid_5_hour_diff < 0,
    "mid-percentage": rowData.bid_5_hour_diff === 0,
    "mid-high-percentage":
      rowData.bid_5_hour_diff > 0 && rowData.bid_5_hour_diff < 0.2,
    "high-percentage": rowData.bid_5_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_5_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askFiveWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_5_week_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_5_week_diff >= -0.2 && rowData.ask_5_week_diff < 0,
    "mid-percentage": rowData.ask_5_week_diff === 0,
    "mid-high-percentage":
      rowData.ask_5_week_diff > 0 && rowData.ask_5_week_diff < 0.2,
    "high-percentage": rowData.ask_5_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_5_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askFiveDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_5_day_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_5_day_diff >= -0.2 && rowData.ask_5_day_diff < 0,
    "mid-percentage": rowData.ask_5_day_diff === 0,
    "mid-high-percentage":
      rowData.ask_5_day_diff > 0 && rowData.ask_5_day_diff < 0.2,
    "high-percentage": rowData.ask_5_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_5_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askFiveHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_5_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_5_hour_diff >= -0.2 && rowData.ask_5_hour_diff < 0,
    "mid-percentage": rowData.ask_5_hour_diff === 0,
    "mid-high-percentage":
      rowData.ask_5_hour_diff > 0 && rowData.ask_5_hour_diff < 0.2,
    "high-percentage": rowData.ask_5_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_5_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTenBodyTemplate = (rowData) => {
  return rowData.bid_10.toLocaleString("en", { maximumFractionDigits: 0 });
};

const askTenBodyTemplate = (rowData) => {
  return rowData.ask_10.toLocaleString("en", { maximumFractionDigits: 0 });
};

const bidTenWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_10_week_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_10_week_diff >= -0.2 && rowData.bid_10_week_diff < 0,
    "mid-percentage": rowData.bid_10_week_diff === 0,
    "mid-high-percentage":
      rowData.bid_10_week_diff > 0 && rowData.bid_10_week_diff < 0.2,
    "high-percentage": rowData.bid_10_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_10_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTenDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_10_day_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_10_day_diff >= -0.2 && rowData.bid_10_day_diff < 0,
    "mid-percentage": rowData.bid_10_day_diff === 0,
    "mid-high-percentage":
      rowData.bid_10_day_diff > 0 && rowData.bid_10_day_diff < 0.2,
    "high-percentage": rowData.bid_10_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_10_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const bidTenHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.bid_10_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.bid_10_hour_diff >= -0.2 && rowData.bid_10_hour_diff < 0,
    "mid-percentage": rowData.bid_10_hour_diff === 0,
    "mid-high-percentage":
      rowData.bid_10_hour_diff > 0 && rowData.bid_10_hour_diff < 0.2,
    "high-percentage": rowData.bid_10_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.bid_10_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTenWeekBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_10_week_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_10_week_diff >= -0.2 && rowData.ask_10_week_diff < 0,
    "mid-percentage": rowData.ask_10_week_diff === 0,
    "mid-high-percentage":
      rowData.ask_10_week_diff > 0 && rowData.ask_10_week_diff < 0.2,
    "high-percentage": rowData.ask_10_week_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_10_week_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTenDayBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_10_day_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_10_day_diff >= -0.2 && rowData.ask_10_day_diff < 0,
    "mid-percentage": rowData.ask_10_day_diff === 0,
    "mid-high-percentage":
      rowData.ask_10_day_diff > 0 && rowData.ask_10_day_diff < 0.2,
    "high-percentage": rowData.ask_10_day_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_10_day_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const askTenHourBodyTemplate = (rowData) => {
  const stockClassName = classNames({
    "low-percentage": rowData.ask_10_hour_diff < -0.2,
    "mid-low-percentage":
      rowData.ask_10_hour_diff >= -0.2 && rowData.ask_10_hour_diff < 0,
    "mid-percentage": rowData.ask_10_hour_diff === 0,
    "mid-high-percentage":
      rowData.ask_10_hour_diff > 0 && rowData.ask_10_hour_diff < 0.2,
    "high-percentage": rowData.ask_10_hour_diff >= 0.2,
  });

  return (
    <div className={stockClassName}>
      {rowData.ask_10_hour_diff.toLocaleString("en", {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    </div>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "dataLoaded":
      return { ...state, results: action.payload, loading: false };
    default:
      throw new Error();
  }
};

export const PerpsOrderBookOne = () => {
  const { isLoading, isError, getData } = usePerpOrderBookData();
  const [results, setResult] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      {...DataTableStyles.dataTableSettings}
      stripedRows
      header={header1}
      filters={filters1}
      value={results}
      sortField="bid_1"
      sortOrder={-1}
      size="small"
    >
      <Column
        field="ticker"
        header="Ticker"
        headerStyle={globalHeaderStyle}
        style={{ width: "18%" }}
        filter
        sortable
      />
      <Column
        field="price"
        header="Price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        sortable
      />
      <Column
        field="bid_1_week_diff"
        header="Bid-7d"
        headerStyle={globalHeaderStyle}
        body={bidOneWeekBodyTemplate}
        sortable
      />
      <Column
        field="bid_1_day_diff"
        header="Bid-1d"
        headerStyle={globalHeaderStyle}
        body={bidOneDayBodyTemplate}
        sortable
      />
      <Column
        field="bid_1_hour_diff"
        header="Bid-1h"
        headerStyle={globalHeaderStyle}
        body={bidOneHourBodyTemplate}
        sortable
      />
      <Column
        field="bid_1"
        header="Bid"
        headerStyle={globalHeaderStyle}
        body={bidOneBodyTemplate}
        sortable
      />
      <Column
        field="ask_1"
        header="Ask"
        headerStyle={globalHeaderStyle}
        body={askOneBodyTemplate}
        sortable
      />
      <Column
        field="ask_1_hour_diff"
        header="Ask-1h"
        headerStyle={globalHeaderStyle}
        body={askOneHourBodyTemplate}
        sortable
      />
      <Column
        field="ask_1_day_diff"
        header="Ask-1d"
        headerStyle={globalHeaderStyle}
        body={askOneDayBodyTemplate}
        sortable
      />
      <Column
        field="ask_1_week_diff"
        header="Ask-7d"
        headerStyle={globalHeaderStyle}
        body={askOneWeekBodyTemplate}
        sortable
      />
    </DataTable>
  );
};

export const PerpsOrderBookTwo = () => {
  const { isLoading, isError, getData } = usePerpOrderBookData();
  const [results, setResult] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      {...DataTableStyles.dataTableSettings}
      stripedRows
      header={header1}
      filters={filters1}
      value={results}
      sortField="bid_2"
      sortOrder={-1}
      size="small"
    >
      <Column
        field="ticker"
        header="Ticker"
        headerStyle={globalHeaderStyle}
        style={{ width: "18%" }}
        filter
        sortable
      />
      <Column
        field="price"
        header="Price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        sortable
      />
      <Column
        field="bid_2_week_diff"
        header="Bid-7d"
        headerStyle={globalHeaderStyle}
        body={bidTwoWeekBodyTemplate}
        sortable
      />
      <Column
        field="bid_2_day_diff"
        header="Bid-1d"
        headerStyle={globalHeaderStyle}
        body={bidTwoDayBodyTemplate}
        sortable
      />
      <Column
        field="bid_2_hour_diff"
        header="Bid-1h"
        headerStyle={globalHeaderStyle}
        body={bidTwoHourBodyTemplate}
        sortable
      />
      <Column
        field="bid_2"
        header="Bid"
        headerStyle={globalHeaderStyle}
        body={bidTwoBodyTemplate}
        sortable
      />
      <Column
        field="ask_2"
        header="Ask"
        headerStyle={globalHeaderStyle}
        body={askTwoBodyTemplate}
        sortable
      />
      <Column
        field="ask_2_hour_diff"
        header="Ask-1h"
        headerStyle={globalHeaderStyle}
        body={askTwoHourBodyTemplate}
        sortable
      />
      <Column
        field="ask_2_day_diff"
        header="Ask-1d"
        headerStyle={globalHeaderStyle}
        body={askTwoDayBodyTemplate}
        sortable
      />
      <Column
        field="ask_2_week_diff"
        header="Ask-7d"
        headerStyle={globalHeaderStyle}
        body={askTwoWeekBodyTemplate}
        sortable
      />
    </DataTable>
  );
};

export const PerpsOrderBookFive = () => {
  const { isLoading, isError, getData } = usePerpOrderBookData();
  const [results, setResult] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];
      setResult(summary_data);
    }
  }, [isLoading, isError, getData]);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      {...DataTableStyles.dataTableSettings}
      stripedRows
      header={header1}
      filters={filters1}
      value={results}
      sortField="bid_5"
      sortOrder={-1}
      size="small"
    >
      <Column
        field="ticker"
        header="Ticker"
        headerStyle={globalHeaderStyle}
        style={{ width: "18%" }}
        filter
        sortable
      />
      <Column
        field="price"
        header="Price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        sortable
      />
      <Column
        field="bid_5_week_diff"
        header="Bid-7d"
        headerStyle={globalHeaderStyle}
        body={bidFiveWeekBodyTemplate}
        sortable
      />
      <Column
        field="bid_5_day_diff"
        header="Bid-1d"
        headerStyle={globalHeaderStyle}
        body={bidFiveDayBodyTemplate}
        sortable
      />
      <Column
        field="bid_5_hour_diff"
        header="Bid-1h"
        headerStyle={globalHeaderStyle}
        body={bidFiveHourBodyTemplate}
        sortable
      />
      <Column
        field="bid_5"
        header="Bid"
        headerStyle={globalHeaderStyle}
        body={bidFiveBodyTemplate}
        sortable
      />
      <Column
        field="ask_5"
        header="Ask"
        headerStyle={globalHeaderStyle}
        body={askFiveBodyTemplate}
        sortable
      />
      <Column
        field="ask_5_hour_diff"
        header="Ask-1h"
        headerStyle={globalHeaderStyle}
        body={askFiveHourBodyTemplate}
        sortable
      />
      <Column
        field="ask_5_day_diff"
        header="Ask-1d"
        headerStyle={globalHeaderStyle}
        body={askFiveDayBodyTemplate}
        sortable
      />
      <Column
        field="ask_5_week_diff"
        header="Ask-7d"
        headerStyle={globalHeaderStyle}
        body={askFiveWeekBodyTemplate}
        sortable
      />
    </DataTable>
  );
};

export const PerpsOrderBookTen = () => {
  const { isLoading, isError, getData } = usePerpOrderBookData();
  const [results, setResult] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const summary_data = data[0]["data"];
      setResult(summary_data);
      console.log(summary_data)
    }
  }, [isLoading, isError, getData]);

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <DataTable
      className="sticky-header-table"
      style={DataTableStyles.dataTableUniversal}
      {...DataTableStyles.dataTableSettings}
      stripedRows
      header={header1}
      filters={filters1}
      value={results}
      sortField="bid_10"
      sortOrder={-1}
      size="small"
    >
      <Column
        field="ticker"
        header="Ticker"
        headerStyle={globalHeaderStyle}
        style={{ width: "18%" }}
        filter
        sortable
      />
      <Column
        field="price"
        header="Price"
        headerStyle={globalHeaderStyle}
        body={pricesBodyTemplate}
        sortable
      />
      <Column
        field="bid_10_week_diff"
        header="Bid-7d"
        headerStyle={globalHeaderStyle}
        body={bidTenWeekBodyTemplate}
        sortable
      />
      <Column
        field="bid_10_day_diff"
        header="Bid-1d"
        headerStyle={globalHeaderStyle}
        body={bidTenDayBodyTemplate}
        sortable
      />
      <Column
        field="bid_10_hour_diff"
        header="Bid-1h"
        headerStyle={globalHeaderStyle}
        body={bidTenHourBodyTemplate}
        sortable
      />
      <Column
        field="bid_10"
        header="Bid"
        headerStyle={globalHeaderStyle}
        body={bidTenBodyTemplate}
        sortable
      />
      <Column
        field="ask_10"
        header="Ask"
        headerStyle={globalHeaderStyle}
        body={askTenBodyTemplate}
        sortable
      />
      <Column
        field="ask_10_hour_diff"
        header="Ask-1h"
        headerStyle={globalHeaderStyle}
        body={askTenHourBodyTemplate}
        sortable
      />
      <Column
        field="ask_10_day_diff"
        header="Ask-1d"
        headerStyle={globalHeaderStyle}
        body={askTenDayBodyTemplate}
        sortable
      />
      <Column
        field="ask_10_week_diff"
        header="Ask-7d"
        headerStyle={globalHeaderStyle}
        body={askTenWeekBodyTemplate}
        sortable
      />
    </DataTable>
  );
};
