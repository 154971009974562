import React, { useEffect } from "react";
import Highcharts from "highcharts";
import { useLayerData } from "../hooks/useFtmLayerData";
import {
  getLineChartOptions,
  getHistogramOptions,
  getCombinedOptions,
} from "../../shared/components/LineHistogram.js";
import { getSingleLine, getDoubleLine } from "../../shared/components/DoubleLine.js";
import { getSingleBar, generateBar } from "../../shared/components/Bar.js";
import "../../../styles.css";

const varMarginTop = 35;
const varMarginRight = 20;
const varMarginLeft = 20;
const varMarginBottom = 10;

export const FtmMarketCapActiveAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[0]["MarketCapActiveAddress"][0]];
      const graphTwoData = [data[0]["MarketCapActiveAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapActiveAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapActiveAddress"></div>
    </div>
  );
};

export const FtmMarketCapUniqueAddress = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[1]["MarketCapUniqueAddress"][0]];
      const graphTwoData = [data[1]["MarketCapUniqueAddress"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapUniqueAddress", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapUniqueAddress"></div>
    </div>
  );
};


export const FtmMarketCapDailyTx = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[3]["MarketCapDailyTx"][0]];
      const graphTwoData = [data[3]["MarketCapDailyTx"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapDailyTx", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapDailyTx"></div>
    </div>
  );
};


export const FtmMarketCapUniqueAddressGrowth = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[2]["MarketCapUniqueAddressGrowth"][0]];
      const graphTwoData = [data[2]["MarketCapUniqueAddressGrowth"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapUniqueAddressGrowth", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="FtmMarketCapUniqueAddressGrowth"
      ></div>
    </div>
  );
};

export const FtmMarketCapDailyNetFlow = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[4]["MarketCapNetFlow"][0]];
      const graphTwoData = [data[4]["MarketCapNetFlow"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapDailyNetFlow", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="FtmMarketCapDailyNetFlow"
      ></div>
    </div>
  );
};

export const FtmMarketCapRevenue = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapRevenue", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="FtmMarketCapRevenue"
      ></div>
    </div>
  );
};

export const FtmMarketCapTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[6]["MarketCapTvl"][0]];
      const graphTwoData = [data[6]["MarketCapTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapTvl"></div>
    </div>
  );
};


export const FtmMarketCapStablecoinTvl = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[7]["MarketCapStablecoinTvl"][0]];
      const graphTwoData = [data[7]["MarketCapStablecoinTvl"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapStablecoinTvl", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapStablecoinTvl"></div>
    </div>
  );
};


export const FtmMarketCapFees = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();
      const graphOneData = [data[8]["MarketCapFees"][0]];
      const graphTwoData = [data[8]["MarketCapFees"][1]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;



      const lineChartOptions = getLineChartOptions(graphOneId, graphOneData);
      const histogramOptions = getHistogramOptions(graphTwoId, graphTwoData);

      const combinedOptions = getCombinedOptions(
        lineChartOptions,
        histogramOptions,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapFees", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div
        className="graphContainer"
        id="FtmMarketCapFees"
      ></div>
    </div>
  );
};

export const FtmMarketCapRollingPE = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[5]["MarketCapRevenue"][0]];
      const graphTwoData = [data[5]["MarketCapRevenue"][2]];
      const graphThreeData = [data[5]["MarketCapRevenue"][3]];

      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      const graphThreeId = graphThreeData[0].id;

      const lineChartOptionsOne = getSingleLine(graphOneId, graphOneData);
      const lineChartOptionsTwo = [getSingleLine(graphTwoId, graphTwoData),getSingleLine(graphThreeId, graphThreeData)];

      const combinedOptions = getDoubleLine(
        lineChartOptionsOne,
        lineChartOptionsTwo,
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );

      Highcharts.chart("FtmMarketCapRollingPE", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmMarketCapRollingPE"></div>
    </div>
  );
};

export const FtmDevs = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][1]];
      const graphTwoData = [data[9]["MarketCapDev"][2]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("FtmDevs", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmDevs"></div>
    </div>
  );
};


export const FtmCommits = () => {
  const { isLoading, isError, getData } = useLayerData();

  useEffect(() => {
    if (!isLoading && !isError) {
      const data = getData();

      const graphOneData = [data[9]["MarketCapDev"][3]];
      const graphTwoData = [data[9]["MarketCapDev"][4]];
      const graphOneId = graphOneData[0].id;
      const graphTwoId = graphTwoData[0].id;
      
      const barChartOptionsOne = getSingleBar(graphOneId, graphOneData);
      const barChartOptionsTwo = [getSingleBar(graphTwoId, graphTwoData)];
      
      const combinedOptions = generateBar(
        [barChartOptionsOne, ...barChartOptionsTwo],
        varMarginTop,
        varMarginRight,
        varMarginBottom,
        varMarginLeft
      );
      

      Highcharts.chart("FtmCommits", combinedOptions);
    }
  }, [isLoading, isError, getData]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "12px",
        }}
      >
        loading...
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <div className="wrapper">
      <div className="graphContainer" id="FtmCommits"></div>
    </div>
  );
};