import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #fff, #e1d9ea);
`;

export const FormContainerNonLogin = styled.div`
  margin-top: 2%;
`;

export const Header = styled.h1`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #000;
  font-weight: normal;
  margin-top: 2%;
`;

export const InnerHeader = styled.h1`
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-top: 2%;
`;

export const LoadingText = styled.h1`
  font-size: 14px;
  color: #000;
  font-weight: 500;
  // margin-top: 2%;
  // margin-bottom: 2%;
  background #fff;
`;


export const Form = styled.form`
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  background-color: #fff;
  flex-wrap: wrap; /* Add this line to allow the elements to wrap */
  align-items: flex-start;
  margin-bottom: 2rem;
  position: relative;

  /* Set the CSS variables for the input width */
  --input-width: calc(
    100%
  ); /* Adjust the value based on the number of inputs per row */
`;

export const RowWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #000;
`;

export const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  flex: 1;
`;

export const Input = styled.input`
  padding: 0.5rem;
  padding-right: 1rem;
  flex: 1;
  width: var(--input-width); /* Use the CSS variable for width */
  &:read-only {
    background-color: #e1d9ea;
    pointer-events: none;
  }
  border: 1px solid #ccc;
`;
export const InputWallet = styled.input`
  padding: 0.5rem;
  flex: 2; /* Adjust the flex value as desired */
  background-color: #f0f0f0; /* Change the background color to the desired color */
  &:read-only {
    background-color: #e1d9ea;
    pointer-events: none;
  }
  width: var(--input-width); /* Use the CSS variable for width */
`;

export const AlertButton = styled.button`
  padding: 0.2rem;
  border: none;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  &:hover {
    background-color: #ad6baf;
  }
  font-size: 10px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  font-size: 12px;
`;

export const Button = styled.button`
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  background-color: #0000ff;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ad6baf;
  }
  margin-right: 1rem; /* Align the button to the right */
  // border-radius: 10px;
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0.5rem;
  text-align: left;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const ButtonAlt = styled.button`
  padding: 0.5rem;

  border: none;
  background-color: #e0b5b5;
  color: #000;
  cursor: pointer;
  border: 1px solid #000;
  &:hover {
    background-color: #cfcfcf;
  }
  margin-right: 1rem; /* Align the button to the right */
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  text-align: left;
  font-size: 12px;
`;

export const CenteredStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 1rem;
`;

export const InputButton = styled.button`
  padding: 0.5rem;
  border: none;
  background-color: #0000ff;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  //   margin-top: 1rem; /* Add margin to the top to create space between the input and the button */
  &:hover {
    background-color: #ad6baf;
  }
`;

export const AddressRow = styled.div`
  // display: flex;
  // flex-direction: column;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  flex: 1;
  flex-wrap: wrap; /* Add this line to allow the elements to wrap */
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #000;
  color: #fff;
  border-radius: 0px;
  padding: 5px;
  font-size: 14px;
  // margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #fff;
`;

export const AddressText = styled.span`
  flex: 1; /* Allow the AddressText to grow and occupy the available space */
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const InnerHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.isGray ? "#fff" : "#fff")};
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative; /* Add position relative to create a positioning context */
  border-radius: 0px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.isGray ? "#fff" : "#fff")};
  padding-left: 1.5rem;
  padding-right: 1rem;
  position: relative; /* Add position relative to create a positioning context */
  border-radius: 0px;
  margin-bottom: 1%;
  width: 100%;
`;

export const RowContainerNoGap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.isGray ? "#fff" : "#fff")};
  padding-left: 1.5rem;
  position: relative; /* Add position relative to create a positioning context */
  border-radius: 0px;
  width: 100%;
`;

export const RowContainerAlt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.isGray ? "#fff" : "#fff")};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative; /* Add position relative to create a positioning context */
  border-radius: 0px;
  width: 100%;
`;

export const RowButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${(props) => (props.isGray ? "#fff" : "#fff")};
  position: relative; /* Add position relative to create a positioning context */
  border-radius: 0px;
  width: 100%;
  // border-top: 1px solid #000;
`;

export const RowButtonTopRight = styled.button`
  padding: 0;
  border: none;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; 
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; 
  }
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #000;
`;


export const RowButtonBottomRight = styled.button`
  padding: 0;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; /* Add this line to enable flex layout */
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; /* Adjust the margin as needed */
  }
  font-size: 14px;
  font-weight: 500;
  // border-right: 1px solid #000;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #000;
`;

export const RowButtonBorderTopRight = styled.button`
  padding: 0;
  border: none;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; 
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; 
  }
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #000;
`;


export const RowButtonBorderTopLeft = styled.button`
  padding: 0;
  color: #fff;
  cursor: pointer;
  background-color: #0000ff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; /* Add this line to enable flex layout */
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; /* Adjust the margin as needed */
  }
  font-size: 14px;
  font-weight: 500;
  // border-right: 1px solid #000;
  border-left: none;
  border-bottom: none;
  border-top: 1px solid #000;
`;

export const AddAlertButton = styled.button`
  padding: 0;
  color: #fff;
  cursor: pointer;
  background-color: #0000ff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; /* Add this line to enable flex layout */
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; /* Adjust the margin as needed */
  }
  font-size: 14px;
  font-weight: 500;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid #000;
`;

export const WideButton = styled.button`
  padding: 0;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  padding: 0.5rem;
  &:hover {
    background-color: #ad6baf;
  }
  border-radius: 0px;
  height: 40px;
  width: 100%;
  display: flex; /* Add this line to enable flex layout */
  align-items: center; 
  justify-content: center; /* Add this line to horizontally align the content */
  svg {
    margin-right: 5px; /* Adjust the margin as needed */
  }
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
  border-right: none;
`;

export const RowLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  order: 2;
  margin-left: 0.5rem;
`;

export const LabelContainer = styled.div`
  order: 1;
`;
export const RowContainerLoad = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically within the flex container */
  background-color: #f5f8ff;
  padding-left: 1.5rem;
  padding-right: 1rem;
  position: relative;
  border-radius: 0px;
  width: 100%;
`;

export const TextCenter = styled.div`
  text-align: center;
  background-color: #f5f8ff;
  margin-top: 2%;
  margin-bottom: 2%;
`;
