import "../../../styles.css";
import {
  firstColor,
  secondColor,
} from "../../constants/components/Universal.styles";

const lineWidth = 2;
const gridLineWidth = 0.5;
const fontSize = "10px";
const firstGraphColor = 'red';
const secondGraphColor = '#0000ff';

function formatAxisLabel() {
    let value = Math.abs(this.value); // Take the absolute value to handle negative values
    let suffix = "";
  
    if (value >= 1e9) {
      value = (value / 1e9).toFixed(0);
      suffix = "b";
    } else if (value >= 1e6) {
      value = (value / 1e6).toFixed(0);
      suffix = "m";
    } else if (value >= 1e3) {
      value = (value / 1e3).toFixed(0);
      suffix = "k";
    }
  
    return (this.value < 0 ? "-" : "") + value + suffix; // Add "-" prefix for negative values
  }
  

export const getLineChartOptions = (graphOneId, graphOneData) => {
  return {
    yAxis: {
      title: {
        text: graphOneId,
        style: {
          fontSize: fontSize,
        },
      },
      min: 0,
      labels: {
        style: {
          fontSize: fontSize,
        },
      },
    },
    series: [
      {
        name: graphOneId,
        data: graphOneData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
  };
};

export const getSecondLineChartOptions = (graphTwoId, graphTwoData) => {
  return {
    yAxis: [
      {
        title: {
          text: graphTwoId,
          style: {
            fontSize: fontSize,
          },
        },
        labels: {
          style: {
            fontSize: fontSize,
          },
        },
        opposite: false, // Set opposite to false to place y-axis on the left side
        // min: -45, // Adjust min value so bars start below zero
      },
    ],
    series: [
      {
        name: graphTwoId,
        data: graphTwoData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y, // Keep the values as they are
        ]),
        yAxis: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
        pointWidth: 2,
      },
    },
  };
};

export const getCombinedOptions = (
  lineChartOptions,
  SecondLineChartOptions,
  varMarginTop,
  varMarginRight,
  varMarginBottom,
  varMarginLeft
) => {
  const combinedSeries = [
    {
      ...lineChartOptions.series[0],
      type: "column",
      color: firstGraphColor,
      yAxis: 0, // Assign the series to the left y-axis
    },
    {
      ...SecondLineChartOptions.series[0],
      type: "column",
      color: secondGraphColor,
      yAxis: 0, // Assign the series to the left y-axis
    },
  ];

  // Calculate min values for both series
  const minValues = [
    ...lineChartOptions.series[0].data.map((item) => item[1]),
    ...SecondLineChartOptions.series[0].data.map((item) => item[1]),
  ];
  const min = Math.min(...minValues);

  return {
    chart: {
      spacingTop: varMarginTop,
      spacingRight: varMarginRight,
      spacingBottom: varMarginBottom,
      spacingLeft: varMarginLeft,
    },
    title: {
      text: undefined,
    },
    credits: {
      text: "Trizon",
    },
    series: combinedSeries,
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
          const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
          return `${formattedDate} ${formattedTime}`;
        },
        style: {
          fontSize: fontSize,
        },
      },
      // tickInterval: 14 * 24 * 60 * 60 * 1000,
    },
    yAxis: {
      title: {
        text: "Value ($)", // Add your y-axis title here
        style: {
          fontSize: fontSize,
        },
      },
      labels: {
        formatter: function () {
          return this.value !== 0 ? formatAxisLabel.call(this) : "";
        },
        style: {
          fontSize: fontSize,
        },
      },
      min: min > 0 ? 0 : null, // Set min to 0 if min > 0, otherwise null for auto min
      max: 5000000, // Set the maximum value to 10 million (10,000,000)
      opposite: false, // Set opposite to false for the left y-axis
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const formattedDate = new Date(this.x);
        const formattedDateStr = `${formattedDate.getFullYear()}-${(formattedDate.getMonth() + 1).toString().padStart(2, "0")}-${formattedDate.getDate().toString().padStart(2, "0")}`;
        const formattedTime = `${formattedDate.getHours().toString().padStart(2, "0")}:${formattedDate.getMinutes().toString().padStart(2, "0")}:${formattedDate.getSeconds().toString().padStart(2, "0")}`;
        
        let tooltip = `<span style="font-size: ${fontSize}">${formattedDateStr} ${formattedTime}</span><br/>`;
        
        for (let point of this.points) {
          const formattedValue = point.y.toLocaleString(); // Format with thousand separators
          tooltip += `<span style="color:${point.color}">${point.series.name}: ${formattedValue}</span><br/>`;
        }
        
        return tooltip;
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
        pointWidth: 2,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "top",
      x: 0,
      y: -20,
      floating: false,
      itemStyle: {
        fontSize: fontSize,
        textOverflow: "ellipsis",
      },
    },
  };
};