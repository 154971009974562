import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
};

const Pool = new CognitoUserPool(poolData);

const confirmRegistration = (email, code) => {
  const userData = {
    Username: email,
    Pool: Pool,
  };
  const cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

export { Pool, confirmRegistration };
