import "../../../styles.css";
import React, { useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import { OiSummaryCoinTable, OiSummaryUsdTable } from "./OiSummaryComponent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { HashLink as Link } from "react-router-hash-link";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  ButtonContainer,
  SubButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  ButtonBox,
  TextContainer,
} from "../../constants/components/Universal.styles";

export default function OiSummaryPage() {
  const { role } = useContext(AccountContext);

  if (role === "notloggedin") {
    return (
      <>
        <ButtonContainer></ButtonContainer>
        <ContentNotAvailable>
          <NoticeTitle>Content Not Available</NoticeTitle>
          <NoticeSubTitle>Login or Register for Access</NoticeSubTitle>
          <NoticeButtonContainer>
            <StyledLink to="/login">
              <LoginButton>Login</LoginButton>
            </StyledLink>
            <StyledLink to="/register">
              <LoginButtonAlt>Register</LoginButtonAlt>
            </StyledLink>
          </NoticeButtonContainer>
        </ContentNotAvailable>
      </>
    );
  }

  return (
    <>
      <ButtonContainer></ButtonContainer>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Open Interest - Coin denominated</GraphTitle>
            <GraphSubTitle>Binance Perps</GraphSubTitle>
            <Card>
              <OiSummaryCoinTable />
            </Card>
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>Open Interest - USD denominated</GraphTitle>
            <GraphSubTitle>Binance Perps</GraphSubTitle>
            <Card>
              <OiSummaryUsdTable />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>



      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <TextContainer>
              <a
                href="https://forms.gle/4Njv5TSAN4EPGYvx7"
                target="_blank"
                rel="noEthener noreferrer"
              >
                <SmallSubHeader>
                  Click here to help us with feedback or suggestions
                </SmallSubHeader>
              </a>
            </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
