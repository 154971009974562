// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import React, { useReducer, useEffect, useState } from "react";
import axios from "axios";
import { ResponsiveLine } from '@nivo/line'

// PRESETS
const varGrid = 6
const varMarginTop = 30
const varMarginRight = 150
const varMarginLeft = 80
const varMarginBottom = 60

const url_obGraph = 'https://api.forkeddigital.vip/orderbookhist'
const init = initialState => initialState;

const reducer = (state, action) => {
  switch (action.type) {
    case "dataLoaded":
      return { ...state, results: action.payload, loading: false };
    default:
      throw new Error();
  }
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const OrderBookBtcOne = () => {

  const initialState = {
    results: [],
    loading: true
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;


  useEffect(() => {
      const fetchData = async () => {
          setIsLoading(true);
          setIsError(false);
          try {
              const result = await axios(url_obGraph);
              if (result.status == 200) {
                  
                  setData(result);
                  return result
              }
          } catch (error) {
              console.log(error);
              setIsError(true);
          }
          setIsLoading(false);
          
      };
      fetchData()
      // .then(res => console.log(res.data[0]))
      .then(res => dispatch({ type: "dataLoaded", payload: res.data[0]['spot_one'] }))
  }, [loading]);

  // Get min/max
  var values = [];
  results.forEach(function (item) {
      item.data.forEach(function (nestedItem) {
        values.push(nestedItem.y);
      });
  });
  
  return (
    <ResponsiveLine
        data={results}
        // width= {900}
        // height = {500}
        margin={{ top: varMarginTop, right: varMarginRight, bottom: varMarginBottom, left: varMarginLeft }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d%H:%M:%S"
        }}
        xFormat="time:%Y-%m-%d%H:%M:%S"
        yScale={{
          type: "linear",
          min: 0,
          max: Math.max.apply(Math, values)*1.2,
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickValues: varGrid,
          // legend: "Yield %",
          // legendOffset: -110,
          // legendPosition: "middle",
          // format: values => {
          //   // console.log(values);
          //   return values.toLocaleString().concat("%");
          //   // values.format('DD');
          // },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          format: "%d/%m",
          tickValues: "every 3 days",
          // tickRotation: -90,
        //   legend: "Date",
          legendOffset: 36,
          legendPosition: 'middle',
        //   orient: 'bottom',
        }}
        colors={{ scheme: "dark2" }}
        pointSize={1}
        pointColor={{ from: 'color', modifiers: [] }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        crosshairType="cross"
        lineWidth={1}
        useMesh={true}
        enableGridX={false}
        enableGridY={true}
        gridXValues = "every 1 days"
        gridYValues = {varGrid}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 175,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 140,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        theme={{
            axis: {
                ticks: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                legend: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                // domain: { line: { stroke: "#000000", strokeWidth: 1 } },
            },
            // grid: { line: { stroke: '#bfbfbf', strokeDasharray: '3 6' } },
            grid: { line: { stroke: '#000000', strokeWidth: 0.1 } },
            legends: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            const date = slice.points[0].data.xFormatted;
            return (
              <div align="left" style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                fontSize: 12
            }}>
                <strong>
                  {`Date: ${date}`}
                </strong>
                {slice.points.map(point => (
                  <div key={point.id}>
                    <div style={{ color: point.serieColor }}>
                      {`${point.serieId}: ${"$".concat(Number(point.data.yFormatted).toLocaleString())}`}
                    </div>
                  </div>
                ))}
              </div>
            );
        }}
        tooltip = {{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        }}
        markers={[
          {
            axis: 'y',
            value: '0',
            lineStyle: { stroke: 'black', strokeWidth: 1, strokeDasharray: '5 6' },
            // legend: 'y marker',
            // legendOrientation: 'vertical',
          },
        ]}

      />
  )
}

export const OrderBookBtcTwo = () => {

  const initialState = {
    results: [],
    loading: true
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;


  useEffect(() => {
      const fetchData = async () => {
          setIsLoading(true);
          setIsError(false);
          try {
              const result = await axios(url_obGraph);
              if (result.status == 200) {
                  
                  setData(result);
                  return result
              }
          } catch (error) {
              console.log(error);
              setIsError(true);
          }
          setIsLoading(false);
          
      };
      fetchData()
      // .then(res => console.log(res.data[0]))
      .then(res => dispatch({ type: "dataLoaded", payload: res.data[1]['spot_two'] }))
  }, [loading]);

  // Get min/max
  var values = [];
  results.forEach(function (item) {
      item.data.forEach(function (nestedItem) {
        values.push(nestedItem.y);
      });
  });
  
  return (
    <ResponsiveLine
        data={results}
        // width= {900}
        // height = {500}
        margin={{ top: varMarginTop, right: varMarginRight, bottom: varMarginBottom, left: varMarginLeft }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d%H:%M:%S"
        }}
        xFormat="time:%Y-%m-%d%H:%M:%S"
        yScale={{
          type: "linear",
          min: 0,
          max: Math.max.apply(Math, values)*1.2,
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickValues: varGrid,
          // legend: "Yield %",
          // legendOffset: -110,
          // legendPosition: "middle",
          // format: values => {
          //   // console.log(values);
          //   return values.toLocaleString().concat("%");
          //   // values.format('DD');
          // },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          format: "%d/%m",
          tickValues: "every 3 days",
          // tickRotation: -90,
        //   legend: "Date",
          legendOffset: 36,
          legendPosition: 'middle',
        //   orient: 'bottom',
        }}
        colors={{ scheme: "dark2" }}
        pointSize={1}
        pointColor={{ from: 'color', modifiers: [] }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        crosshairType="cross"
        lineWidth={1}
        useMesh={true}
        enableGridX={false}
        enableGridY={true}
        gridXValues = "every 1 days"
        gridYValues = {varGrid}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 175,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 140,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        theme={{
            axis: {
                ticks: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                legend: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                // domain: { line: { stroke: "#000000", strokeWidth: 1 } },
            },
            // grid: { line: { stroke: '#bfbfbf', strokeDasharray: '3 6' } },
            grid: { line: { stroke: '#000000', strokeWidth: 0.1 } },
            legends: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            const date = slice.points[0].data.xFormatted;
            return (
              <div align="left" style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                fontSize: 12
            }}>
                <strong>
                  {`Date: ${date}`}
                </strong>
                {slice.points.map(point => (
                  <div key={point.id}>
                    <div style={{ color: point.serieColor }}>
                      {`${point.serieId}: ${"$".concat(Number(point.data.yFormatted).toLocaleString())}`}
                    </div>
                  </div>
                ))}
              </div>
            );
        }}
        tooltip = {{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        }}
        markers={[
          {
            axis: 'y',
            value: '0',
            lineStyle: { stroke: 'black', strokeWidth: 1, strokeDasharray: '5 6' },
            // legend: 'y marker',
            // legendOrientation: 'vertical',
          },
        ]}

      />
  )
}

export const OrderBookBtcFive = () => {

  const initialState = {
    results: [],
    loading: true
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;


  useEffect(() => {
      const fetchData = async () => {
          setIsLoading(true);
          setIsError(false);
          try {
              const result = await axios(url_obGraph);
              if (result.status == 200) {
                  
                  setData(result);
                  return result
              }
          } catch (error) {
              console.log(error);
              setIsError(true);
          }
          setIsLoading(false);
          
      };
      fetchData()
      // .then(res => console.log(res.data[0]))
      .then(res => dispatch({ type: "dataLoaded", payload: res.data[2]['spot_five'] }))
  }, [loading]);

    // Get min/max
    var values = [];
    results.forEach(function (item) {
        item.data.forEach(function (nestedItem) {
          values.push(nestedItem.y);
        });
    });

  return (
    <ResponsiveLine
        data={results}
        // width= {900}
        // height = {500}
        margin={{ top: varMarginTop, right: varMarginRight, bottom: varMarginBottom, left: varMarginLeft }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d%H:%M:%S"
        }}
        xFormat="time:%Y-%m-%d%H:%M:%S"
        yScale={{
          type: "linear",
          min: 0,
          max: Math.max.apply(Math, values)*1.2,
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickValues: varGrid,
          // legend: "Yield %",
          // legendOffset: -110,
          // legendPosition: "middle",
          // format: values => {
          //   // console.log(values);
          //   return values.toLocaleString().concat("%");
          //   // values.format('DD');
          // },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          format: "%d/%m",
          tickValues: "every 3 days",
          // tickRotation: -90,
        //   legend: "Date",
          legendOffset: 36,
          legendPosition: 'middle',
        //   orient: 'bottom',
        }}
        colors={{ scheme: "dark2" }}
        pointSize={1}
        pointColor={{ from: 'color', modifiers: [] }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        crosshairType="cross"
        lineWidth={1}
        useMesh={true}
        enableGridX={false}
        enableGridY={true}
        gridXValues = "every 1 days"
        gridYValues = {varGrid}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 175,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 140,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        theme={{
            axis: {
                ticks: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                legend: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                // domain: { line: { stroke: "#000000", strokeWidth: 1 } },
            },
            // grid: { line: { stroke: '#bfbfbf', strokeDasharray: '3 6' } },
            grid: { line: { stroke: '#000000', strokeWidth: 0.1 } },
            legends: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            const date = slice.points[0].data.xFormatted;
            return (
              <div align="left" style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                fontSize: 12
            }}>
                <strong>
                  {`Date: ${date}`}
                </strong>
                {slice.points.map(point => (
                  <div key={point.id}>
                    <div style={{ color: point.serieColor }}>
                      {`${point.serieId}: ${"$".concat(Number(point.data.yFormatted).toLocaleString())}`}
                    </div>
                  </div>
                ))}
              </div>
            );
        }}
        tooltip = {{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        }}
        markers={[
          {
            axis: 'y',
            value: '0',
            lineStyle: { stroke: 'black', strokeWidth: 1, strokeDasharray: '5 6' },
            // legend: 'y marker',
            // legendOrientation: 'vertical',
          },
        ]}

      />
  )
}

export const OrderBookBtcTen = () => {

  const initialState = {
    results: [],
    loading: true
  };
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const { results, loading } = state;


  useEffect(() => {
      const fetchData = async () => {
          setIsLoading(true);
          setIsError(false);
          try {
              const result = await axios(url_obGraph);
              if (result.status == 200) {
                  
                  setData(result);
                  return result
              }
          } catch (error) {
              console.log(error);
              setIsError(true);
          }
          setIsLoading(false);
          
      };
      fetchData()
      // .then(res => console.log(res.data[0]))
      .then(res => dispatch({ type: "dataLoaded", payload: res.data[3]['spot_ten'] }))
  }, [loading]);
    // Get min/max
    var values = [];
    results.forEach(function (item) {
        item.data.forEach(function (nestedItem) {
          values.push(nestedItem.y);
        });
    });
  return (
    <ResponsiveLine
        data={results}
        // width= {900}
        // height = {500}
        margin={{ top: varMarginTop, right: varMarginRight, bottom: varMarginBottom, left: varMarginLeft }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d%H:%M:%S"
        }}
        xFormat="time:%Y-%m-%d%H:%M:%S"
        yScale={{
          type: "linear",
          min: 0,
          max: Math.max.apply(Math, values)*1.2,
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickValues: varGrid,
          // legend: "Yield %",
          // legendOffset: -110,
          // legendPosition: "middle",
          // format: values => {
          //   // console.log(values);
          //   return values.toLocaleString().concat("%");
          //   // values.format('DD');
          // },
        }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          format: "%d/%m",
          tickValues: "every 3 days",
          // tickRotation: -90,
        //   legend: "Date",
          legendOffset: 36,
          legendPosition: 'middle',
        //   orient: 'bottom',
        }}
        colors={{ scheme: "dark2" }}
        pointSize={1}
        pointColor={{ from: 'color', modifiers: [] }}
        // pointBorderWidth={2}
        // pointBorderColor={{ from: "serieColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        crosshairType="cross"
        lineWidth={1}
        useMesh={true}
        enableGridX={false}
        enableGridY={true}
        gridXValues = "every 1 days"
        gridYValues = {varGrid}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 175,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 140,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        theme={{
            axis: {
                ticks: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                legend: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
                // domain: { line: { stroke: "#000000", strokeWidth: 1 } },
            },
            // grid: { line: { stroke: '#bfbfbf', strokeDasharray: '3 6' } },
            grid: { line: { stroke: '#000000', strokeWidth: 0.1 } },
            legends: { text: { fontSize: 11, fontFamily: "Roboto Mono" } },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            const date = slice.points[0].data.xFormatted;
            return (
              <div align="left" style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                fontSize: 12
            }}>
                <strong>
                  {`Date: ${date}`}
                </strong>
                {slice.points.map(point => (
                  <div key={point.id}>
                    <div style={{ color: point.serieColor }}>
                      {`${point.serieId}: ${"$".concat(Number(point.data.yFormatted).toLocaleString())}`}
                    </div>
                  </div>
                ))}
              </div>
            );
        }}
        tooltip = {{
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12
            },
        }}
        markers={[
          {
            axis: 'y',
            value: '0',
            lineStyle: { stroke: 'black', strokeWidth: 1, strokeDasharray: '5 6' },
            // legend: 'y marker',
            // legendOrientation: 'vertical',
          },
        ]}

      />
  )
}