import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Navbar,
  NavbarWithBackground,
  NavLinks,
  HomeLink,
  NavLink,
  NavLinkBlack,
  DropDown,
  DropDownContent,
  ParentDropDownLink,
  DropDownLink,
  DropDownIcon,
  NavbarButton,
  NavbarButtonBlack,
  CustomNavLink,
  NavLinkDropDown,
  HomeNavLinkWrapper,
} from "./Navbar.styles";
import { AccountContext } from "../../login/components/Account";
import trizontextImage from "../../shared/icons/trizontext.png";
import { SiHiveBlockchain } from "react-icons/si";
import { BiSolidCoinStack } from "react-icons/bi";
import { GiBuyCard } from "react-icons/gi";
import { SlGraph } from "react-icons/sl";
import { VscGraphLine, VscBook, VscFeedback } from "react-icons/vsc";
import { TbAlertCircleFilled } from "react-icons/tb";
import { FaTools } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";

export default function Navigation() {
  const { role } = useContext(AccountContext);
  const location = useLocation();

  const handleHomeLinkClick = () => {
    window.location.href = "/"; // Navigate to the homepage
  };

  return (
    <Navbar>
      <NavLinks>
        <HomeLink onClick={handleHomeLinkClick}>
          <img src={trizontextImage} alt="TRIZON" style={{ width: "65px" }} />
        </HomeLink>

        {/* <DropDown>
          <ParentDropDownLink to="/layers/eth">
            <NavbarButton>
              <SiHiveBlockchain />
              Chains
            </NavbarButton>
            <DropDownIcon />
          </ParentDropDownLink>
          <DropDownContent>
            <DropDownLink to="/layers/eth">Ethereum</DropDownLink>
            <DropDownLink to="/layers/matic">Polygon</DropDownLink>
            <DropDownLink to="/layers/arb">Arbitrum</DropDownLink>
            <DropDownLink to="/layers/op">Optimism</DropDownLink>
            <DropDownLink to="/layers/ftm">Fantom</DropDownLink>
            <DropDownLink to="/layers/avax">Avalanche</DropDownLink>
            <DropDownLink to="/layers/bsc">Binance Smart Chain</DropDownLink>
          </DropDownContent>
        </DropDown> */}

        {/* <NavLink to="/cex">
          <NavbarButton>
            <BiSolidCoinStack />
            CEX
          </NavbarButton>
        </NavLink> */}

        <NavLink to="/leveragetracker">
          <NavbarButton>
            <BiSolidCoinStack />
            Leverage
          </NavbarButton>
        </NavLink>

        {role === "admin" && (
          <NavLink to="/binancetoptraders">
            <NavbarButton>
              <SlGraph />
              DefiCap Traders
            </NavbarButton>
          </NavLink>
        )}

        <DropDown>
          <ParentDropDownLink to="/oi/coin">
            <NavbarButton>
              <VscGraphLine />
              OI
            </NavbarButton>
            <DropDownIcon />
          </ParentDropDownLink>
          <DropDownContent>
            <DropDownLink to="/oi/summary">Summary</DropDownLink>
            <DropDownLink to="/oi/coin">COIN Denominated</DropDownLink>
            <DropDownLink to="/oi/usd">USD Denominated</DropDownLink>
          </DropDownContent>
        </DropDown>

        <DropDown>
          <ParentDropDownLink to="/orderbook/perps/one">
            <NavbarButton>
              <VscBook />
              Order Book
            </NavbarButton>
            <DropDownIcon />
          </ParentDropDownLink>
          <DropDownContent>
            <DropDownLink to="/orderbook/perps/one">Perpetuals</DropDownLink>
            <DropDownLink to="/orderbook/spot/one">Spot</DropDownLink>
          </DropDownContent>
        </DropDown>

        <NavLink to="/traders">
          <NavbarButton>
            <GiBuyCard />
            Traders
          </NavbarButton>
        </NavLink>

        {/* <NavLink to="/alerts/add">
          <NavbarButton>
            <TbAlertCircleFilled />
            Wallet Alerts
          </NavbarButton>
        </NavLink> */}

        
        {/* {role === "admin" && (
          <DropDown>
            <ParentDropDownLink to="/tokenhistory">
              <NavbarButton>
                <FaTools />
                Tools
              </NavbarButton>
              <DropDownIcon />
            </ParentDropDownLink>
            <DropDownContent>
              <DropDownLink to="/tokenhistory">
                Token Balance History
              </DropDownLink>
              <DropDownLink to="/stEth">stEth</DropDownLink>
            </DropDownContent>
          </DropDown>
        )} */}

        {role === "admin" && (
          <DropDown>
            <ParentDropDownLink to="/eth">
              <NavbarButton>Dashboards</NavbarButton>
              <DropDownIcon />
            </ParentDropDownLink>
            <DropDownContent>
              <DropDownLink to="/btc">BTC</DropDownLink>
              <DropDownLink to="/eth">ETH</DropDownLink>
              <DropDownLink to="/usd">USD</DropDownLink>
            </DropDownContent>
          </DropDown>
        )}
      </NavLinks>

      <HomeNavLinkWrapper>
        <CustomNavLink
          href="https://forms.gle/4Njv5TSAN4EPGYvx7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <NavbarButtonBlack>
            <VscFeedback />
          </NavbarButtonBlack>
        </CustomNavLink>
        <NavLinkBlack to={role === "notloggedin" ? "/login" : "/edit"}>
          <NavbarButtonBlack>
            {role === "notloggedin" ? "Login" : <MdManageAccounts />}
          </NavbarButtonBlack>
        </NavLinkBlack>
      </HomeNavLinkWrapper>
    </Navbar>
  );
}
