import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  SubButtonContainer,
  ButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  TextContainer,
  ButtonBox,
  ButtonBoxRight,
  PlaceHolder,
} from "../../constants/components/Universal.styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const BinanceOrderBook = () => {
  const [rawOrderBook, setRawOrderBook] = useState({ bids: {}, asks: {} });

  const [groupedOrderBook, setGroupedOrderBook] = useState({
    bids: [],
    asks: [],
  });
  const [orders, setOrders] = useState({ bids: [], asks: [] });
  const [currentPrice, setCurrentPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [initialUpdateId, setInitialUpdateId] = useState(0);
  const [currentMinPrice, setCurrentMinPrice] = useState(null);
  const [currentMaxPrice, setCurrentMaxPrice] = useState(null);

  const processOrderBookEntry = (entries) => {
    const processed = {};
    entries.forEach(([price, quantity]) => {
      price = parseFloat(price);
      processed[price] = parseFloat(quantity);
    });
    return processed;
  };

  const groupOrderBookEntries = (entries) => {
    const grouped = {};
    for (const price in entries) {
      const quantity = entries[price];
      const roundedPrice = Math.round(price);
      grouped[roundedPrice] = (grouped[roundedPrice] || 0) + quantity;
    }
    return Object.entries(grouped).map(([price, quantity]) => ({
      price,
      quantity,
    }));
  };

  const setupWebSocket = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws/btcusdt@depth");

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.U > initialUpdateId) {
        if (data.e === "depthUpdate") {
          setRawOrderBook((prevRawOrderBook) => {
            const updatedBids = {
              ...prevRawOrderBook.bids,
              ...processOrderBookEntry(data.b),
            };
            const updatedAsks = {
              ...prevRawOrderBook.asks,
              ...processOrderBookEntry(data.a),
            };

            // Find the latest bid and ask prices
            const latestBidPrice = Math.max(
              ...Object.keys(updatedBids).map(parseFloat)
            );
            const lowestAskPrice = Math.min(
              ...Object.keys(updatedAsks).map(parseFloat)
            );

            // Remove bids that are higher than the lowest ask price
            for (const price in updatedBids) {
              if (parseFloat(price) > lowestAskPrice) {
                delete updatedBids[price];
              }
            }

            // Remove asks that are lower than the latest bid price
            for (const price in updatedAsks) {
              if (parseFloat(price) < latestBidPrice) {
                delete updatedAsks[price];
              }
            }

            return { bids: updatedBids, asks: updatedAsks };
          });
        }
      }
    };

    return ws;
  };

  useEffect(() => {
    axios
      .get("https://api.binance.com/api/v3/depth", {
        params: {
          symbol: "BTCUSDT",
          limit: 5000,
        },
      })
      .then((response) => {
        setInitialUpdateId(response.data.lastUpdateId);
        setRawOrderBook({
          bids: processOrderBookEntry(response.data.bids),
          asks: processOrderBookEntry(response.data.asks),
        });
      })
      .catch((error) => {
        console.error("Error fetching initial order book snapshot:", error);
      });

    const ws = setupWebSocket();

    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    const groupedBids = groupOrderBookEntries(rawOrderBook.bids).sort(
      (a, b) => b.price - a.price
    );
    const groupedAsks = groupOrderBookEntries(rawOrderBook.asks).sort(
      (a, b) => a.price - b.price
    );

    setGroupedOrderBook({ bids: groupedBids, asks: groupedAsks });

    // if (groupedBids.length > 0) {
    //   const latestBidPrice = parseFloat(groupedBids[0].price);
    //   setMinPrice(latestBidPrice * 0.999); // latest bid price - 1%
    // }

    // if (groupedAsks.length > 0) {
    //   const latestAskPrice = parseFloat(groupedAsks[0].price);
    //   setMaxPrice(latestAskPrice * 1.001); // latest ask price + 1%
    // }
  }, [rawOrderBook]);

  const mergeBidsAndAsks = (bidsData, asksData) => {
    const combined = {};

    bidsData.forEach(([price, quantity]) => {
      if (!combined[price]) combined[price] = { bids: 0, asks: 0 };
      combined[price].bids += quantity;
    });

    asksData.forEach(([price, quantity]) => {
      if (!combined[price]) combined[price] = { bids: 0, asks: 0 };
      combined[price].asks += quantity;
    });

    const combinedData = Object.entries(combined).map(
      ([price, { bids, asks }]) => {
        return { price: parseFloat(price), bids, asks };
      }
    );

    return combinedData.sort((a, b) => a.price - b.price).reverse(); // Reversed here
  };

  const getChartData = () => {
    const bidsData = groupedOrderBook.bids.map((bid) => [
      parseFloat(bid.price),
      bid.quantity,
    ]);
    const asksData = groupedOrderBook.asks.map((ask) => [
      parseFloat(ask.price),
      ask.quantity,
    ]);

    const latestBidPrice =
      groupedOrderBook.bids.length > 0
        ? parseFloat(groupedOrderBook.bids[0].price)
        : 0;
    const latestAskPrice =
      groupedOrderBook.asks.length > 0
        ? parseFloat(groupedOrderBook.asks[0].price)
        : 0;

    if (
      currentMinPrice === null ||
      latestBidPrice < currentMinPrice ||
      latestBidPrice > currentMaxPrice
    ) {
      setCurrentMinPrice(currentMinPrice * 0.999); // latest bid price - 1%
      setCurrentMaxPrice(currentMaxPrice * 1.001); // latest ask price + 1%
    }

    const filteredBidsData = bidsData.filter(
      (bid) => bid[0] >= currentMinPrice
    );
    const filteredAsksData = asksData.filter(
      (ask) => ask[0] <= currentMaxPrice
    );

    const mergedData = mergeBidsAndAsks(filteredBidsData, filteredAsksData);

    return {
      chart: {
        type: "bar",
        height: 1000,
        width: 700,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category",
        title: {
          text: null, // Remove x-axis title
        },
        reversed: false,
        lineWidth: 0,
        gridLineWidth: 0,
        plotLines: [
          {
            color: "#0000ff",
            value: latestBidPrice,
            width: 1,
            zIndex: 5,
            label: {
              text: latestBidPrice.toString(),
              align: "right",
              x: 0,
              y: 15,
              style: {
                color: "#0000ff",
              },
            },
          },
        ],
      },
      yAxis: {
        title: {
          text: null, // Remove y-axis title
        },
        labels: {
          enabled: false,
        },
        max: 50,
        gridLineWidth: 0,
      },
      legend: {
        enabled: false, // Disable legend
      },
      plotOptions: {
        bar: {
          stacking: "normal",
          animation: false,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          borderRadius: 0,
        },
      },
      series: [
        {
          name: "Bids",
          data: mergedData.map((item) => [item.price, item.bids]),
          color: "rgba(0, 128, 0, 0.5)", // green with 50% transparency
          stacking: "normal",
          dataLabels: {
            enabled: true,
            color: "#000000",
            inside: false, // Position inside the bar
            align: "left", // Align to the left
            style: {
              fontSize: "11px",
              fontWeight: 400,
              textOutline: 0,
            },
            formatter: function () {
              return this.y > 0 ? this.y.toFixed(2) : null; // Rounds to nearest two decimals if y > 0
            },
          },
        },
        {
          name: "Asks",
          data: mergedData.map((item) => [item.price, item.asks]),
          color: "rgba(255, 0, 0, 0.5)", // red with 50% transparency
          stacking: "normal",
          dataLabels: {
            enabled: true,
            color: "#000000",
            inside: false, // Position inside the bar
            align: "left", // Align to the left
            style: {
              fontSize: "11px",
              fontWeight: 400,
              textOutline: 0,
            },
            formatter: function () {
              return this.y > 0 ? this.y.toFixed(2) : null; // Rounds to nearest two decimals if y > 0
            },
          },
        },
      ],
    };
  };

  return (
    <>
      <ButtonContainer></ButtonContainer>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>orderBook</GraphTitle>
            <GraphSubTitle>Visual</GraphSubTitle>
            <Card>
              <PlaceHolder style={{ height: "1000px", overflow: "auto" }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getChartData()}
                />
              </PlaceHolder>
            </Card>
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>orderBook</GraphTitle>
            <GraphSubTitle>Visual</GraphSubTitle>
            <Card>
              <PlaceHolder
                style={{ height: "1000px", overflow: "auto" }}
              ></PlaceHolder>
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>orderBook</GraphTitle>
            <GraphSubTitle>Order - live</GraphSubTitle>
            <Card>
              <PlaceHolder>
                {groupedOrderBook.asks && groupedOrderBook.asks.length > 0 && (
                  <div>
                    <h3>Asks</h3>
                    <ul>
                      {groupedOrderBook.asks.map((ask, index) => (
                        <li key={index}>
                          Price: {ask.price}, Quantity: {ask.quantity}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {groupedOrderBook.bids && groupedOrderBook.bids.length > 0 && (
                  <div>
                    <h3>Bids</h3>
                    <ul>
                      {groupedOrderBook.bids.map((bid, index) => (
                        <li key={index}>
                          Price: {bid.price}, Quantity: {bid.quantity}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </PlaceHolder>
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>
      <hr />

      <h2>WebSocket Orders: BTCUSDT</h2>
      {orders.asks && orders.asks.length > 0 && (
        <div>
          <h3>Asks</h3>
          <ul>
            {orders.asks.map((ask, index) => (
              <li key={index}>
                Price: {ask.price}, Quantity: {ask.quantity}
              </li>
            ))}
          </ul>
        </div>
      )}
      {orders.bids && orders.bids.length > 0 && (
        <div>
          <h3>Bids</h3>
          <ul>
            {orders.bids.map((bid, index) => (
              <li key={index}>
                Price: {bid.price}, Quantity: {bid.quantity}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
