import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

// Add a new styled component for setting the background color
export const Navbar = styled.div`
  width: 100%;
  // height: auto;
  height: 50px;
  color: #000;
  border-bottom: 1px solid #000;

  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
  font-size: 12px;

  background-color: white;
  display: flex; /* Use flexbox to align NavLinks */
  justify-content: space-between; /* Distribute space evenly between elements */
  align-items: center; /* Center align the items vertically */
`;

export const NavLinks = styled.ul`
  list-style: none;
  display: flex; /* Use flexbox to arrange NavLinks horizontally */
  align-items: center; /* Center align the NavLinks vertically */
  position: relative; /* Add relative positioning */
  padding-right: 10px;

  border-right: 1px solid #000;
`;

// Wrapper for HomeLink and NavLinkBlack
export const HomeNavLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  // margin-right: 10px;
  border-left: 1px solid #000;
`;

export const HomeLink = styled.div`
  position: relative; /* Add relative positioning */
  display: flex;
  align-items: center;
  color: #0000ff;
  text-align: center;
  // padding-left: 10px;
  padding-right: 20px;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  padding-left: 20px;
  height: 50px;
  border-right: 1px solid #000;

  &:hover {
    color: #000;
    cursor: pointer;
  }
`;

export const NavLinkDropDown = styled(Link)`
  display: block;
  color: #000;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  // float: right;
  background-color: transparent;
  position: relative;
`;

const isActive = (currentPath, linkPath) => {
  return currentPath === linkPath;
};

export const NavLink = styled(Link)`
  display: block;
  color: #000;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  // padding-top: 1%;
  text-decoration: none;
  background-color: transparent;
  position: relative;
  ${(props) =>
    isActive(props.to, props.location) &&
    css`
      font-weight: bold;
    `}
`;

export const CustomNavLink = styled.a`
  display: block;
  color: #000;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  position: relative;
`;

export const NavLinkBlack = styled(Link)`
  display: block;
  color: #000;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  // float: right;
  // background-color: #0000ff;
  position: relative;
`;

export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  // float: right;
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + -1px);
  text-align: left;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;

  ${DropDown}:hover & {
    display: block;
  }
`;

export const DropDownLink = styled(NavLinkDropDown)`
  display: block;
  color: #000;
  text-align: left;
  padding-right: 50px;
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-decoration: none;
  width: 100%;
  border-bottom: 1px solid rgba(207, 207, 207, 0.2);
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text with ellipsis */

  &:hover {
    background-color: #cfcfcf;
  }
`;

export const ParentDropDownLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: #000;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  // float: right;
  position: relative;
`;

export const DropDownIcon = styled(FaChevronDown)`
  margin-left: 5px;
`;

export const NavbarButton = styled.button`
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.color ||
    "transparent"}; /* Use the passed color or fallback to a default color */
  color: #000;
  padding: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 10px; /* Adjust the value to your desired spacing */
  }

  &:hover {
    color: #0000ff;
    // font-weight: bold;
  }
`;

export const NavbarButtonBlack = styled.div`
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  // padding: 5px;
  // font-size: 15px;
  // background-color: "#000";
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  /* Increase the icon size using font-size */
  & svg {
    font-size: 24px; /* Adjust the value to change the icon size */
  }

  &:hover {
    background-color: #cfcfcf;
  }
`;
