import React, { useState, useContext, useEffect } from "react";
import { Pool, confirmRegistration } from "../../../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { AccountContext } from "./Account";
import {
  FormContainer,
  Header,
  Form,
  Input,
  Button,
  ButtonAlt,
  ErrorMessage,
  ButtonContainer,
  Label,
  CenteredStyles,
} from "./Login.styles";
import Footer from "../../footer/components/Footer";

const Edit = () => {
  const { getSession } = useContext(AccountContext);
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [telegram, setTelegram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setIsLoading(false);
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              setIsLoading(false);
            } else {
              const attributeMap = {};
              attributes.forEach((attribute) => {
                attributeMap[attribute.Name] = attribute.Value;
              });
              setEmail(attributeMap.email);
              setDiscord(attributeMap["custom:discord"] || "");
              setTelegram(attributeMap["custom:telegram"] || "");
              setTwitter(attributeMap["custom:twitter"] || "");
              setRole(attributeMap["custom:role"] || "");
              setIsLoading(false);
            }
          });
        }
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const now = new Date();
    const unixTimestamp = Math.floor(now.getTime() / 1000); // convert current date and time to Unix timestamp
    const user = Pool.getCurrentUser();

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setIsLoading(false);
        } else {
          const attributeList = [
            { Name: "updated_at", Value: unixTimestamp.toString() },
            { Name: "custom:discord", Value: discord },
            { Name: "custom:telegram", Value: telegram },
            { Name: "custom:twitter", Value: twitter },
            { Name: "custom:role", Value: role },
          ];

          user.updateAttributes(attributeList, (err, result) => {
            if (err) {
              setError(err.message || "Something went wrong");
            } else {
              window.location.reload();
            }
          });
        }
      });
    }
  };

  const handleLogout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      window.location.href = "/";
    }
  };

  return (
    <>
      <FormContainer>
        <Form onSubmit={handleSubmit} noValidate>
          <Header>Edit Details</Header>
          <Label>Email:</Label>
          <Input type="text" placeholder="Email" value={email} readOnly />
          <Label>Membership:</Label>
          <Input type="text" placeholder="Role" value={role} readOnly />
          <Label>Discord:</Label>
          <Input
            type="text"
            value={discord}
            onChange={(event) => setDiscord(event.target.value)}
            onFocus={() => setError("")}
          />
          <Label>Twitter:</Label>
          <Input
            type="text"
            value={twitter}
            onChange={(event) => setTwitter(event.target.value)}
            onFocus={() => setError("")}
          />
          <Label>Telegram:</Label>
          <Input
            type="text"
            value={telegram}
            onChange={(event) => setTelegram(event.target.value)}
            onFocus={() => setError("")}
          />
<Label>
  Join our TG group to receive alerts: <br />
  <a
    href="https://t.me/+zyYifyy5HVk1YmM9"
    target="_blank"
    rel="noopener noreferrer"
  >
    Click here
  </a>
</Label>


          <Button type="submit">Save</Button>
          <CenteredStyles>
            <ButtonAlt onClick={handleLogout}>Logout</ButtonAlt>
          </CenteredStyles>
        </Form>
      </FormContainer>
      <Footer />
    </>
  );
};

export { Edit };
