import "../../../styles.css";
import {
  firstColor,
  secondColor,
} from "../../constants/components/Universal.styles";

const lineWidth = 2;
const fontSize = "10px";
const firstGraphColor = '#4d4d4e';
const secondGraphColor = '#0000ff';
const validColors = [
  "#E57500", // Dark Orange
  "#C11479", // Dark Pink
  "#7A60A8", // Dark Medium Purple
  "#DB00DB", // Dark Magenta
  "#CC3C00", // Dark Orange Red
  "#7523AB", // Dark Blue Violet
  "#D1478B", // Dark Hot Pink
  "#660066", // Dark Purple
  "#D47D00", // Dark Orange
  "#B20F2C", // Dark Crimson
  "#D1432D", // Dark Tomato
  "#7C2696", // Dark Dark Orchid
  "#2A764C", // Dark Sea Green
  "#FF1493", // Deep Pink
  "#FF8C00", // Orange
  "#800000", // Maroon
  "#2F4F4F", // Dark Slate Gray
];
//test
function formatAxisLabel() {
  let value = this.value;
  let suffix = "";

  if (value >= 1e9) {
    value = (value / 1e9).toFixed(0);
    suffix = "b";
  } else if (value >= 1e6) {
    value = (value / 1e6).toFixed(0);
    suffix = "m";
  } else if (value >= 1e3) {
    value = (value / 1e3).toFixed(0);
    suffix = "k";
  }
  return value + suffix;
}

export const getLine = (graphId, graphData) => {
  return {
    yAxis: {
      title: {
        text: null,
        style: {
          fontSize: fontSize,
        },
      },
      labels: {
        formatter: function () {
          return this.value !== 0 ? formatAxisLabel.call(this) : "";
        },
        style: {
          fontSize: fontSize,
        },
      },
    },
    series: [
      {
        name: graphId,
        data: graphData[0]["data"].map((item) => [
          new Date(item.x).getTime(),
          item.y,
        ]),
      },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
  };
};

export const generateLineChart = (
  lineChartOptionsArray,
  varMarginTop,
  varMarginRight,
  varMarginBottom,
  varMarginLeft,
  displayPercentage = false
) => {
  const combinedSeries = lineChartOptionsArray.map(
    (lineChartOptions, index) => {
      let color;
      if (index === 0) {
        color = firstGraphColor;
      } else if (index === 1) {
        color = secondGraphColor;
      } else {
        const availableColors = validColors.filter(
          (c) =>
            !lineChartOptionsArray
              .slice(0, index)
              .some((opt) => opt.series[0].color === c)
        );
        color = availableColors[index % availableColors.length];
      }

      const seriesData = lineChartOptions.series[0].data;
      const latestValue = seriesData[seriesData.length - 1][1];
      const formattedLatestValue = displayPercentage
        ? (latestValue).toFixed(2)
        : latestValue.toLocaleString();

      let valueColor = "black";
      if (latestValue < 0) {
        valueColor = "#8B0000"; // Dark red
      } else if (latestValue > 0) {
        valueColor = "#006400"; // Dark green
      }

      return {
        ...lineChartOptions.series[0],
        type: "line",
        color: color,
        name: lineChartOptions.series[0].name + ": " + formattedLatestValue,
        valueColor: valueColor,
      };
    }
  );

  combinedSeries.sort((a, b) => {
    const aValue = a.data[a.data.length - 1][1];
    const bValue = b.data[b.data.length - 1][1];
    return bValue - aValue;
  });

  return {
    chart: {
      spacingTop: varMarginTop,
      spacingRight: varMarginRight,
      spacingBottom: varMarginBottom,
      spacingLeft: varMarginLeft,
    },
    title: {
      text: undefined,
    },
    credits: {
      text: "Trizon",
    },
    series: combinedSeries,
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getUTCDate().toString().padStart(2, "0");
          const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
          const hours = date.getUTCHours().toString().padStart(2, "0");
          const minutes = date.getUTCMinutes().toString().padStart(2, "0");
          const seconds = date.getUTCSeconds().toString().padStart(2, "0");
          return `${day}/${month} ${hours}:${minutes}`;
        },
        style: {
          fontSize: fontSize,
        },
      },
    },

    yAxis: {
      ...lineChartOptionsArray[0].yAxis,
      title: {
        ...lineChartOptionsArray[0].yAxis.title,
        style: {
          fontSize: fontSize,
        },
      },
      labels: {
        formatter: function () {
          // Remove the percentage conversion
          return this.value;
        },
        style: {
          fontSize: fontSize,
        },
      },
      plotLines: [
        {
          value: 0,
          color: "black",
          width: lineWidth - 1,
          dashStyle: "Dash",
          zIndex: 5,
        },
      ],
    },
    tooltip: {
      shared: true,
      formatter: function () {
        const formattedDate = new Date(this.x).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
        let sortedPoints = this.points.sort((a, b) => b.y - a.y);
        let tooltip = `<span style="font-size: ${fontSize}">${formattedDate}</span><br/>`;
        for (let point of sortedPoints) {
          const formattedValue = point.y.toLocaleString(); 
          tooltip += `<span style="color:${point.color}">${point.series.name
            .split(":")[0]
            .trim()}: ${formattedValue}</span><br>`;
        }
        return tooltip;
      },
    },

    plotOptions: {
      line: {
        lineWidth: lineWidth,
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "right", // Align the legend to the right
      verticalAlign: "top", // Vertically center the legend
      layout: "vertical", // Display the legend vertically
      x: 0,
      y: -10,
      floating: false,
      itemStyle: {
        fontSize: fontSize,
        textOverflow: "ellipsis",
      },
    },
  };
};
